// Polyfill Buffer for bitcoinjs-lib
import { Buffer } from 'buffer';
window.Buffer = Buffer;

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "@/assets/global.css"

import {createApp} from 'vue'
import {createRouter, createWebHistory} from 'vue-router'
import App from './App.vue'
// import HelloWorld from './components/HelloWorld.vue'
// import HelloWorld2 from './components/HelloWorld2.vue'
import Dashboard from './components/DashboardScreen.vue'
// import Header from './components/Header.vue'
import HashDetails from "./components/HashDetails.vue";
import { store } from './store';
import FaucetRequest from "@/components/FaucetRequest.vue";
import PoolParties from "@/components/PoolParties.vue"; // Assuming store.js is in the root directory alongside main.js
import AppLayout from './layouts/AppLayout.vue';
import AppScreen from './components/AppScreen.vue';
import PortfolioDetails from './components/PortfolioDetails.vue';
import WalletConnectScreen from './components/WalletConnectScreen.vue';
import WasmTest from './components/WasmTest.vue';

// Import utilities
import './utils/wallet-connect';
// import webSocketClient from './utils/websocket-client';

// Define routes
const routes = [
    { path: '/hash/:param', component: HashDetails },
    { path: '/', component: Dashboard},
    { path: '/faucet', component: FaucetRequest},
    { path: '/parties', component: PoolParties},
    { path: '/wasm-test', component: WasmTest },
    { 
        path: '/app', 
        component: AppLayout,
        children: [
            { 
                path: '', 
                component: AppScreen,
                beforeEnter: async (to, from, next) => {
                    // Wait for wallet initialization to complete
                    // This ensures localStorage state is properly loaded
                    await new Promise(resolve => {
                        const checkInitialized = () => {
                            if (store.getters.isWalletInitialized) {
                                resolve();
                            } else {
                                setTimeout(checkInitialized, 50);
                            }
                        };
                        checkInitialized();
                    });
                    
                    // Check if wallet is connected or skipped
                    if (store.getters.isWalletConnected || store.getters.isWalletSkipped) {
                        // If wallet is connected or user skipped connection, proceed to app
                        next();
                    } else {
                        // Otherwise redirect to connect screen
                        next('/app/connect');
                    }
                }
            },
            { path: 'connect', component: WalletConnectScreen },
            { path: 'portfolio/:id', component: PortfolioDetails },
            // Add more app-specific routes here as needed
        ]
    }
]

// Create router
const router = createRouter({
    history: createWebHistory(),
    routes
})

// Create app
const app = createApp(App)

// Initialize WASM and Web3Modal before mounting the app
const initializeApp = async () => {
    try {

        const initWasm = await import('./wasm/redgold_gui.js').then(module => module.default);
        const wasmModule = await initWasm();
        // console.log('WASM module initialized successfully');
            // Create a new state object
        const stateId = wasmModule.create_state();
        // console.log('Created state with ID:', stateId);

        // Use the state
        // console.log('Initial counter:', wasmModule.get_counter(stateId)); // Should be 0
        // console.log('Incremented counter:', wasmModule.increment_counter(stateId)); // Should be 1
        // console.log('Incremented counter again:', wasmModule.increment_counter(stateId)); // Should be 2

        // When done
        // const deleted = wasmModule.delete_state(stateId);
        // console.log('State deleted:', deleted);

        window.redgoldWasm = wasmModule;

        store.state.wasm = wasmModule;
        
        // Track previous data state value
        let previousDataState = null;
        
        // Set up interval to call get_data_state() every 5 seconds
        setInterval(() => {
            try {
                if (window.redgoldWasm && typeof window.redgoldWasm.get_data_state === 'function') {
                    const dataState = window.redgoldWasm.get_data_state();
                    console.log('Data State:', dataState);
                    // const dataStateBalances = window.redgoldWasm.get_data_state_balances();
                    // console.log('Data State Balances:', dataStateBalances);
                    
                    // Check if dataState is a number and has changed
                    const dataStateNum = parseInt(dataState);
                    if (!isNaN(dataStateNum) && (previousDataState === null || dataStateNum > previousDataState)) {
                        console.log('Data state incremented from', previousDataState, 'to', dataStateNum, '- updating wallet balance');
                        
                        // Update the previous state value
                        previousDataState = dataStateNum;
                        
                        // Get wallet address from store
                        const walletAddress = store.getters.getWalletAddress;
                        
                        // Update wallet balance if we have an address and the get_data_state_balance_for_address function
                        if (walletAddress && typeof window.redgoldWasm.get_data_state_balance_for_address === 'function') {
                            try {
                                // Get the balance for the wallet address
                                const balanceRdg = window.redgoldWasm.get_data_state_balance_for_address(walletAddress);
                                console.log('Updated RDG balance:', balanceRdg);
                                
                                // Update the store with the RDG balance directly
                                if (balanceRdg !== undefined && balanceRdg !== null) {
                                    const numericBalance = parseFloat(balanceRdg);
                                    if (!isNaN(numericBalance)) {
                                        store.commit('setWalletBalance', numericBalance);
                                    }
                                }
                            } catch (balanceError) {
                                console.error('Error updating wallet balance:', balanceError);
                            }
                        }
                    } else {
                        previousDataState = dataStateNum;
                    }
                } else {
                    console.warn('get_data_state function not available');
                }
            } catch (error) {
                console.error('Error calling get_data_state:', error);
            }
        }, 1000);
        
        // Initialize wallet connection and WebSocket
        console.log('Wallet connection utilities initialized');

        // Initialize and connect the WebSocket client
        if (window.redgoldWasm) {
            const rustWebSocketClient = await import('./utils/rust-websocket-client.js').then(module => module.default);
            rustWebSocketClient.initialize();
            rustWebSocketClient.connect();
            console.log('Rust WebSocket client initialized and connected');
        } else {
            console.error('WASM module not loaded, WebSocket client initialization skipped');
        }

        // Initialize app configuration
        store.dispatch('initConfigData');
        // console.log('App configuration initialized');
        
        // Mount app after initialization
        app.use(router)
        app.use(store)
        app.mount('#app')
    } catch (error) {
        console.error('Failed to initialize app:', error);
        // Mount app anyway to show error state
        app.use(router)
        app.use(store)
        app.mount('#app')
    }
};

initializeApp();

// Import TypeScript module to verify it works
// This won't affect existing functionality
import('./ts-entry.ts').then(module => {
    // console.log('TypeScript module imported successfully');
    // Call the TypeScript initialization function
    module.initTypeScript();
}).catch(error => {
    console.error('Failed to import TypeScript module:', error);
});
