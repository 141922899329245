<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h4>Mnemonics</h4>
      <button class="btn btn-sm btn-success" @click="$emit('show-add-mnemonic-modal')">
        Add Mnemonic
      </button>
    </div>
    
    <div v-if="!mnemonics.length" class="alert alert-secondary">
      No mnemonics stored. Add a mnemonic to get started.
    </div>
    
    <div v-else class="mnemonic-list">
      <div v-for="(mnemonic, index) in mnemonics" :key="index" class="mnemonic-item p-3 mb-2 border border-secondary rounded">
        <div class="d-flex justify-content-between">
          <div>
            <h5>{{ mnemonic.name }}</h5>
            <div class="text-muted">
              <span class="badge bg-secondary me-2">{{ mnemonic.persist_disk ? 'Persisted' : 'In-memory only' }}</span>
              <span v-if="mnemonic.passphrase" class="badge bg-info">Has Passphrase</span>
            </div>
          </div>
          <div>
            <button class="btn btn-sm btn-danger" @click="$emit('remove-mnemonic', index)">
              Remove
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MnemonicsTab',
  props: {
    mnemonics: {
      type: Array,
      required: true
    }
  },
  emits: ['show-add-mnemonic-modal', 'remove-mnemonic']
});
</script>

<style scoped>
.mnemonic-item {
  background-color: #1e1e1e;
  transition: background-color 0.2s;
}

.mnemonic-item:hover {
  background-color: #2c2c2c;
}
</style>
