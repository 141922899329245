<template>
  <div class="recent-transactions">
    <h5 class="mb-3">Recent Transactions</h5>
    <div class="table-responsive">
      <table class="table table-dark table-hover">
        <thead>
          <tr>
            <th>Date</th>
            <th>Type</th>
            <th>Asset</th>
            <th>Amount</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="transactions.length === 0">
            <td colspan="5" class="text-center">No recent transactions</td>
          </tr>
          <tr v-for="(tx, index) in transactions" :key="index">
            <td>{{ formatDate(tx.date) }}</td>
            <td>
              <span :class="getTransactionTypeClass(tx.type)">{{ tx.type }}</span>
            </td>
            <td>{{ tx.asset }}</td>
            <td>{{ formatCurrency(tx.amount, currency) }}</td>
            <td>
              <span :class="getStatusBadgeClass(tx.status)">{{ tx.status }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { formatCurrency } from '../../../utils/app-utils.js';

export default defineComponent({
  name: 'RecentTransactions',
  props: {
    transactions: {
      type: Array,
      required: true
    },
    currency: {
      type: String,
      default: 'USD'
    }
  },
  setup() {
    const formatDate = (date) => {
      if (!date) return 'N/A';
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    };
    
    // Helper functions for styling
    const getTransactionTypeClass = (type) => {
      switch (type) {
        case 'Receive':
          return 'text-success';
        case 'Send':
          return 'text-primary';
        case 'Trade':
          return 'text-warning';
        default:
          return '';
      }
    };
    
    const getStatusBadgeClass = (status) => {
      switch (status) {
        case 'Completed':
          return 'badge bg-success';
        case 'Pending':
          return 'badge bg-warning text-dark';
        case 'Failed':
          return 'badge bg-danger';
        default:
          return 'badge bg-secondary';
      }
    };

    return {
      formatDate,
      formatCurrency,
      getTransactionTypeClass,
      getStatusBadgeClass
    };
  }
});
</script>

<style scoped>
.table-dark {
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
}

.table-dark thead th {
  border-color: #444;
  background-color: rgba(0, 0, 0, 0.3);
}

.table-dark tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.05);
}
</style>
