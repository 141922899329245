// metamask-reset.js
// This file contains functions to aggressively reset MetaMask's connection state

/**
 * Attempts to completely reset MetaMask's connection state
 * This is a more aggressive approach than just disconnecting
 * @returns {Promise<boolean>} True if reset was successful
 */
export const forceMetaMaskReset = async () => {
  try {
    console.log('Attempting aggressive MetaMask reset...');
    
    // Clear any localStorage and sessionStorage items
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && (key.includes('metamask') || key.includes('wallet') || key.includes('web3'))) {
        console.log('Clearing localStorage item:', key);
        localStorage.removeItem(key);
      }
    }
    
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      if (key && (key.includes('metamask') || key.includes('wallet') || key.includes('web3'))) {
        console.log('Clearing sessionStorage item:', key);
        sessionStorage.removeItem(key);
      }
    }
    
    // Try to access MetaMask's internal state
    if (window.ethereum) {
      // Try to clear the selectedAddress
      if (window.ethereum.selectedAddress) {
        console.log('Clearing selectedAddress:', window.ethereum.selectedAddress);
        try {
          // This is a hack and might not work in all versions of MetaMask
          window.ethereum.selectedAddress = null;
        } catch (err) {
          console.log('Error clearing selectedAddress:', err);
        }
      }
      
      // Try to clear any cached accounts
      if (window.ethereum._state && window.ethereum._state.accounts) {
        console.log('Clearing cached accounts:', window.ethereum._state.accounts);
        try {
          window.ethereum._state.accounts = [];
        } catch (err) {
          console.log('Error clearing cached accounts:', err);
        }
      }
      
      // Try to clear permissions
      if (window.ethereum._metamask && typeof window.ethereum._metamask.clearPermissions === 'function') {
        console.log('Clearing MetaMask permissions');
        try {
          await window.ethereum._metamask.clearPermissions();
        } catch (err) {
          console.log('Error clearing permissions:', err);
        }
      }
      
      // Try to directly call the requestPermissions method if available
      if (window.ethereum._metamask && typeof window.ethereum._metamask.requestPermissions === 'function') {
        console.log('Requesting new permissions directly');
        try {
          await window.ethereum._metamask.requestPermissions({ eth_accounts: {} });
        } catch (err) {
          console.log('Error requesting permissions:', err);
        }
      }
      
      // Remove all event listeners
      if (typeof window.ethereum.removeAllListeners === 'function') {
        console.log('Removing all MetaMask event listeners');
        window.ethereum.removeAllListeners();
      }
      
      // Try to reset the connection by calling enable with a timeout
      console.log('Attempting to reset connection with timeout');
      try {
        // Create a timeout promise
        const timeoutPromise = new Promise((_, reject) => {
          setTimeout(() => reject(new Error('Connection timeout')), 100);
        });
        
        // Race the enable call with the timeout
        await Promise.race([
          window.ethereum.enable(),
          timeoutPromise
        ]);
      } catch (err) {
        console.log('Expected timeout error:', err);
        // This is expected to fail with a timeout, which helps reset the connection
      }
    }
    
    // Force page reload if all else fails
    // Uncomment this if you want to force a page reload as a last resort
    // window.location.reload();
    
    return true;
  } catch (error) {
    console.error('Error during MetaMask reset:', error);
    return false;
  }
};

/**
 * Attempts to force MetaMask to show the permission dialog
 * @returns {Promise<string[]>} Array of accounts if successful
 */
export const forceMetaMaskPermissionDialog = async () => {
  try {
    console.log('Attempting to force MetaMask permission dialog...');
    
    // First try to reset MetaMask
    await forceMetaMaskReset();
    
    // Generate a random ID to prevent caching
    const randomId = Math.floor(Math.random() * 1000000);
    
    // Try different approaches to force the permission dialog
    
    // Approach 1: Use eth_requestAccounts with random parameters
    console.log('Approach 1: Using eth_requestAccounts with random parameters');
    try {
      const accounts1 = await window.ethereum.request({
        method: 'eth_requestAccounts',
        params: [{ forceReload: true, random: randomId }]
      });
      
      if (accounts1 && accounts1.length > 0) {
        console.log('Approach 1 succeeded:', accounts1);
        return accounts1;
      }
    } catch (err) {
      console.log('Approach 1 failed:', err);
    }
    
    // Approach 2: Try using enable with a different context
    console.log('Approach 2: Using enable with a different context');
    try {
      const enable = window.ethereum.enable.bind({});
      const accounts2 = await enable();
      
      if (accounts2 && accounts2.length > 0) {
        console.log('Approach 2 succeeded:', accounts2);
        return accounts2;
      }
    } catch (err) {
      console.log('Approach 2 failed:', err);
    }
    
    // Approach 3: Try using a direct call to _metamask.requestPermissions
    console.log('Approach 3: Using _metamask.requestPermissions');
    try {
      if (window.ethereum._metamask && typeof window.ethereum._metamask.requestPermissions === 'function') {
        const permissions = await window.ethereum._metamask.requestPermissions({
          eth_accounts: {}
        });
        
        if (permissions && permissions.length > 0) {
          // Now try to get accounts again
          const accounts3 = await window.ethereum.request({ method: 'eth_accounts' });
          
          if (accounts3 && accounts3.length > 0) {
            console.log('Approach 3 succeeded:', accounts3);
            return accounts3;
          }
        }
      }
    } catch (err) {
      console.log('Approach 3 failed:', err);
    }
    
    // Approach 4: Last resort, try a standard request
    console.log('Approach 4: Standard eth_requestAccounts as last resort');
    try {
      const accounts4 = await window.ethereum.request({ method: 'eth_requestAccounts' });
      
      if (accounts4 && accounts4.length > 0) {
        console.log('Approach 4 succeeded:', accounts4);
        return accounts4;
      }
    } catch (err) {
      console.log('Approach 4 failed:', err);
    }
    
    // If all approaches failed, return an empty array
    console.log('All approaches failed to force permission dialog');
    return [];
  } catch (error) {
    console.error('Error forcing MetaMask permission dialog:', error);
    return [];
  }
};
