/**
 * Utility functions for working with Bitcoin addresses
 */
import * as bitcoin from 'bitcoinjs-lib';
import { Buffer } from 'buffer';

/**
 * Validates a Bitcoin address
 * 
 * @param {string} address - The Bitcoin address to validate
 * @returns {Object} - Object containing the validated address and error if any
 */
export function validateBitcoinAddress(address) {
  try {
    // Try to parse the address
    // This will throw if the address is invalid
    const result = bitcoin.address.fromString(address);
    
    return {
      isValid: true,
      address: address,
      type: result.type, // 'p2pkh', 'p2sh', 'p2wpkh', 'p2wsh', etc.
      error: null
    };
  } catch (error) {
    return {
      isValid: false,
      address: null,
      error: error.message
    };
  }
}
