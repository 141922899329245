<template>
  <div>
    <!-- Configuration Management Section -->
    <h4>Configuration Management</h4>
    
    <div class="d-flex gap-2 mb-4 transaction-buttons">
      <button class="btn flex-grow-1 py-1 px-3 import-button" @click="$emit('show-import-modal')">
        <span class="button-text">Import</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-download button-icon" viewBox="0 0 16 16">
          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
          <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
        </svg>
      </button>
      <button class="btn flex-grow-1 py-1 px-3 export-button" @click="$emit('show-export-modal')">
        <span class="button-text">Export</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-upload button-icon" viewBox="0 0 16 16">
          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
          <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"/>
        </svg>
      </button>
      <button class="btn flex-grow-1 py-1 px-3 purge-button" @click="$emit('purge-configs')">
        <span class="button-text">Purge</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-trash button-icon" viewBox="0 0 16 16">
          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z"/>
          <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z"/>
        </svg>
      </button>
    </div>

    <!-- Wallet Metrics Section -->
    <h4 class="mt-4">Wallet Metrics</h4>
    <div class="row mt-3">
      <div class="col-md-6 col-lg-3 mb-3">
        <div class="metric-card">
          <div class="metric-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-wallet2" viewBox="0 0 16 16">
              <path d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499L12.136.326zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484L5.562 3zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z"/>
            </svg>
          </div>
          <div class="metric-content">
            <h5 class="metric-title">Addresses</h5>
            <div class="metric-value">{{ addressesCount }}</div>
          </div>
        </div>
      </div>
      
      <div class="col-md-6 col-lg-3 mb-3">
        <div class="metric-card">
          <div class="metric-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-key" viewBox="0 0 16 16">
              <path d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8zm4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5z"/>
              <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
            </svg>
          </div>
          <div class="metric-content">
            <h5 class="metric-title">Mnemonics</h5>
            <div class="metric-value">{{ mnemonicsCount }}</div>
          </div>
        </div>
      </div>
      
      <div class="col-md-6 col-lg-3 mb-3">
        <div class="metric-card">
          <div class="metric-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
              <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8Zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816ZM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"/>
            </svg>
          </div>
          <div class="metric-content">
            <h5 class="metric-title">Contacts</h5>
            <div class="metric-value">{{ contactsCount }}</div>
          </div>
        </div>
      </div>
      
      <div class="col-md-6 col-lg-3 mb-3">
        <div class="metric-card">
          <div class="metric-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-hdd-network" viewBox="0 0 16 16">
              <path d="M4.5 5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM3 4.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
              <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8.5v3a1.5 1.5 0 0 1 1.5 1.5h5.5a.5.5 0 0 1 0 1H10A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5H.5a.5.5 0 0 1 0-1H6A1.5 1.5 0 0 1 7.5 10V7H2a2 2 0 0 1-2-2V4zm1 0v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1zm6 7.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5z"/>
            </svg>
          </div>
          <div class="metric-content">
            <h5 class="metric-title">Servers</h5>
            <div class="metric-value">{{ serversCount }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- Additional Metrics -->
    <div class="row mt-2">
      <div class="col-md-6 col-lg-3 mb-3" v-if="xpubsCount !== null">
        <div class="metric-card">
          <div class="metric-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-key-fill" viewBox="0 0 16 16">
              <path d="M3.5 11.5a3.5 3.5 0 1 1 3.163-5H14L15.5 8 14 9.5l-1-1-1 1-1-1-1 1-1-1-1 1H6.663a3.5 3.5 0 0 1-3.163 2zM2.5 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
            </svg>
          </div>
          <div class="metric-content">
            <h5 class="metric-title">XPubs</h5>
            <div class="metric-value">{{ xpubsCount }}</div>
          </div>
        </div>
      </div>
      
      <div class="col-md-6 col-lg-3 mb-3" v-if="keysCount !== null">
        <div class="metric-card">
          <div class="metric-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-shield-lock" viewBox="0 0 16 16">
              <path d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z"/>
              <path d="M9.5 6.5a1.5 1.5 0 0 1-1 1.415l.385 1.99a.5.5 0 0 1-.491.595h-.788a.5.5 0 0 1-.49-.595l.384-1.99a1.5 1.5 0 1 1 2-1.415z"/>
            </svg>
          </div>
          <div class="metric-content">
            <h5 class="metric-title">API Keys</h5>
            <div class="metric-value">{{ keysCount }}</div>
          </div>
        </div>
      </div>
      
      <div class="col-md-6 col-lg-3 mb-3" v-if="keySourcesCount !== null">
        <div class="metric-card">
          <div class="metric-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-keyboard" viewBox="0 0 16 16">
              <path d="M14 5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h12zM2 4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H2z"/>
              <path d="M13 10.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5zm0-2a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5zm-5 0A.25.25 0 0 1 8.25 8h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 8 8.75v-.5zm2 0a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25v-.5zm1 2a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5zm-5-2A.25.25 0 0 1 6.25 8h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 6 8.75v-.5zm-2 0A.25.25 0 0 1 4.25 8h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 4 8.75v-.5zm-2 0A.25.25 0 0 1 2.25 8h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 2 8.75v-.5zm11-2a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5zm-2 0a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5zm-2 0A.25.25 0 0 1 9.25 6h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 9 6.75v-.5zm-2 0A.25.25 0 0 1 7.25 6h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 7 6.75v-.5zm-2 0A.25.25 0 0 1 5.25 6h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 5 6.75v-.5zm-3 0A.25.25 0 0 1 2.25 6h1.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-1.5A.25.25 0 0 1 2 6.75v-.5zm0 4a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5zm0-2a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5zm5 0a.25.25 0 0 1 .25-.25h5.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-5.5a.25.25 0 0 1-.25-.25v-.5z"/>
            </svg>
          </div>
          <div class="metric-content">
            <h5 class="metric-title">Key Sources</h5>
            <div class="metric-value">{{ keySourcesCount }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
  name: 'GeneralTab',
  props: {
    addressesCount: {
      type: Number,
      default: 0
    },
    mnemonicsCount: {
      type: Number,
      default: 0
    },
    contactsCount: {
      type: Number,
      default: 0
    },
    serversCount: {
      type: Number,
      default: 0
    },
    configData: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  setup(props) {
    // Use refs for metrics that can be updated
    const xpubsCount = ref(null);
    const keysCount = ref(null);
    const keySourcesCount = ref(null);
    
    // Function to calculate metrics from config data
    const calculateMetrics = () => {
      try {
        // Create a non-reactive copy of the config data to break reactivity chains
        const configDataCopy = JSON.parse(JSON.stringify(props.configData || {}));
        
        // Calculate number of xpubs if they exist in the config
        if (configDataCopy && 
            typeof configDataCopy === 'object' && 
            configDataCopy.local && 
            Array.isArray(configDataCopy.local.xpubs)) {
          xpubsCount.value = configDataCopy.local.xpubs.length;
        } else {
          xpubsCount.value = null;
        }

        // Calculate number of API keys
        let keyCount = 0;
        if (configDataCopy && 
            typeof configDataCopy === 'object' && 
            configDataCopy.keys) {
          // Count standard API keys
          if (configDataCopy.keys.etherscan) keyCount++;
          if (configDataCopy.keys.recaptcha) keyCount++;
          if (configDataCopy.keys.aws_access) keyCount++;
          if (configDataCopy.keys.aws_secret) keyCount++;
          
          // Count AI API keys
          if (configDataCopy.keys.ai) {
            if (configDataCopy.keys.ai.anthropic) keyCount++;
            if (configDataCopy.keys.ai.openai) keyCount++;
            if (configDataCopy.keys.ai.gemini) keyCount++;
            if (configDataCopy.keys.ai.elevenlabs) keyCount++;
          }
        }
        
        // Calculate number of key sources
        if (configDataCopy && 
            typeof configDataCopy === 'object' && 
            configDataCopy.local && 
            Array.isArray(configDataCopy.local.keys)) {
          keySourcesCount.value = configDataCopy.local.keys.length;
        } else {
          keySourcesCount.value = null;
        }

        // Only set to a value if we have keys, otherwise null
        keysCount.value = keyCount > 0 ? keyCount : null;
        
        console.log('GeneralTab metrics updated:', { 
          xpubsCount: xpubsCount.value, 
          keysCount: keysCount.value,
          keySourcesCount: keySourcesCount.value
        });
      } catch (error) {
        console.error('Error calculating metrics:', error);
      }
    };
    
    // Calculate metrics initially
    calculateMetrics();
    
    // Watch for changes to configData and recalculate metrics
    // Use deep watch to detect nested changes
    watch(() => props.configData, () => {
      // Use setTimeout to break reactivity chain and prevent recursion
      setTimeout(() => {
        calculateMetrics();
      }, 0);
    }, { deep: true });

    return {
      xpubsCount,
      keysCount,
      keySourcesCount
    };
  },
  emits: ['show-import-modal', 'show-export-modal', 'purge-configs']
});
</script>

<style scoped>
.import-button, .export-button, .purge-button {
  background-color: #343a40;
  color: #fff;
  border: 1px solid #6c757d;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.import-button:hover, .export-button:hover {
  background-color: #495057;
  color: #fff;
}

.purge-button {
  background-color: #5a3030;
  color: #fff;
  border: 1px solid #6c4747;
}

.purge-button:hover {
  background-color: #6e3939;
  color: #fff;
}

.button-icon {
  margin-left: 0.5rem;
}

/* Metric Card Styles */
.metric-card {
  background-color: #1e1e1e;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  height: 100%;
  transition: transform 0.2s, background-color 0.2s;
  border: 1px solid #343a40;
}

.metric-card:hover {
  background-color: #2c2c2c;
  transform: translateY(-2px);
}

.metric-icon {
  background-color: rgba(255, 193, 7, 0.1);
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  color: #ffc107;
}

.metric-content {
  flex: 1;
}

.metric-title {
  font-size: 0.9rem;
  color: #adb5bd;
  margin-bottom: 4px;
}

.metric-value {
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
}
</style>
