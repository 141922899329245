/**
 * Utility functions for working with transactions via WASM bindings
 */

// Import the wasm module and store
import { store } from '../store';

/**
 * Validates a Redgold address
 * @param {string} address - The address to validate
 * @returns {string} - Empty string if valid, error message if invalid
 */
export function validateAddress(address) {
  if (!address) {
    return 'Address is required';
  }
  
  try {
    if (!window.redgoldWasm) {
      console.error('WASM not initialized');
      return 'WASM not initialized';
    }
    const result = JSON.parse(window.redgoldWasm.validate_address(address));
    console.log('validateAddress result:', result);
    // If result has a message field, it's an error
    if (result.message) {
      return "Cannot parse address";
    }
    // No message means valid address
    return '';
  } catch (error) {
    console.error('Error validating address:', error);
    return 'Internal address error';
  }
}

/**
 * Prepares a transaction for signing
 * @param {string} recipientAddress - The recipient's address
 * @param {number} amount - The amount to send
 * @param {number} fee - The transaction fee (should be 0)
 * @param {string} [senderAddress] - Optional sender address
 * @returns {Object} - The prepared transaction or error
 */
export function prepareTransaction(recipientAddress, amount, fee, senderAddress = null) {
  try {
    // Get the current wallet address from store if not provided
    const walletAddress = senderAddress || store.getters.getWalletAddress;
    if (!walletAddress) {
      return { code: 'ERROR', message: 'Wallet address not found' };
    }

    if (!window.redgoldWasm) {
      console.error('WASM not initialized');
      return { code: 'ERROR', message: 'WASM not initialized' };
    }
    console.log('Preparing transaction with:', {
      walletAddress,
      recipientAddress,
      amount,
      fee
    });
    const result = window.redgoldWasm.prepare_transaction(
      walletAddress,
      recipientAddress,
      amount,
      fee
    );

    console.log('prepareTransaction result:', result);

    // Parse the result since it's a JSON string
    const parsedResult = JSON.parse(result);

    return parsedResult;
  } catch (error) {
    console.error('Error preparing transaction:', error);
    return { code: 'ERROR', message: error.message || 'Unknown error preparing transaction' };
  }
}

/**
 * Gets the message to sign for a transaction
 * @param {string} walletAddress - The wallet address
 * @returns {Object} - The message to sign or error
 */
export async function getSignMessage(walletAddress) {
  try {
    if (!window.redgoldWasm) {
      console.error('WASM not initialized');
      return { success: false, error: 'WASM not initialized' };
    }
    const result = window.redgoldWasm.get_sign_transaction_details(walletAddress);
    const parsed = JSON.parse(result);
    
    if (parsed.error) {
      return { success: false, error: parsed.error };
    }
    
    return { success: true, messageToSign: parsed.message_to_sign };
  } catch (error) {
    console.error('Error getting sign message:', error);
    return { success: false, error: error.message || 'Unknown error getting sign message' };
  }
}

/**
 * Signs a transaction with Metamask
 * @param {Object} params - Signing parameters
 * @param {string} params.messageToSign - The message to sign
 * @param {string} params.walletAddress - The wallet address
 * @returns {Object} - The signature or error
 */
export async function signTransaction(params) {
  try {
    const { messageToSign, walletAddress } = params;
    
    if (!window.ethereum) {
      return { success: false, error: 'Metamask not found' };
    }

    try {
      // Request account access if needed
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      
      // Sign the message
      const signature = await window.ethereum.request({
        method: 'personal_sign',
        params: [messageToSign, walletAddress],
      });
      
      console.log('MetaMask signature data:', {
        signature,
        messageToSign,
        signatureType: 'MetaMask personal_sign',
        signatureLength: signature.length,
        hexSignature: signature
      });
      
      // Affix the signature using WASM
      const affixResult = window.redgoldWasm.affix_metamask_signature(signature, walletAddress);
      const parsedAffixResult = JSON.parse(affixResult);
      
      if (parsedAffixResult) {
        return { success: false, error: parsedAffixResult.message };
      }
      
      return { 
        success: true, 
        signature,
        messageToSign 
      };
    } catch (err) {
      return { success: false, error: err.message };
    }
  } catch (error) {
    console.error('Error signing transaction:', error);
    return { success: false, error: error.message || 'Unknown error signing transaction' };
  }
}

/**
 * Broadcasts a signed transaction to the network
 * @param {Object|string} transaction - The signed transaction object or JSON string
 * @returns {Object} - The result of the broadcast operation
 */
export function broadcastTransaction(transaction) {
  try {
    // Convert transaction to string if it's an object
    const txJson = typeof transaction === 'string' 
      ? transaction 
      : JSON.stringify(transaction);
    
    if (!window.redgoldWasm) {
      console.error('WASM not initialized');
      return { success: false, error: 'WASM not initialized' };
    }
    const result = window.redgoldWasm.broadcast_transaction(txJson);
    
    // Parse the result
    const parsed = JSON.parse(result);
    
    if (parsed.error) {
      return { success: false, error: parsed.error };
    }
    
    return { 
      success: true, 
      hash: parsed.hash,
      timestamp: Date.now()
    };
  } catch (error) {
    console.error('Error broadcasting transaction:', error);
    return { success: false, error: error.message || 'Unknown error broadcasting transaction' };
  }
}

/**
 * Gets the transaction fee presets
 * @returns {Object} - The fee presets (low, medium, high)
 */
export function getTransactionFeePresets() {
  try {
    if (!window.redgoldWasm) {
      console.error('WASM not initialized');
      return { zero: 0, low: 0.0001, medium: 0.001 };
    }
    const result = window.redgoldWasm.get_transaction_fee_presets();
    return JSON.parse(result);
  } catch (error) {
    // console.error('Error getting fee presets:', error);
    return { zero: 0, low: 0.0001, medium: 0.001 };
  }
}

/**
 * Estimates the confirmation time based on fee
 * @param {number} fee - The transaction fee
 * @param {Object} [presets] - Optional fee presets
 * @returns {string} - Estimated confirmation time
 */
export function estimateConfirmationTime(fee, presets = null) {
  if (!presets) {
    presets = getTransactionFeePresets();
  }
  
  if (fee >= presets.medium) return '~1 minute';
  if (fee >= presets.low) return '~5 minutes';
  return '~10 minutes';
}

/**
 * Formats a Redgold address for display (truncates the middle)
 * @param {string} address - The full address
 * @returns {string} - The truncated address
 */
export function formatAddress(address) {
  if (!address) return '';
  if (address.length <= 16) return address;
  
  const start = address.substring(0, 8);
  const end = address.substring(address.length - 8);
  return `${start}...${end}`;
}

/**
 * Converts USD to RDG based on current exchange rate
 * @param {number} usdAmount - Amount in USD
 * @param {number} exchangeRate - Exchange rate (USD per RDG)
 * @returns {number} - Amount in RDG
 */
export function usdToRdg(usdAmount, exchangeRate = 1.25) {
  if (!usdAmount) return 0;
  return parseFloat(usdAmount) / exchangeRate;
}

/**
 * Converts RDG to USD based on current exchange rate
 * @param {number} rdgAmount - Amount in RDG
 * @param {number} exchangeRate - Exchange rate (USD per RDG)
 * @returns {number} - Amount in USD
 */
export function rdgToUsd(rdgAmount, exchangeRate = 1.25) {
  if (!rdgAmount) return 0;
  return parseFloat(rdgAmount) * exchangeRate;
}
