<template>
  <div class="portfolio-list mt-4">
    <h5 class="mb-3">Your Portfolios</h5>
    <div v-for="portfolio in portfolios" :key="portfolio.id" class="portfolio-item mb-3 p-3 border border-secondary rounded">
      <h5>{{ portfolio.name }}</h5>
      <p>Created: {{ formatDate(portfolio.createdAt) }}</p>
      <p>Total Value: {{ formatCurrency(calculatePortfolioValue(portfolio.assets), currency) }}</p>
      <div class="d-flex justify-content-end">
        <router-link :to="`/app/portfolio/${portfolio.id}`" class="btn btn-outline-light btn-sm">View Details</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { formatCurrency, calculatePortfolioValue } from '../../../utils/app-utils.js';

export default defineComponent({
  name: 'PortfolioList',
  props: {
    portfolios: {
      type: Array,
      required: true
    },
    currency: {
      type: String,
      default: 'USD'
    }
  },
  setup() {
    const formatDate = (date) => {
      if (!date) return 'N/A';
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    };

    return {
      formatDate,
      formatCurrency,
      calculatePortfolioValue
    };
  }
});
</script>

<style scoped>
.portfolio-item {
  background-color: rgba(0, 0, 0, 0.2);
  transition: background-color 0.2s;
}

.portfolio-item:hover {
  background-color: rgba(30, 30, 30, 0.5);
}
</style>
