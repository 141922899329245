<template>
  <div class="modal" :class="{ 'd-block': show }" tabindex="-1" v-if="show">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content bg-dark text-white">
        <div class="modal-header">
          <h5 class="modal-title">Add Deployment Server</h5>
          <button type="button" class="btn-close btn-close-white" @click="$emit('close')"></button>
        </div>
        <div class="modal-body">
          <div class="form-group mb-3">
            <label for="serverHost">SSH Host *</label>
            <input type="text" class="form-control bg-dark text-white" id="serverHost" v-model="server.ssh_host" required>
          </div>
          <div class="form-group mb-3">
            <label for="serverUser">SSH User *</label>
            <input type="text" class="form-control bg-dark text-white" id="serverUser" v-model="server.ssh_user" required>
          </div>
          <div class="form-group mb-3">
            <label for="serverIp">External IPv4 *</label>
            <input type="text" class="form-control bg-dark text-white" id="serverIp" v-model="server.external_ipv4" required>
          </div>
          <div class="form-group mb-3">
            <label for="serverHostname">External Hostname (Optional)</label>
            <input type="text" class="form-control bg-dark text-white" id="serverHostname" v-model="server.external_hostname">
          </div>
          <div class="form-check mb-3">
            <input type="checkbox" class="form-check-input" id="serverMetrics" v-model="server.deploy_metrics_instance">
            <label class="form-check-label" for="serverMetrics">Deploy Metrics Instance</label>
          </div>
          <div class="form-check mb-3">
            <input type="checkbox" class="form-check-input" id="serverLocalhost" v-model="server.is_localhost">
            <label class="form-check-label" for="serverLocalhost">Is Localhost</label>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="$emit('close')">Cancel</button>
          <button 
            type="button" 
            class="btn btn-primary" 
            @click="addServer" 
            :disabled="!server.ssh_host || !server.ssh_user || !server.external_ipv4"
          >
            Add
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive } from 'vue';

export default defineComponent({
  name: 'AddServerModal',
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  emits: ['close', 'add'],
  setup(props, { emit }) {
    const server = reactive({
      ssh_host: '',
      ssh_user: '',
      external_ipv4: '',
      external_hostname: '',
      deploy_metrics_instance: false,
      is_localhost: false,
      instances: []
    });

    const addServer = () => {
      if (server.ssh_host && server.ssh_user && server.external_ipv4) {
        emit('add', { ...server });
        
        // Reset form
        server.ssh_host = '';
        server.ssh_user = '';
        server.external_ipv4 = '';
        server.external_hostname = '';
        server.deploy_metrics_instance = false;
        server.is_localhost = false;
      }
    };

    return {
      server,
      addServer
    };
  }
});
</script>

<style scoped>
.modal {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  border: 1px solid #343a40;
}

.form-control {
  border: 1px solid #343a40;
}

.form-control:focus {
  border-color: #ffc107;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.25);
}
</style>
