<template>
  <div class="sidebar-header-container">
    <!-- Net Asset Value Indicator -->
    <div class="total-asset-value">
      <div class="asset-value-label">Net Asset Value</div>
      <div class="asset-value-container">
        <div class="asset-value-amount" :class="{ 'positive': totalAssetValue > 0, 'negative': totalAssetValue < 0 }">
          {{ formatCurrencyAbbreviated(totalAssetValue, currency) }}
        </div>
        <div class="asset-value-currency">{{ currency }}</div>
      </div>
    </div>
    
    <!-- Performance Indicators -->
    <div class="performance-indicators">
      <div class="performance-column" v-if="hasChartData(performanceData.day.chartData)">
        <MiniLineChart
          :chartValues="performanceData.day.chartData"
          :isPositive="performanceData.day.value > 0"
        />
        <div class="performance-item" :class="{ 'positive': performanceData.day.value > 0, 'negative': performanceData.day.value < 0 }">
          <span class="triangle" v-if="performanceData.day.value !== 0">{{ performanceData.day.value > 0 ? '▲' : '▼' }}</span>
          {{ formatPercentage(performanceData.day.value) }} <span class="performance-period">1d</span>
        </div>
      </div>
      <div class="performance-column" v-if="hasChartData(performanceData.week.chartData)">
        <MiniLineChart
          :chartValues="performanceData.week.chartData"
          :isPositive="performanceData.week.value > 0"
        />
        <div class="performance-item" :class="{ 'positive': performanceData.week.value > 0, 'negative': performanceData.week.value < 0 }">
          <span class="triangle" v-if="performanceData.week.value !== 0">{{ performanceData.week.value > 0 ? '▲' : '▼' }}</span>
          {{ formatPercentage(performanceData.week.value) }} <span class="performance-period">7d</span>
        </div>
      </div>
      <div class="performance-column" v-if="hasChartData(performanceData.year.chartData)">
        <MiniLineChart
          :chartValues="performanceData.year.chartData"
          :isPositive="performanceData.year.value > 0"
        />
        <div class="performance-item" :class="{ 'positive': performanceData.year.value > 0, 'negative': performanceData.year.value < 0 }">
          <span class="triangle" v-if="performanceData.year.value !== 0">{{ performanceData.year.value > 0 ? '▲' : '▼' }}</span>
          {{ formatPercentage(performanceData.year.value) }} <span class="performance-period">1yr</span>
        </div>
      </div>
      <div class="performance-column" v-if="hasChartData(performanceData.all.chartData)">
        <MiniLineChart
          :chartValues="performanceData.all.chartData"
          :isPositive="performanceData.all.value > 0"
        />
        <div class="performance-item" :class="{ 'positive': performanceData.all.value > 0, 'negative': performanceData.all.value < 0 }">
          <span class="triangle" v-if="performanceData.all.value !== 0">{{ performanceData.all.value > 0 ? '▲' : '▼' }}</span>
          {{ formatPercentage(performanceData.all.value) }} <span class="performance-period">all</span>
        </div>
      </div>
    </div>
    
    <!-- Portfolio Selector -->
    <div class="sidebar-dropdown">
      <FilterableDropdown
        id="portfolioDropdown"
        label="Portfolio"
        dropdownClass="portfolio-dropdown"
        menuClass="portfolio-dropdown-menu"
        searchPlaceholder="Filter portfolios..."
        clearButtonTitle="Clear selected portfolio"
        :maxDisplayLength="12"
        v-model="activePortfolio"
        :items="portfolios"
        :displayName="(portfolio) => portfolio.name"
        :isActive="(portfolio, selectedPortfolio) => selectedPortfolio && portfolio.id === selectedPortfolio.id"
        @select="selectPortfolio"
        @clear="clearSelectedPortfolio"
      >
        <template #icon>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <rect x="3" y="8" width="18" height="12" rx="2" ry="2"></rect>
            <path d="M16 8V6a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v2"></path>
            <line x1="12" y1="12" x2="12" y2="16"></line>
            <line x1="10" y1="14" x2="14" y2="14"></line>
          </svg>
        </template>
      </FilterableDropdown>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { defineComponent, ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { formatCurrencyAbbreviated } from '../../utils/app-utils.js';
import FilterableDropdown from '../common/FilterableDropdown.vue';
import MiniLineChart from '../common/MiniLineChart.vue';

export default defineComponent({
  name: 'SidebarHeader',
  components: {
    FilterableDropdown,
    MiniLineChart
  },
  setup() {
    const store = useStore();
    
    // Total asset value from store
    const totalAssetValue = computed(() => store.state.netAssetValue);
    
    // Currency setting (default to USD)
    const currency = ref('USD');
    
    // Get portfolios from store, but only if demo mode is not disabled
    const portfolios = computed(() => {
      // If demo mode is disabled, return empty array (no portfolios)
      if (store.state.isDemoDisabled) {
        return [];
      }
      // Otherwise return the demo portfolios from store
      return store.getters.getPortfolios;
    });
    
    const activePortfolio = computed({
      get: () => store.getters.getActivePortfolio,
      set: (value) => store.commit('setActivePortfolio', value)
    });
    
    // Initialize active portfolio to null (None) by default
    onMounted(() => {
      if (activePortfolio.value === undefined) {
        store.commit('setActivePortfolio', null);
      }
    });
    
    // Select portfolio
    const selectPortfolio = (portfolio) => {
      store.commit('setActivePortfolio', portfolio);
    };
    
    // Clear selected portfolio
    const clearSelectedPortfolio = () => {
      store.commit('setActivePortfolio', null);
    };
    
    // Performance data with fixed values but chart data from store
    const performanceData = computed(() => {
      // Check if demo is disabled
      if (store.state.isDemoDisabled) {
        // Return all zeros when demo is disabled
        return {
          day: { value: 0, fraction: 0, chartData: store.state.chartData['1d'] },
          week: { value: 0, fraction: 0, chartData: store.state.chartData['7d'] },
          year: { value: 0, fraction: 0, chartData: store.state.chartData['1y'] },
          all: { value: 0, fraction: 0, chartData: store.state.chartData['5y'] }
        };
      }
      
      // Return original demo values when demo is enabled
      return {
        day: {
          value: 0.05, 
          fraction: 0.05,
          chartData: store.state.chartData['1d']
        },
        week: {
          value: -0.11, 
          fraction: -0.11,
          chartData: store.state.chartData['7d']
        },
        year: {
          value: 1.02, 
          fraction: 1.02,
          chartData: store.state.chartData['1y']
        },
        all: {
          value: 4.51, 
          fraction: 4.51,
          chartData: store.state.chartData['5y']
        }
      };
    });
    
    // Format percentage for display
    const formatPercentage = (value) => {
      const absValue = Math.abs(value);
      return `${absValue * 100}%`;
    };
    
    // Check if chart data is valid and should be displayed
    const hasChartData = (chartValues) => {
      // Check if chartValues exists and has at least 2 elements (minimum for a line)
      if (!chartValues || chartValues.length < 2) {
        return false;
      }
      
      // Check if chartValues has at least one non-zero value
      return chartValues.some(value => value !== 0);
    };

    return {
      // Performance data
      performanceData,
      formatPercentage,
      hasChartData,
      
      // Total asset value and currency
      totalAssetValue,
      currency,
      
      // Portfolio dropdown
      portfolios,
      activePortfolio,
      selectPortfolio,
      clearSelectedPortfolio,
      
      // Utility functions
      formatCurrencyAbbreviated
    };
  }
});
</script>

<style scoped>
/* Total Asset Value Styles */
.sidebar-header-container {
  width: 100%;
}

.total-asset-value {
  padding: 15px 15px 0px 15px; /* Adjusted: more padding at top (15px), no padding at bottom (0px) */
  background-color: #0d0d1a;
  text-align: center;
  margin-bottom: 0; /* Ensure no margin at bottom */
  border-bottom: none; /* Remove any border */
}

.asset-value-label {
  font-size: 0.85rem;
  color: #999;
  margin-bottom: 2px; /* Keep at 2px */
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.asset-value-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.asset-value-amount {
  font-size: 1.8rem;
  font-weight: 800;
  color: #fff;
}

.asset-value-amount.positive {
  color: #00cc00; /* Matching wallet connected green */
}

.asset-value-amount.negative {
  color: #F44336; /* Red color for negative values */
}

.asset-value-currency {
  font-size: 1.2rem;
  color: #f5f5f5;
  font-weight: 600;
  position: relative;
  top: 3px;
}

.sidebar-dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 8px 0px; /* Added padding to the bottom of the portfolio selector */
  border-bottom: 1px solid #333;
  margin-top: -10px; /* Increased negative margin to further reduce space between percent change and portfolio select */
}

/* Portfolio Dropdown Styles */
.portfolio-dropdown {
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: center;
}

.portfolio-dropdown-menu {
  width: 280px;
}

/* Performance Indicators Styles */
.performance-indicators {
  display: flex;
  justify-content: space-between;
  padding: 0px 15px 0px 15px; /* Removed bottom padding */
  background-color: #0d0d1a;
  margin-top: -18px; /* Extreme negative margin to pull charts up */
  margin-bottom: 0; /* Ensure no margin at bottom */
  border-top: none; /* Remove any border */
  border-bottom: none; /* Remove any border */
}

.performance-column {
  display: flex;
  flex-direction: column;
  width: 25%;
  gap: 4px; /* Add gap between chart and percentage numbers */
  padding: 0 3px; /* Add 2px padding on left and right sides to create 4px space between charts */
}

.performance-item {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  justify-content: center;
}

.performance-value {
  font-weight: 600;
  display: flex;
  align-items: center;
}

.triangle {
  margin-right: 2px;
  font-size: 0.8rem;
}

.performance-period {
  font-size: 0.65rem; /* Reduced from 0.75rem */
  color: #999;
  margin-left: 2px;
}

.performance-item.positive {
  color: #00cc00; /* Same green as NAV */
}

.performance-item.negative {
  color: #F44336; /* Red color for negative values */
}
</style>
