<template>
  <div class="wallet-select-modal" v-if="isVisible">
    <div class="modal-backdrop" @click="closeModal"></div>
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">Connect Wallet</h3>
        <button class="close-button" @click="closeModal">&times;</button>
      </div>
      <div class="modal-body">
        <div v-if="error" class="error-message">{{ error }}</div>
        
        <div class="wallet-options">
          <div 
            v-for="wallet in availableWallets" 
            :key="wallet.id"
            class="wallet-option"
            @click="selectWallet(wallet.id)"
            :class="{ 'disabled': !wallet.isAvailable }"
          >
            <div class="wallet-icon">
              <img :src="require(`@/assets/${wallet.icon}`)" :alt="wallet.name" v-if="wallet.icon" class="wallet-icon-img">
              <div class="wallet-icon-placeholder" v-else>{{ wallet.name.charAt(0) }}</div>
            </div>
            <div class="wallet-info">
              <div class="wallet-name">{{ wallet.name }}</div>
              <div class="wallet-status" v-if="!wallet.isAvailable">Not detected</div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-outline-secondary" @click="closeModal">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { disconnectWallet } from '../utils/wallet-connect';

export default defineComponent({
  name: 'WalletSelectModal',
  props: {
    isVisible: {
      type: Boolean,
      required: true
    }
  },
  emits: ['close', 'select'],
  setup(props, { emit }) {
    const store = useStore();
    const error = ref('');
    const isConnecting = ref(false);
    
    // Check which wallets are available in the browser
    const metamaskAvailable = ref(false);
    const phantomAvailable = ref(false);
    
    onMounted(() => {
      // Check for MetaMask
      metamaskAvailable.value = typeof window.ethereum !== 'undefined';
      
      // Check for Phantom
      phantomAvailable.value = typeof window.solana !== 'undefined' && window.solana.isPhantom;
      
      // Log wallet availability for debugging
      console.log('Wallet availability in modal:', {
        metamask: metamaskAvailable.value,
        phantom: phantomAvailable.value
      });
    });
    
    const availableWallets = computed(() => [
      {
        id: 'metamask',
        name: 'MetaMask',
        icon: 'metamask-icon.svg',
        isAvailable: metamaskAvailable.value
      },
      {
        id: 'phantom',
        name: 'Phantom',
        icon: 'phantom-icon.svg',
        isAvailable: phantomAvailable.value
      },
      {
        id: 'walletconnect',
        name: 'WalletConnect',
        icon: 'walletconnect-icon.svg',
        isAvailable: true // WalletConnect is always available as it's not browser-dependent
      }
    ]);
    
    const selectWallet = async (walletId) => {
      // Find the selected wallet
      const wallet = availableWallets.value.find(w => w.id === walletId);
      
      // If wallet is not available, show error
      if (wallet && !wallet.isAvailable) {
        error.value = `${wallet.name} is not installed or available in your browser.`;
        return;
      }
      
      // Clear any previous errors
      error.value = '';
      isConnecting.value = true;
      
      try {
        // Log wallet selection for debugging
        console.log(`Selecting wallet: ${walletId}, isAvailable:`, wallet ? wallet.isAvailable : 'unknown');
        
        // Emit the selected wallet to parent
        emit('select', walletId);
        console.log('Emitted select event with walletId:', walletId);
        
        // Direct connection for MetaMask
        if (walletId === 'metamask' && window.ethereum) {
          // Request accounts
          const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
          const chainId = await window.ethereum.request({ method: 'eth_chainId' });
          
          if (accounts && accounts.length > 0) {
            // Update the store
            store.commit('setWalletConnected', true);
            store.commit('setWalletAddress', accounts[0]);
            store.commit('setWalletChainId', parseInt(chainId, 16));
            store.commit('setWalletType', 'metamask');
            
            // Set up event listeners
            window.ethereum.on('accountsChanged', (newAccounts) => {
              console.log('WalletSelectModal: MetaMask accountsChanged event triggered:', newAccounts);
              if (newAccounts.length === 0) {
                // User disconnected
                console.log('WalletSelectModal: No accounts found, disconnecting wallet');
                disconnectWallet();
              } else {
                console.log('WalletSelectModal: Updating wallet address to:', newAccounts[0]);
                store.commit('setWalletAddress', newAccounts[0]);
              }
            });
            
            window.ethereum.on('chainChanged', (newChainId) => {
              store.commit('setWalletChainId', parseInt(newChainId, 16));
            });
            
            window.ethereum.on('disconnect', () => {
              disconnectWallet();
            });
            
            // Store the provider for later use
            window.walletProvider = window.ethereum;
            
            // Close the modal after successful connection
            closeModal();
          } else {
            error.value = 'No accounts found';
          }
        } 
        // Direct connection for Phantom
        else if (walletId === 'phantom' && window.solana?.isPhantom) {
          // Connect to Phantom
          const response = await window.solana.connect();
          const publicKey = response.publicKey.toString();
          
          if (publicKey) {
            // Update the store
            store.commit('setWalletConnected', true);
            store.commit('setWalletAddress', publicKey);
            store.commit('setWalletChainId', 'solana'); // Using string for Solana
            store.commit('setWalletType', 'phantom');
            
            // Set up event listeners
            window.solana.on('disconnect', () => {
              disconnectWallet();
            });
            
            window.solana.on('accountChanged', async () => {
              // Mirror the active account change instead of disconnecting
              try {
                const response = await window.solana.connect();
                const publicKey = response.publicKey.toString();
                if (publicKey) {
                  store.commit('setWalletAddress', publicKey);
                  console.log('Updated Phantom wallet address:', publicKey);
                }
              } catch (error) {
                console.error('Error updating Phantom account:', error);
              }
            });
            
            // Store the provider for later use
            window.walletProvider = window.solana;
            
            // Close the modal after successful connection
            closeModal();
          } else {
            error.value = 'No public key found';
          }
        }
        // For WalletConnect, we'll let the parent handle it
      } catch (err) {
        console.error('Wallet connection error:', err);
        error.value = 'An unexpected error occurred while connecting to wallet.';
      } finally {
        isConnecting.value = false;
      }
    };
    
    const closeModal = () => {
      emit('close');
    };
    
    return {
      error,
      isConnecting,
      availableWallets,
      selectWallet,
      closeModal
    };
  }
});
</script>

<style scoped>
.wallet-select-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Increased z-index to ensure it's above other elements */
  pointer-events: none; /* Allow clicks to pass through by default */
}

.modal-backdrop {
  position: fixed; /* Changed from absolute to fixed */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998; /* Ensure backdrop is below content but above other elements */
  pointer-events: auto; /* Capture clicks on the backdrop */
}

.modal-content {
  position: relative;
  background-color: rgba(30, 30, 30, 0.95);
  border: 1px solid #333;
  border-radius: 8px;
  width: 100%;
  max-width: 450px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  z-index: 10000; /* Ensure content is above backdrop */
  color: #fff;
  pointer-events: auto; /* Ensure clicks are captured on the modal content */
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  border-bottom: 1px solid #333;
}

.modal-title {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #aaa;
  cursor: pointer;
}

.close-button:hover {
  color: #fff;
}

.modal-body {
  padding: 1.5rem;
}

.error-message {
  color: #ff6b6b;
  margin-bottom: 1.5rem;
  padding: 0.5rem;
  background-color: rgba(255, 107, 107, 0.1);
  border-radius: 4px;
  font-size: 0.9rem;
}

.wallet-options {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}

.wallet-option {
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 8px;
  background-color: #2a2a2a;
  cursor: pointer;
  transition: background-color 0.2s;
}

.wallet-option:hover {
  background-color: #333;
}

.wallet-option.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.wallet-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  overflow: hidden;
}

.wallet-icon-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.wallet-icon-placeholder {
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
}

.wallet-info {
  flex: 1;
}

.wallet-name {
  font-weight: 500;
  font-size: 1.1rem;
}

.wallet-status {
  font-size: 0.8rem;
  color: #aaa;
  margin-top: 0.25rem;
}

.modal-footer {
  padding: 1rem 1.5rem;
  border-top: 1px solid #333;
  display: flex;
  justify-content: flex-end;
}

.btn {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
}

.btn-outline-secondary {
  background-color: transparent;
  border: 1px solid #555;
  color: #fff;
}

.btn-outline-secondary:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
</style>
