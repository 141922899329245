<template>
  <div class="portfolio-details">
    <div class="container mt-4">
      <div class="row">
        <div class="col-12">
          <div class="d-flex justify-content-between align-items-center mb-4">
            <h2>Portfolio Details</h2>
            <router-link to="/app" class="btn btn-outline-light">Back to Dashboard</router-link>
          </div>
          
          <div v-if="loading" class="text-center py-5">
            <p>Loading portfolio details...</p>
          </div>
          
          <div v-else-if="!portfolio" class="text-center py-5">
            <p>Portfolio not found</p>
          </div>
          
          <div v-else>
            <div class="card bg-dark text-white mb-4">
              <div class="card-header">
                <h3>{{ portfolio.name }}</h3>
              </div>
              <div class="card-body">
                <p>Created: {{ formatDate(portfolio.createdAt) }}</p>
                <p>Total Value: {{ formatCurrency(calculatePortfolioValue(portfolio.assets)) }}</p>
                
                <h4 class="mt-4">Rebalance Rules</h4>
                <div v-for="(rule, index) in portfolio.rebalanceRules" :key="index" class="mb-2">
                  <div v-if="rule.type === 'threshold'">
                    Rebalance when any asset deviates by {{ rule.threshold }}% from target allocation
                  </div>
                  <div v-else-if="rule.type === 'schedule'">
                    Scheduled rebalance: {{ rule.schedule }}
                  </div>
                </div>
              </div>
            </div>
            
            <div class="card bg-dark text-white">
              <div class="card-header">
                <h4>Asset Allocation</h4>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-dark">
                    <thead>
                      <tr>
                        <th>Asset</th>
                        <th>Network</th>
                        <th>Target Allocation</th>
                        <th>Current Value</th>
                        <th>Current Allocation</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="asset in portfolio.assets" :key="asset.symbol">
                        <td>{{ asset.symbol }}</td>
                        <td>{{ asset.network }}</td>
                        <td>{{ asset.allocation }}%</td>
                        <td>{{ formatCurrency(asset.currentValue || 0) }}</td>
                        <td>
                          {{ calculateCurrentAllocation(asset) }}%
                          <span 
                            v-if="isOutOfBalance(asset)" 
                            class="badge bg-warning text-dark ms-2"
                          >
                            Out of balance
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { formatCurrency, calculatePortfolioValue } from '../utils/app-utils.js';

export default defineComponent({
  name: 'PortfolioDetails',
  setup() {
    const route = useRoute();
    const portfolioId = computed(() => route.params.id);
    const loading = ref(true);
    const portfolio = ref(null);
    
    onMounted(async () => {
      // In a real app, this would be an API call
      // Simulating API call with timeout
      setTimeout(() => {
        // Mock data
        if (portfolioId.value === '1') {
          portfolio.value = {
            id: '1',
            name: 'Balanced Growth Portfolio',
            createdAt: new Date(2023, 0, 15),
            assets: [
              { symbol: 'BTC', network: 'Bitcoin', allocation: 40, currentValue: 24000 },
              { symbol: 'ETH', network: 'Ethereum', allocation: 30, currentValue: 18000 },
              { symbol: 'SOL', network: 'Solana', allocation: 20, currentValue: 12000 },
              { symbol: 'USDC', network: 'Ethereum', allocation: 10, currentValue: 6000 }
            ],
            rebalanceRules: [
              { type: 'threshold', threshold: 5 }
            ]
          };
        }
        loading.value = false;
      }, 1000);
    });
    
    const formatDate = (date) => {
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    };
    
    const calculateCurrentAllocation = (asset) => {
      if (!portfolio.value) return 0;
      const totalValue = calculatePortfolioValue(portfolio.value.assets);
      if (totalValue === 0) return 0;
      return Math.round(((asset.currentValue || 0) / totalValue) * 100);
    };
    
    const isOutOfBalance = (asset) => {
      if (!portfolio.value) return false;
      const currentAllocation = calculateCurrentAllocation(asset);
      const targetAllocation = asset.allocation;
      const threshold = portfolio.value.rebalanceRules.find(r => r.type === 'threshold')?.threshold || 5;
      
      return Math.abs(currentAllocation - targetAllocation) > threshold;
    };
    
    return {
      loading,
      portfolio,
      formatDate,
      formatCurrency,
      calculatePortfolioValue,
      calculateCurrentAllocation,
      isOutOfBalance
    };
  }
});
</script>

<style scoped>
.portfolio-details {
  min-height: calc(100vh - 70px);
}

.card {
  border: 1px solid #333;
  margin-bottom: 1.5rem;
}

.card-header {
  background-color: rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid #333;
}

.table {
  margin-bottom: 0;
}

.table-dark {
  background-color: transparent;
}
</style> 