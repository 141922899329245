<template>
  <div class="key-metrics-summary p-1 mb-0">
    <div class="d-flex justify-content-between mb-0">
      <div class="metric-item">
        <div class="metric-label">24h Change</div>
        <div class="metric-value" :class="{ positive: keyMetrics.dailyChange.startsWith('+') }">
          {{ keyMetrics.dailyChange }}
        </div>
      </div>
      <div class="metric-item">
        <div class="metric-label">7d High/Low</div>
        <div class="metric-value">{{ keyMetrics.weeklyHighLow }}</div>
      </div>
      <div class="metric-item">
        <div class="metric-label">Monthly Change</div>
        <div class="metric-value" :class="{ positive: keyMetrics.monthlyChange.startsWith('+') }">
          {{ keyMetrics.monthlyChange }}
        </div>
      </div>
      <div class="metric-item">
        <div class="metric-label">Xpub</div>
        <div class="metric-value">{{ keyMetrics.xpub || 'N/A' }}</div>
      </div>
    </div>
    <div class="d-flex justify-content-between mt-2 mb-0">
      <div class="metric-item">
        <div class="metric-label">Num Assets</div>
        <div class="metric-value">{{ keyMetrics.numAssets }}</div>
      </div>
      <div class="metric-item">
        <div class="metric-label">Days Since Max</div>
        <div class="metric-value">{{ keyMetrics.daysSinceMax }}</div>
      </div>
      <div class="metric-item">
        <div class="metric-label">Portfolio Age</div>
        <div class="metric-value">{{ keyMetrics.portfolioAge }}</div>
      </div>
      <div class="metric-item">
        <div class="metric-label">Address</div>
        <div class="metric-value">{{ keyMetrics.address || 'N/A' }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'KeyMetrics',
  props: {
    keyMetrics: {
      type: Object,
      required: true
    }
  }
});
</script>

<style scoped>
.key-metrics-summary {
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 4px 8px;
  margin-bottom: 0;
}

.metric-item {
  text-align: center;
  flex: 1;
  padding: 0 2px;
}

.metric-label {
  font-size: 0.65rem;
  color: #999;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 1px;
}

.metric-value {
  font-size: 0.8rem;
  font-weight: 600;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.metric-value.positive {
  color: #00cc00;
}
</style>
