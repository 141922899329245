/**
 * Utility functions for working with Ethereum addresses
 */
import { isAddress, getAddress } from 'viem';

/**
 * Validates and normalizes an Ethereum address
 * 
 * @param {string} address - The Ethereum address to validate
 * @returns {Object} - Object containing the validated address and error if any
 */
export function validateEthereumAddress(address) {
  try {
    // Check if the address is valid
    if (!isAddress(address)) {
      return {
        isValid: false,
        address: null,
        error: 'Invalid Ethereum address format'
      };
    }
    
    // Normalize the address (checksum format)
    const checksumAddress = getAddress(address);
    
    return {
      isValid: true,
      address: checksumAddress,
      error: null
    };
  } catch (error) {
    return {
      isValid: false,
      address: null,
      error: error.message
    };
  }
}
