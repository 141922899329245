/**
 * Utility functions for working with extended public keys (xpubs)
 */
import BIP32Factory from 'bip32';
import * as ecc from 'tiny-secp256k1';

// Initialize the BIP32 factory with the secp256k1 implementation
const bip32 = BIP32Factory(ecc);

/**
 * Derives a child public key from an xpub at a specific derivation path
 * 
 * @param {string} xpub - The extended public key in xpub format
 * @param {string} path - The derivation path (e.g. "0/0" or "m/0/0")
 * @returns {Object} - Object containing the derived public key bytes and error if any
 */
export function derivePublicKeyFromXpub(xpub, path) {
  try {
    // Extract only the non-hardened part of the derivation path
    // This handles cases where the path includes hardened components that
    // should be skipped since the xpub already includes them
    const nonHardenedPath = extractNonHardenedSuffix(path);
    
    // Parse the xpub
    const hdNode = bip32.fromBase58(xpub);
    
    // Split the path into its components
    const pathComponents = nonHardenedPath.split('/');
    
    // Derive the child key
    let derivedNode = hdNode;
    for (const component of pathComponents) {
      // Skip empty components (can happen if path starts or ends with /)
      if (!component) continue;
      
      const index = parseInt(component, 10);
      if (isNaN(index)) {
        throw new Error(`Invalid path component: ${component}`);
      }
      
      // Double-check that we're not trying to do hardened derivation
      if (index >= 0x80000000) {
        throw new Error("Cannot perform hardened derivation with an xpub");
      }
      
      derivedNode = derivedNode.derive(index);
    }
    
    // Get the public key in compressed format
    const publicKeyBytes = derivedNode.publicKey;
    
    return {
      publicKeyBytes: Array.from(publicKeyBytes),
      error: null
    };
  } catch (error) {
    console.error(`Error deriving public key from xpub: ${xpub} ${path}`, error);
    return {
      publicKeyBytes: null,
      error: error.message
    };
  }
}

/**
 * Extracts the non-hardened suffix of a derivation path
 * 
 * @param {string} path - The full derivation path
 * @returns {string} - Only the non-hardened suffix of the path
 */
function extractNonHardenedSuffix(path) {
  // Normalize the path (remove 'm/' prefix if present)
  const normalizedPath = path.replace(/^m\//, '');
  
  // Split path into components
  const components = normalizedPath.split('/');
  
  // Find the last hardened component
  let lastHardenedIndex = -1;
  for (let i = 0; i < components.length; i++) {
    if (components[i].endsWith("'") || components[i].endsWith("h")) {
      lastHardenedIndex = i;
    }
  }
  
  // Extract only the non-hardened components after the last hardened component
  const nonHardenedComponents = components.slice(lastHardenedIndex + 1);
  
  // Join them back into a path
  return nonHardenedComponents.join('/');
}
