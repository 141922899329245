<template>
  <div class="mini-line-chart">
    <Line
      :data="chartData"
      :options="chartOptions"
    />
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { Line } from 'vue-chartjs';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

export default defineComponent({
  name: 'MiniLineChart',
  components: {
    Line
  },
  props: {
    chartValues: {
      type: Array,
      required: true
    },
    isPositive: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    // Compute chart data based on props
    const chartData = computed(() => ({
      labels: Array(props.chartValues.length).fill(''), // Empty labels for a clean look
      datasets: [
        {
          data: props.chartValues,
          borderColor: props.isPositive ? '#006600' : '#a02020', // Darker green for positive, darker red for negative
          backgroundColor: props.isPositive ? 'rgba(0, 102, 0, 0.1)' : 'rgba(160, 32, 32, 0.1)',
          tension: 0.4, // Makes the line smooth
          borderWidth: 2,
          pointRadius: 0, // Hide points
          fill: true
        }
      ]
    }));

    // Chart options
    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false // Hide legend
        },
        tooltip: {
          enabled: false // Disable tooltips
        }
      },
      scales: {
        x: {
          display: false // Hide x-axis
        },
        y: {
          display: false // Hide y-axis
        }
      },
      elements: {
        line: {
          borderJoinStyle: 'round' // Smooth line joins
        }
      }
    };

    return {
      chartData,
      chartOptions
    };
  }
});
</script>

<style scoped>
.mini-line-chart {
  height: 35px;
  width: 100%;
  margin-bottom: 2px;
}
</style>
