<template>
  <div class="asset-allocation">
    <div class="asset-allocation-label">Asset Allocation</div>
    <div class="allocation-items">
      <div v-if="assetAllocation.length === 0" class="text-center py-2">
        <p class="text-muted">No asset allocation data</p>
      </div>
      <div v-for="asset in assetAllocation" :key="asset.name" class="allocation-item">
        <div class="allocation-header">
          <span class="allocation-name">{{ asset.name }}</span>
          <span class="allocation-value positive">{{ formatCurrencyAbbreviated(asset.value, currency) }}</span>
          <span class="allocation-percentage">{{ asset.percentage }}%</span>
        </div>
        <div class="allocation-bar-container">
          <div class="allocation-bar" :style="{ width: asset.percentage + '%', backgroundColor: asset.color }"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { formatCurrencyAbbreviated } from '../../../utils/app-utils.js';

export default defineComponent({
  name: 'AssetAllocation',
  props: {
    assetAllocation: {
      type: Array,
      required: true
    },
    currency: {
      type: String,
      default: 'USD'
    }
  },
  setup() {
    return {
      formatCurrencyAbbreviated
    };
  }
});
</script>

<style scoped>
.asset-allocation-label {
  font-size: 0.85rem;
  color: #999;
  margin-bottom: 8px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  text-align: center;
  padding-bottom: 10px;
}

.allocation-item {
  display: flex;
  flex-direction: column;
  margin-bottom: -2px;
}

.allocation-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.9rem;
}

.allocation-name {
  font-weight: 500;
  min-width: 35px;
  font-size: 0.9rem;
}

.allocation-value {
  font-weight: 600;
  color: #fff;
}

.allocation-value.positive {
  color: #00cc00;
}

.allocation-percentage {
  font-weight: 600;
  color: #fff;
  min-width: 35px;
  text-align: right;
}

.allocation-bar-container {
  width: 100%;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  overflow: hidden;
}

.allocation-bar {
  height: 100%;
  border-radius: 2px;
}
</style>
