<template>
  <div class="balance-info alert alert-info mb-3">
    <div class="d-flex justify-content-between">
      <span>Available Balance:</span>
      <span>{{ formatRdgBalance(walletBalance) }}</span>
      <span>Amount:</span>
      <span>{{ formatRdgBalance(parseFloat(amount)) }}</span>
    </div>
    <div class="d-flex justify-content-between fw-bold">
      <span>Remaining Balance:</span>
      <span>{{ formatRdgBalance(walletBalance - parseFloat(amount)) }}</span>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'BalanceInfo',
  props: {
    amount: {
      type: [String, Number],
      default: 0
    }
  },
  setup() {
    const store = useStore();
    const walletBalance = computed(() => store.getters.getWalletBalance);
    
    const formatRdgBalance = (balance) => {
      const symbol = 'Ⓡ';
      return `${symbol} ${parseFloat(balance).toFixed(8)}`;
    };
    
    return {
      formatRdgBalance,
      walletBalance
    };
  }
});
</script>

<style scoped>
.balance-info {
  margin-top: -25px;
  background-color: rgba(23, 162, 184, 0.1);
  border-color: rgba(23, 162, 184, 0.2);
  color: #e0e0e0;
  /* margin-bottom: -100px; */
}
</style>
