<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h4>Deployment Servers</h4>
      <button class="btn btn-sm btn-success" @click="$emit('show-add-server-modal')">
        Add Server
      </button>
    </div>
    
    <div v-if="!deploymentServers.length" class="alert alert-secondary">
      No deployment servers configured. Add a server to get started.
    </div>
    
    <div v-else class="server-list">
      <div v-for="(server, index) in deploymentServers" :key="index" class="server-item p-3 mb-2 border border-secondary rounded">
        <div class="d-flex justify-content-between">
          <div>
            <h5>{{ server.ssh_host }}</h5>
            <div class="text-muted">User: {{ server.ssh_user }}</div>
            <div class="text-muted">IP: {{ server.external_ipv4 }}</div>
            <div v-if="server.external_hostname" class="text-muted">Hostname: {{ server.external_hostname }}</div>
            <div class="mt-2">
              <span class="badge bg-info me-2">{{ server.instances?.length || 0 }} Instances</span>
              <span class="badge bg-success" v-if="server.deploy_metrics_instance">Metrics Enabled</span>
            </div>
          </div>
          <div>
            <button class="btn btn-sm btn-danger" @click="$emit('remove-server', index)">
              Remove
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DeploymentTab',
  props: {
    deploymentServers: {
      type: Array,
      required: true
    }
  },
  emits: ['show-add-server-modal', 'remove-server']
});
</script>

<style scoped>
.server-item {
  background-color: #1e1e1e;
  transition: background-color 0.2s;
}

.server-item:hover {
  background-color: #2c2c2c;
}
</style>
