<template>
  <div class="wasm-test">
    <h4>WASM Test</h4>
    <div class="test-results">
      <p>Add Result (5 + 3): {{ addResult }}</p>
      <p>Add Result (10 + 20): {{ addResult2 }}</p>
      <p>Add Result with variables: {{ dynamicAddResult }}</p>
    </div>
    <div class="input-section">
      <div class="input-group">
        <label for="num1">First Number:</label>
        <input id="num1" v-model="num1" type="number" @input="calculateDynamicSum" />
      </div>
      <div class="input-group">
        <label for="num2">Second Number:</label>
        <input id="num2" v-model="num2" type="number" @input="calculateDynamicSum" />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import initWasm, { add } from '../wasm/redgold_gui.js';

export default {
  name: 'WasmTest',
  data() {
    return {
      addResult: null,
      addResult2: null,
      dynamicAddResult: null,
      wasmModule: null,
      num1: 7,
      num2: 8
    }
  },
  async mounted() {
    try {
      this.wasmModule = await initWasm();
      
      // Test WASM add function with fixed values
      /* eslint-disable no-undef */
      this.addResult = add(5n, 3n);
      this.addResult2 = add(10n, 20n);
      /* eslint-enable no-undef */
      
      // Calculate dynamic sum with initial values
      this.calculateDynamicSum();
      
      console.log('WASM add function tested successfully');
    } catch (error) {
      console.error('WASM test error:', error);
    }
  },
  methods: {
    calculateDynamicSum() {
      if (this.num1 && this.num2) {
        try {
          /* eslint-disable no-undef */
          const n1 = BigInt(parseInt(this.num1));
          const n2 = BigInt(parseInt(this.num2));
          this.dynamicAddResult = add(n1, n2);
          /* eslint-enable no-undef */
          console.log(`Dynamic sum: ${this.num1} + ${this.num2} = ${this.dynamicAddResult}`);
        } catch (e) {
          console.error('Error calculating dynamic sum:', e);
        }
      }
    }
  }
}
</script>

<style scoped>
.wasm-test {
  margin: 20px 0;
  padding: 20px;
  background-color: #191a19;
  border-radius: 8px;
}

.test-results {
  margin-bottom: 20px;
}

.input-section {
  margin-top: 20px;
  padding: 15px;
  background-color: #222;
  border-radius: 6px;
}

.input-group {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

label {
  margin-right: 10px;
  min-width: 120px;
}

input {
  padding: 8px;
  background-color: #333;
  color: white;
  border: 1px solid #444;
  border-radius: 4px;
}
</style>
