<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <div>
    <h4>API Keys</h4>
    <div class="form-group mb-3">
      <label for="anthropicKey">Anthropic API Key</label>
      <input type="password" class="form-control bg-dark text-white" id="anthropicKey" v-model="localAiKeys.anthropic" placeholder="Enter Anthropic API key">
    </div>
    <div class="form-group mb-3">
      <label for="openaiKey">OpenAI API Key</label>
      <input type="password" class="form-control bg-dark text-white" id="openaiKey" v-model="localAiKeys.openai" placeholder="Enter OpenAI API key">
    </div>
    <div class="form-group mb-3">
      <label for="geminiKey">Gemini API Key</label>
      <input type="password" class="form-control bg-dark text-white" id="geminiKey" v-model="localAiKeys.gemini" placeholder="Enter Gemini API key">
    </div>
    <div class="form-group mb-3">
      <label for="elevenlabsKey">ElevenLabs API Key</label>
      <input type="password" class="form-control bg-dark text-white" id="elevenlabsKey" v-model="localAiKeys.elevenlabs" placeholder="Enter ElevenLabs API key">
    </div>
    <div class="form-group mb-3">
      <label for="etherscanKey">Etherscan API Key</label>
      <input type="password" class="form-control bg-dark text-white" id="etherscanKey" v-model="localKeys.etherscan" placeholder="Enter Etherscan API key">
    </div>
    <div class="form-group mb-3">
      <label for="recaptchaKey">reCAPTCHA Key</label>
      <input type="password" class="form-control bg-dark text-white" id="recaptchaKey" v-model="localKeys.recaptcha" placeholder="Enter reCAPTCHA key">
    </div>
    <div class="form-group mb-3">
      <label for="awsAccessKey">AWS Access Key</label>
      <input type="password" class="form-control bg-dark text-white" id="awsAccessKey" v-model="localKeys.aws_access" placeholder="Enter AWS Access key">
    </div>
    <div class="form-group mb-3">
      <label for="awsSecretKey">AWS Secret Key</label>
      <input type="password" class="form-control bg-dark text-white" id="awsSecretKey" v-model="localKeys.aws_secret" placeholder="Enter AWS Secret key">
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
  name: 'KeysTab',
  props: {
    keys: {
      type: Object,
      required: true
    },
    aiKeys: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    // Create local copies of the props
    const localKeys = ref({
      etherscan: props.keys.etherscan,
      recaptcha: props.keys.recaptcha,
      aws_access: props.keys.aws_access,
      aws_secret: props.keys.aws_secret
    });

    const localAiKeys = ref({
      anthropic: props.aiKeys.anthropic,
      openai: props.aiKeys.openai,
      gemini: props.aiKeys.gemini,
      elevenlabs: props.aiKeys.elevenlabs
    });

    // Watch for changes in the props and update local data
    watch(() => props.keys, (newValue) => {
      localKeys.value.etherscan = newValue.etherscan;
      localKeys.value.recaptcha = newValue.recaptcha;
      localKeys.value.aws_access = newValue.aws_access;
      localKeys.value.aws_secret = newValue.aws_secret;
    }, { deep: true });

    watch(() => props.aiKeys, (newValue) => {
      localAiKeys.value.anthropic = newValue.anthropic;
      localAiKeys.value.openai = newValue.openai;
      localAiKeys.value.gemini = newValue.gemini;
      localAiKeys.value.elevenlabs = newValue.elevenlabs;
    }, { deep: true });

    // Watch for changes in local data and emit update events
    watch(localKeys, (newValue) => {
      emit('update:keys', {
        etherscan: newValue.etherscan,
        recaptcha: newValue.recaptcha,
        aws_access: newValue.aws_access,
        aws_secret: newValue.aws_secret
      });
    }, { deep: true });

    watch(localAiKeys, (newValue) => {
      emit('update:aiKeys', {
        anthropic: newValue.anthropic,
        openai: newValue.openai,
        gemini: newValue.gemini,
        elevenlabs: newValue.elevenlabs
      });
    }, { deep: true });

    return {
      localKeys,
      localAiKeys
    };
  }
});
</script>

<style scoped>
.form-control {
  border: 1px solid #343a40;
}

.form-control:focus {
  border-color: #ffc107;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.25);
}
</style>
