<template>
  <div class="d-flex align-items-center dashboard-header">
    <h3 class="mb-0 ms-3">Dashboard</h3>
    <button class="btn btn-outline-secondary btn-sm ms-3 d-flex align-items-center refresh-btn" @click="refreshDashboard">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <path d="M23 4v6h-6"></path>
        <path d="M1 20v-6h6"></path>
        <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10"></path>
        <path d="M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path>
      </svg>
      <span class="ms-1">Refresh</span>
    </button>
    <div class="wallet-balance ms-5 d-flex align-items-center">
      <div class="wallet-balance-label me-3">Connected Wallet Redgold Balance:</div>
      <div class="wallet-balance-container">
        <div class="wallet-balance-amount positive">{{ formatCurrencyAbbreviated(walletBalance, currency) }}</div>
        <div class="wallet-balance-currency">{{ currency }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { formatCurrencyAbbreviated } from '../../../utils/app-utils.js';

export default defineComponent({
  name: 'DashboardHeader',
  emits: ['refresh'],
  setup(props, { emit }) {
    const store = useStore();
    
    // Get wallet balance from store
    const walletBalance = computed(() => store.getters.getWalletBalance * 100);
    const currency = ref('USD');

    // Get wallet address from store
    const walletAddress = computed(() => store.getters.getWalletAddress);

    // Function to handle refresh button click
    const refreshDashboard = () => {
      // Just trigger websocket subscribe to get real-time balance updates
      if (window.jsWebSocketClient) {
        window.jsWebSocketClient.updateWithConfigAndWalletAddress();
      }
      emit('refresh');
    };

    onMounted(() => {
      // Trigger websocket subscribe to get real-time balance updates
      if (window.jsWebSocketClient) {
        window.jsWebSocketClient.updateWithConfigAndWalletAddress();
      }
    });

    return {
      refreshDashboard,
      walletBalance,
      currency,
      formatCurrencyAbbreviated
    };
  }
});
</script>

<style scoped>
.dashboard-header {
  /* margin-bottom: 1rem; */
  margin-bottom: -20px;
  margin-top: -20px;
}

.refresh-btn {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  line-height: 1;
}

/* Wallet Balance Styles */
.wallet-balance {
  text-align: left;
  margin-bottom: -10px;
}

.wallet-balance-label {
  font-size: 0.85rem;
  color: #999;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 600;
  white-space: nowrap;
}

.wallet-balance-container {
  /* padding-left: 50px; */
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 5px;
}

.wallet-balance-amount {
  font-size: 1.8rem;
  font-weight: 800;
  color: #fff;
}

.wallet-balance-amount.positive {
  color: #00cc00; /* Matching the green color from SidebarHeader.vue */
}

.wallet-balance-amount.negative {
  color: #F44336; /* Red color for negative values */
}

.wallet-balance-currency {
  font-size: 1.2rem;
  color: #f5f5f5;
  font-weight: 600;
  position: relative;
  top: 3px;
}
</style>
