<template>
  <div class="wallet-status-container">
    <div class="wallet-status-container-inner">
      <div v-if="!isWalletConnected" class="wallet-status">
        <span>{{ statusText }}</span>
        <button 
          v-if="!isExplorerRoute && shouldShowDemoView" 
          class="clear-demo-btn" 
          @click="clearDemoData"
          title="Clear demo data"
        >
          Clear Demo
        </button>
      </div>
      
      <div v-if="isWalletConnected" class="wallet-status connected">
        <span>Wallet connected</span>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'WalletStatus',
  props: {
    isExplorerRoute: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const isWalletConnected = computed(() => store.getters.isWalletConnected);
    const isDemoDisabled = computed(() => store.getters.isDemoDisabled);
    const shouldShowDemoView = computed(() => store.getters.shouldShowDemoView);
    const walletAddress = computed(() => store.getters.getWalletAddress);
    
    // Truncate wallet address for display (e.g., 0x1234...5678)
    const truncatedAddress = computed(() => {
      if (!walletAddress.value) return '';
      
      // For Ethereum-style addresses (0x...)
      if (walletAddress.value.startsWith('0x')) {
        return `${walletAddress.value.substring(0, 6)}...${walletAddress.value.substring(walletAddress.value.length - 4)}`;
      }
      
      // For other address formats, just truncate the middle
      return `${walletAddress.value.substring(0, 6)}...${walletAddress.value.substring(walletAddress.value.length - 4)}`;
    });
    
    const statusText = computed(() => {
      if (props.isExplorerRoute) {
        return 'No wallet connected';
      } else {
        return shouldShowDemoView.value ? 'No wallet connected, showing demo view' : 'No wallet connected';
      }
    });
    
    const clearDemoData = () => {
      store.commit('clearDemoData');
    };

    return {
      isWalletConnected,
      isDemoDisabled,
      shouldShowDemoView,
      statusText,
      clearDemoData,
      truncatedAddress
    };
  }
});
</script>

<style scoped>

.wallet-status-container-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  height: 30px; 
}

.wallet-status-container {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  top: 0;
  margin-top: 20px;
}

.wallet-status {
  background-color: rgba(255, 165, 0, 0.2);
  color: #ffa500;
  padding: 8px 8px;
  border-radius: 3px;
  font-size: .8rem;
  font-weight: 500;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; /* Take full height of container */
}

.wallet-status.connected {
  background-color: rgba(0, 128, 0, 0.2);
  color: #00cc00;
}

.clear-demo-btn {
  background-color: rgba(255, 0, 0, 0.2);
  color: #ff5555;
  border: none;
  border-radius: 3px;
  padding: 4px 8px;  /* Increased padding for bigger button */
  margin-left: 8px;
  font-size: 0.7rem;
  cursor: pointer;
  transition: background-color 0.2s;
  height: 24px;  /* Set explicit height */
  display: flex;
  align-items: center;
  justify-content: center;
}

.clear-demo-btn:hover {
  background-color: rgba(255, 0, 0, 0.4);
}
</style>
