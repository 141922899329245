// store.js
import {createStore} from 'vuex';

export const store = createStore({
    state: {
        preparedTransaction: null, // Prepared transaction waiting to be signed
        signedTransaction: null, // Signed transaction waiting to be broadcast
        transactionSignature: null, // Ethereum signature of the transaction
        btcExchangeRate: 30000.0, // Default value
        ethExchangeRate: 2000.0, // Default value
        wasm: null, // WASM module instance
        wasmError: null, // Any WASM loading errors
        walletConnected: false, // Whether a wallet is connected
        walletSkipped: false, // Whether the user skipped wallet connection
        walletAddress: null, // Connected wallet address
        walletChainId: null, // Connected wallet chain ID
        walletType: null, // Type of connected wallet (metamask, phantom, walletconnect, etc.)
        walletInitialized: false, // Whether wallet state has been initialized from localStorage,
        
        // App configuration
        configData: {}, // User configuration data stored in browser
        configEmpty: true, // Whether the config is empty (no user input yet)
        
        // Demo state
        isDemoDisabled: false, // Whether demo data is disabled
        
        // Chart data for different time ranges
        chartData: {
            'default': [30000, 32000, 35000, 34000, 36000, 40000, 42000, 45000, 47000, 48000, 52000, 60000],
            '1h': [59000, 59200, 59400, 59100, 59300, 59500, 59700, 59600, 59800, 59900, 60000, 60100],
            // Day chart - Extreme intraday volatility with dramatic spikes
            '1d': [10, 25, 5, 40, 8, 30, 3, 55, 15, 35, 2, 48, 12, 60, 20, 4, 70, 18, 38, 7, 22, 45],
            // Week chart - Massive market crash followed by recovery
            '7d': [50, 65, 35, 75, 25, 90, 15, 45, 5, 30, 110, 20, 60, 10, 40, 130, 55, 25, 80, 15, 45, 95, 35],
            // Year chart - "Mountain" pattern with extreme peak in the middle
            '1y': [100, 130, 80, 150, 110, 190, 140, 250, 180, 350, 450, 580, 680, 520, 380, 280, 220, 300, 180, 240, 120, 170, 90, 150, 400, 1200],
            // All time chart - Multiple extreme market cycles with dramatic crashes and recoveries
            '5y': [50, 180, 30, 250, 80, 400, 20, 150, 350, 100, 500, 50, 300, 120, 650, 200, 100, 1200, 300, 300, 304, 400, 200, 1100, 500, 250, 200, 600, 300, 1500]
        },
        
        // Recent transactions
        recentTransactions: [
            {
                date: new Date(2024, 2, 28),
                type: 'Receive',
                asset: 'BTC',
                amount: 0.05,
                status: 'Completed'
            },
            {
                date: new Date(2024, 2, 27),
                type: 'Send',
                asset: 'ETH',
                amount: 1.2,
                status: 'Completed'
            },
            {
                date: new Date(2024, 2, 25),
                type: 'Trade',
                asset: 'BTC → ETH',
                amount: 0.03,
                status: 'Completed'
            },
            {
                date: new Date(2024, 2, 22),
                type: 'Receive',
                asset: 'USDC',
                amount: 500,
                status: 'Completed'
            },
            {
                date: new Date(2024, 2, 20),
                type: 'Send',
                asset: 'BTC',
                amount: 0.01,
                status: 'Pending'
            }
        ],
        
        // Asset allocation data
        assetAllocation: [
            { name: 'BTC', value: 10500, percentage: 35, color: '#8b6914' },
            { name: 'ETH', value: 7500, percentage: 25, color: '#1e88e5' },
            { name: 'SOL', value: 4500, percentage: 15, color: '#9c27b0' },
            { name: 'DOT', value: 3000, percentage: 10, color: '#e91e63' },
            { name: 'USDC', value: 3000, percentage: 10, color: '#43a047' },
            { name: 'Other', value: 1500, percentage: 5, color: '#e53935' }
        ],
        
        // Gainers data
        gainers: [
            { name: 'BTC', value: 1250.00 },
            { name: 'ETH', value: 420.50 },
            { name: 'DOT', value: 75.20 },
            { name: 'ADA', value: 62.80 },
            { name: 'MATIC', value: 45.75 }
        ],
        
        // Losers data
        losers: [
            { name: 'XRP', value: 85.30 },
            { name: 'SOL', value: 32.75 },
            { name: 'AVAX', value: 18.45 },
            { name: 'LINK', value: 24.60 },
            { name: 'DOT', value: 15.20 }
        ],
        
        // Key metrics
        keyMetrics: {
            dailyChange: '+5.2%',
            weeklyHighLow: '$62.5k / $58.2k',
            monthlyChange: '+12.8%',
            numAssets: '6',
            daysSinceMax: '12',
            portfolioAge: '186 days'
        },
        
        // Net asset value
        netAssetValue: 0, // $1.42 million
        
        // WebSocket related state
        webSocketConnected: false, // Whether the WebSocket is connected
        lastWebSocketMessageSize: 0, // Size of the last WebSocket message in bytes
        webSocketError: null, // Any WebSocket errors
        walletBalance: 0, // Connected wallet Redgold balance
        
        // Portfolio management
        activePortfolio: null,
        portfolios: [
            { id: '1', name: 'Balanced Growth Portfolio' },
            { id: '2', name: 'Conservative Portfolio' },
            { id: '3', name: 'Aggressive Growth Portfolio' }
        ],
        
        // Identity management
        activeIdentity: null,
        identities: [
            { id: '1', name: 'personal' },
            { id: '2', name: 'business' },
            { id: '3', name: 'anon' },
            { id: '4', name: 'secret identity' }
        ],
        
        // Account management
        activeAccount: null,
        accounts: [
            // Personal accounts (crypto enthusiast themed)
            { id: '1', identityId: '1', name: 'cold wallet' },
            { id: '2', identityId: '1', name: 'hot wallet' },
            { id: '3', identityId: '1', name: 'dao keys' },
            { id: '4', identityId: '1', name: 'test account' },
            
            // Business accounts (corporate humor)
            { id: '5', identityId: '2', name: 'treasury multisig' },
            { id: '6', identityId: '2', name: 'payroll hot wallet' },
            { id: '7', identityId: '2', name: 'slush fund' },
            
            // Anon accounts (privacy themed)
            { id: '8', identityId: '3', name: 'linux isos' },
            { id: '9', identityId: '3', name: 'wikileaks donations' },
            { id: '10', identityId: '3', name: 'Incognito Stash' },
            { id: '11', identityId: '3', name: 'Plausible Deniability Fund' },
            
            // Secret identity accounts
            { id: '12', identityId: '4', name: 'payoffs' },
            { id: '13', identityId: '4', name: 'anonymous vps' },
            { id: '14', identityId: '4', name: 'account2' },
            { id: '15', identityId: '4', name: 'unsorted' }
        ]
    },
    getters: {
        // Getter for btcExchangeRate
        getBtcExchangeRate: (state) => {
            return state.btcExchangeRate;
        },
        getEthExchangeRate: (state) => {
            return state.ethExchangeRate;
        },
        getWasm: (state) => {
            return state.wasm;
        },
        getWasmError: (state) => {
            return state.wasmError;
        },
        isWalletConnected: (state) => {
            return state.walletConnected;
        },
        isWalletSkipped: (state) => {
            return state.walletSkipped;
        },
        shouldShowWalletConnect: (state) => {
            return !state.walletConnected && !state.walletSkipped;
        },
        getWalletAddress: (state) => {
            return state.walletAddress;
        },
        getWalletAddressForConfig: (state) => {
            // Return the wallet address in the format expected by the config
            if (state.walletAddress) {
                return {
                    name: `Connected Wallet`,
                    address: state.walletAddress,
                    source: state.walletType || 'wallet_connect'
                };
            }
            return null;
        },
        getWalletChainId: (state) => {
            return state.walletChainId;
        },
        getWalletType: (state) => {
            return state.walletType;
        },
        isWalletInitialized: (state) => {
            return state.walletInitialized;
        },
        
        // WebSocket getters
        isWebSocketConnected: (state) => {
            return state.webSocketConnected;
        },
        getLastWebSocketMessageSize: (state) => {
            return state.lastWebSocketMessageSize;
        },
        getWebSocketError: (state) => {
            return state.webSocketError;
        },
        getWalletBalance: (state) => {
            return state.walletBalance;
        },
        
        // Portfolio getters
        getActivePortfolio: (state) => {
            return state.activePortfolio;
        },
        getPortfolios: (state) => {
            return state.portfolios;
        },
        
        // Identity getters
        getActiveIdentity: (state) => {
            return state.activeIdentity;
        },
        getIdentities: (state) => {
            return state.identities;
        },
        
        // Account getters
        getActiveAccount: (state) => {
            return state.activeAccount;
        },
        getAccounts: (state) => {
            return state.accounts;
        },
        getAccountsByIdentity: (state) => (identityId) => {
            return state.accounts.filter(account => account.identityId === identityId);
        },
        
        // Config getters
        getConfigData: (state) => {
            return state.configData;
        },
        isConfigEmpty: (state) => {
            return state.configEmpty;
        },
        
        // Demo state getters
        isDemoDisabled: (state) => {
            return state.isDemoDisabled;
        },
        shouldShowDemoView: (state) => {
            // Show demo view if not wallet connected and demo is not disabled
            return !state.walletConnected && !state.isDemoDisabled;
        }
    },
    mutations: {
        // Mutation (setter) for btcExchangeRate
        setBtcExchangeRate(state, rate) {
            state.btcExchangeRate = rate;
        },
        setEthExchangeRate(state, rate) {
            state.ethExchangeRate = rate;
        },
        setWasm(state, instance) {
            state.wasm = instance;
            state.wasmError = null;
        },
        setWasmError(state, error) {
            state.wasmError = error;
            state.wasm = null;
        },
        setWalletConnected(state, isConnected) {
            state.walletConnected = isConnected;
            if (isConnected) {
                state.walletSkipped = false;
                // Disable demo mode when wallet is connected
                state.isDemoDisabled = true;
                
                // Clear chart data when wallet is connected
                state.chartData = {
                    'default': [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
                    '1h': [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
                    '1d': [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
                    '7d': [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
                    '1y': [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
                    '5y': [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0]
                };
            }
        },
        setWalletSkipped(state, isSkipped) {
            state.walletSkipped = isSkipped;
        },
        setWalletAddress(state, address) {
            state.walletAddress = address;
        },
        setWalletChainId(state, chainId) {
            state.walletChainId = chainId;
        },
        setWalletType(state, type) {
            state.walletType = type;
        },
        setWalletInitialized(state, isInitialized) {
            state.walletInitialized = isInitialized;
        },
        
        // Transaction mutations
        setPreparedTransaction(state, tx) {
            state.preparedTransaction = tx;
        },
        setSignedTransaction(state, tx) {
            state.signedTransaction = tx;
        },
        setTransactionSignature(state, signature) {
            state.transactionSignature = signature;
        },
        
        // WebSocket mutations
        setWebSocketConnected(state, isConnected) {
            state.webSocketConnected = isConnected;
        },
        setLastWebSocketMessageSize(state, size) {
            state.lastWebSocketMessageSize = size;
        },
        setWebSocketError(state, error) {
            state.webSocketError = error;
        },
        setWalletBalance(state, balance) {
            state.walletBalance = balance;
            
            // Also update netAssetValue to match the wallet balance when wallet is connected
            if (state.walletInitialized && state.walletConnected && state.walletAddress) {
                console.log('Setting netAssetValue to match wallet balance:', balance);
                state.netAssetValue = balance * 100;
                
                // When wallet is connected, we're not in demo mode
                // Set isDemoDisabled to true to disable demo features
                state.isDemoDisabled = true;
                
                // Clear chart data when wallet is connected (we don't have historical data)
                // This will make the charts not render due to our hasChartData check
                state.chartData = {
                    'default': [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
                    '1h': [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
                    '1d': [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
                    '7d': [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
                    '1y': [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
                    '5y': [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0]
                };
            }
        },
        
        // Portfolio mutations
        setActivePortfolio(state, portfolio) {
            state.activePortfolio = portfolio;
        },
        addPortfolio(state, portfolio) {
            state.portfolios.push(portfolio);
        },
        
        // Identity mutations
        setActiveIdentity(state, identity) {
            state.activeIdentity = identity;
            // When changing identity, reset active account
            state.activeAccount = null;
        },
        addIdentity(state, identity) {
            state.identities.push(identity);
        },
        
        // Account mutations
        setActiveAccount(state, account) {
            state.activeAccount = account;
        },
        addAccount(state, account) {
            state.accounts.push(account);
        },
        
        // Config mutations
        setConfigData(state, config) {
            // Create a deep copy to break reactivity chain
            const configCopy = JSON.parse(JSON.stringify(config || {}));
            
            // Update state with the copy
            state.configData = configCopy;
            state.configEmpty = !configCopy || Object.keys(configCopy).length === 0;
            
            // Store in localStorage using the copy
            localStorage.setItem('configData', JSON.stringify(configCopy));
        },
        
        // Demo state mutations
        setIsDemoDisabled(state, isDisabled) {
            state.isDemoDisabled = isDisabled;
        },
        clearDemoData(state) {
            state.isDemoDisabled = true; // Set to true to hide the demo view and the clear button
            
            // Reset chart data to 0.0 values (clears all mini charts)
            state.chartData = {
                default: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
                '1h': [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
                '1d': [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
                '7d': [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
                '1y': [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
                '5y': [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0]
            };
            
            // Clear recent transactions
            state.recentTransactions = [];
            
            // Clear asset allocation data
            state.assetAllocation = [];
            
            // Clear gainers/losers data
            state.gainers = [];
            state.losers = [];
            
            // Reset key metrics to 0
            state.keyMetrics = {
                dailyChange: '0.0%',
                weeklyHighLow: '$0.0 / $0.0',
                monthlyChange: '0.0%',
                numAssets: '0',
                daysSinceMax: '0',
                portfolioAge: '0 days'
            };
            
            // Set net asset value to 0
            state.netAssetValue = 0.0;
            
            // Clear identity and account data
            state.activeIdentity = null;
            state.identities = [];
            state.activeAccount = null;
            state.accounts = [];
            
            // Clear portfolio data
            state.activePortfolio = null;
            state.portfolios = [];
        }
    },
    actions: {
        // Initialize app configuration from localStorage
        initConfigData({ commit }) {
            try {
                const storedConfig = localStorage.getItem('configData');
                if (storedConfig) {
                    const config = JSON.parse(storedConfig);
                    
                    // Check if the config is an error object (has a "message" field)
                    if (config && typeof config === 'object' && config.message !== undefined) {
                        console.error('Config data in localStorage is an error object:', config);
                        // If it's an error object, initialize with an empty object
                        commit('setConfigData', {});
                    } else {
                        commit('setConfigData', config);
                    }
                } else {
                    // If no config is found, initialize with an empty object
                    commit('setConfigData', {});
                }
            } catch (error) {
                console.error('Failed to initialize config data from localStorage:', error);
                // If there's an error, initialize with an empty object
                commit('setConfigData', {});
            }
        },
        
        // Refresh metrics data from config
        refreshMetrics({ commit, state }) {
            try {
                // Get current config data
                const config = state.configData;
                
                // If metrics data exists in config, update keyMetrics
                if (config && config.metrics) {
                    const metrics = {
                        ...state.keyMetrics, // Keep existing metrics as fallback
                        ...config.metrics // Override with metrics from config
                    };
                    
                    // Update state with new metrics
                    state.keyMetrics = metrics;
                    
                    console.log('Metrics refreshed from config:', metrics);
                    return true;
                }
                return false;
            } catch (error) {
                console.error('Failed to refresh metrics:', error);
                return false;
            }
        }
        
        // // Optional: Async action that could fetch and then commit the new rate
        // async fetchAndSetBtcExchangeRate({ commit }) {
        //     // Example using a fictional API endpoint
        //     // const response = await axios.get('https://api.example.com/btcRate');
        //     // commit('setBtcExchangeRate', response.data.rate);
        // }
    }
});

export default store;
