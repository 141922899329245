<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-2">
      <div class="gainers-losers-label mb-0">Net Asset Value</div>
      <div class="time-range-buttons">
        <button 
          v-for="range in timeRanges" 
          :key="range.value" 
          class="btn btn-sm time-range-btn" 
          :class="{ active: selectedTimeRange === range.value }"
          @click="setTimeRange(range.value)">
          {{ range.label }}
        </button>
      </div>
    </div>
    <div class="chart-container" style="height: 220px;">
      <Line
        :data="chartData"
        :options="chartOptions"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { Line } from 'vue-chartjs';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

export default defineComponent({
  name: 'PortfolioChart',
  components: {
    Line
  },
  props: {
    timeRanges: {
      type: Array,
      required: true
    },
    selectedTimeRange: {
      type: String,
      required: true
    },
    timeRangeData: {
      type: Object,
      required: true
    },
    timeRangeLabels: {
      type: Object,
      required: true
    },
    currency: {
      type: String,
      default: 'USD'
    },
    walletBalance: {
      type: Number,
      default: 0
    }
  },
  emits: ['update:selectedTimeRange'],
  setup(props, { emit }) {
    // Chart options
    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          titleColor: '#fff',
          bodyColor: '#fff',
          borderColor: '#8b6914',
          borderWidth: 1,
          padding: 10,
          displayColors: false,
          callbacks: {
            label: function(context) {
              return formatCurrency(context.raw, props.currency);
            }
          }
        }
      },
      scales: {
        x: {
          grid: {
            color: 'rgba(255, 255, 255, 0.03)'
          },
          ticks: {
            color: '#ccc'
          }
        },
        y: {
          grid: {
            color: 'rgba(255, 255, 255, 0.03)'
          },
          ticks: {
            color: '#ccc',
            maxTicksLimit: 5,
            callback: function(value) {
              return formatCurrency(value, props.currency);
            }
          }
        }
      }
    };
    
    // Set time range
    const setTimeRange = (range) => {
      emit('update:selectedTimeRange', range);
      console.log(`Setting time range to ${range}`);
    };
    
    // Update chart data based on selected time range
    const chartData = computed(() => ({
      labels: props.timeRangeLabels[props.selectedTimeRange],
      datasets: [
        {
          label: 'Portfolio Value',
          data: props.timeRangeData[props.selectedTimeRange],
          borderColor: '#8b6914',
          backgroundColor: 'rgba(139, 105, 20, 0.1)',
          tension: 0.4,
          borderWidth: 2,
          pointRadius: 3,
          pointBackgroundColor: '#8b6914',
          fill: true
        }
      ]
    }));

    // Helper function to format currency
    const formatCurrency = (value, currency) => {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency || 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(value);
    };
    
    return {
      chartOptions,
      chartData,
      setTimeRange
    };
  }
});
</script>

<style scoped>
.chart-container {
  width: 100%;
  height: 100%;
  min-height: 220px;
  padding-bottom: 25px;
}

.gainers-losers-label {
  font-size: 0.85rem;
  color: #999;
  margin-bottom: 8px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  text-align: center;
  padding-bottom: 10px;
}

/* Time range buttons */
.time-range-buttons {
  display: flex;
  gap: 5px;
}

.time-range-btn {
  background-color: #121214;
  color: #999;
  border: none;
}

.time-range-btn:hover {
  background-color: #444444;
}

.time-range-btn.active {
  background-color: #8b6914;
  color: white;
}
</style>
