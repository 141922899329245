<template>
  <div class="wallet-connect-screen">
    <div class="container">
      <div class="connect-box">
        <h2 class="connect-title">Connect wallet to view Portfolio</h2>
        <div v-if="error" class="error-message">{{ error }}</div>
        <div class="wallet-options">
          <div 
            v-for="wallet in availableWallets" 
            :key="wallet.id"
            class="wallet-option"
            @click="selectWallet(wallet.id)"
            :class="{ 'disabled': !wallet.isAvailable }"
          >
            <div class="wallet-icon">
              <img :src="require(`@/assets/${wallet.icon}`)" :alt="wallet.name" v-if="wallet.icon" class="wallet-icon-img">
              <div class="wallet-icon-placeholder" v-else>{{ wallet.name.charAt(0) }}</div>
            </div>
            <div class="wallet-info">
              <div class="wallet-name">{{ wallet.name }}</div>
              <div class="wallet-status" v-if="!wallet.isAvailable">Not detected</div>
            </div>
          </div>
        </div>
        <div class="button-group">
          <button class="btn btn-outline-secondary skip-btn" @click="skipConnection">Skip for now</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { connectWallet, disconnectWallet } from '../utils/wallet-connect';

export default defineComponent({
  name: 'WalletConnectScreen',
  setup() {
    const router = useRouter();
    const store = useStore();
    const isConnecting = ref(false);
    const error = ref('');
    
    // Check which wallets are available in the browser
    const metamaskAvailable = ref(false);
    const phantomAvailable = ref(false);
    
    onMounted(() => {
      // Check for MetaMask
      metamaskAvailable.value = typeof window.ethereum !== 'undefined';
      
      // Check for Phantom
      phantomAvailable.value = typeof window.solana !== 'undefined' && window.solana.isPhantom;
    });
    
    const availableWallets = computed(() => [
      {
        id: 'metamask',
        name: 'MetaMask',
        icon: 'metamask-icon.svg',
        isAvailable: metamaskAvailable.value
      },
      {
        id: 'phantom',
        name: 'Phantom',
        icon: 'phantom-icon.svg',
        isAvailable: phantomAvailable.value
      },
      {
        id: 'walletconnect',
        name: 'WalletConnect',
        icon: 'walletconnect-icon.svg',
        isAvailable: true // WalletConnect is always available as it's not browser-dependent
      }
    ]);

    const selectWallet = async (walletId) => {
      // Find the selected wallet
      const wallet = availableWallets.value.find(w => w.id === walletId);
      
      // If wallet is not available, show error
      if (wallet && !wallet.isAvailable) {
        error.value = `${wallet.name} is not installed or available in your browser.`;
        return;
      }
      
      // Clear any previous errors
      error.value = '';
      isConnecting.value = true;
      
      // Clear any localStorage items related to wallet connection
      localStorage.removeItem('walletConnected');
      localStorage.removeItem('walletAddress');
      localStorage.removeItem('walletChainId');
      localStorage.removeItem('walletType');
      
      console.log('Cleared previous wallet connection data');
      
      try {
        // Connect to the selected wallet
        let result;
        
        // Use the appropriate connection method based on wallet type
        if (walletId === 'metamask') {
          // Directly connect to MetaMask without showing the modal
          const { connectMetamask } = await import('../utils/wallet-connect');
          result = await connectMetamask();
        } else if (walletId === 'phantom') {
          // Connect to Phantom
          const response = await window.solana.connect();
          const publicKey = response.publicKey.toString();
          
          if (publicKey) {
            // Update the store
            store.commit('setWalletConnected', true);
            store.commit('setWalletAddress', publicKey);
            store.commit('setWalletChainId', 'solana'); // Using string for Solana
            store.commit('setWalletType', 'phantom');
            
            // Set up event listeners
            window.solana.on('disconnect', () => {
              disconnectWallet();
            });
            
            window.solana.on('accountChanged', async () => {
              // Mirror the active account change instead of disconnecting
              try {
                const response = await window.solana.connect();
                const publicKey = response.publicKey.toString();
                if (publicKey) {
                  store.commit('setWalletAddress', publicKey);
                  console.log('Updated Phantom wallet address:', publicKey);
                }
              } catch (error) {
                console.error('Error updating Phantom account:', error);
              }
            });
            
            // Store the provider for later use
            window.walletProvider = window.solana;
            
            result = {
              success: true,
              address: publicKey,
              chainId: 'solana',
              walletType: 'phantom'
            };
          } else {
            result = { success: false, error: 'No public key found' };
          }
        } else {
          // For WalletConnect or fallback
          result = await connectWallet();
        }
        
        if (result.success) {
          // Connection successful, redirect to app
          router.push('/app');
        } else if (result.error) {
          // Show error message
          error.value = `Failed to connect wallet: ${result.error}`;
        } else if (!result.cancelled) {
          // Some other error
          error.value = 'Failed to connect wallet. Please try again.';
        }
      } catch (err) {
        console.error('Wallet connection error:', err);
        error.value = 'An unexpected error occurred while connecting to wallet.';
      } finally {
        isConnecting.value = false;
      }
    };

    const skipConnection = () => {
      console.log('Skip connection button clicked');
      
      // Skip wallet connection but mark as not connected
      store.commit('setWalletSkipped', true);
      
      // Save wallet skipped state to localStorage
      localStorage.setItem('walletSkipped', 'true');
      
      // Verify the localStorage value was set
      console.log('walletSkipped localStorage value:', localStorage.getItem('walletSkipped'));
      
      router.push('/app');
    };
    
    // Watch for wallet connection state changes
    const isWalletConnected = computed(() => store.getters.isWalletConnected);
    
    // If wallet is already connected, redirect to app
    onMounted(async () => {
      // Wait for wallet initialization to complete
      await new Promise(resolve => {
        const checkInitialized = () => {
          if (store.getters.isWalletInitialized) {
            resolve();
          } else {
            setTimeout(checkInitialized, 50);
          }
        };
        checkInitialized();
      });
      
      if (isWalletConnected.value) {
        router.push('/app');
      }
    });

    return {
      availableWallets,
      selectWallet,
      skipConnection,
      isConnecting,
      error
    };
  }
});
</script>

<style scoped>
.wallet-connect-screen {
  position: fixed; /* Use fixed positioning to cover the viewport below the header */
  top: 60px; /* Start below the header which is 60px tall */
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw; /* Use viewport width */
  height: calc(100vh - 60px); /* Subtract header height from viewport height */
  display: flex;
  align-items: center; /* Restored to center for vertical alignment */
  justify-content: center;
  background-color: #000;
  color: #fff;
  overflow: hidden; /* Prevent scrollbar */
  z-index: 0; /* Ensure it's behind other elements if needed */
}

.connect-box {
  background-color: rgba(30, 30, 30, 0.5);
  border: 1px solid #333;
  border-radius: 8px;
  padding: 3rem;
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.connect-title {
  font-size: 1.8rem;
  margin-bottom: 2rem;
  font-weight: 600;
}

.wallet-options {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
}

.wallet-option {
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 8px;
  background-color: #2a2a2a;
  cursor: pointer;
  transition: background-color 0.2s;
  text-align: left;
}

.wallet-option:hover {
  background-color: #333;
}

.wallet-option.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.wallet-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  overflow: hidden;
}

.wallet-icon-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.wallet-icon-placeholder {
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
}

.wallet-info {
  flex: 1;
}

.wallet-name {
  font-weight: 500;
  font-size: 1.1rem;
}

.wallet-status {
  font-size: 0.8rem;
  color: #aaa;
  margin-top: 0.25rem;
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.skip-btn {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  color: #aaa;
  border-color: #555;
}

.skip-btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.error-message {
  color: #ff6b6b;
  margin-bottom: 1.5rem;
  padding: 0.5rem;
  background-color: rgba(255, 107, 107, 0.1);
  border-radius: 4px;
  font-size: 0.9rem;
}
</style>
