<template>
  <div>
    <!-- Combined search and top party container -->
    <div class="explorer-elements-container">
      <!-- Search bar on the left -->
      <div class="search-bar-container">
        <div class="search-bar">
          <form class="d-flex align-items-center" :action="'/hash/' + searchValue" method="get">
            <input v-model="searchValue" id="inline-form-input-name" class="form-control mr-2 search-input" type="text" placeholder="Query hash...">
            <button type="submit" class="btn search-button">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-search button-icon" viewBox="0 0 16 16">
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
              </svg>
              <span class="button-text">Search</span>
            </button>
          </form>
        </div>
      </div>
      
      <!-- Top Party after search bar -->
      <div class="top-party-container">
        <div class="top-party-label">Top Party:</div>
        <div v-if="!topPartyKey" class="loading-text">loading...</div>
        <div class="hash-link-wrapper">
          <HashLink v-if="topPartyKey !== ''" :data="topPartyKey" :shorten=true />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import fetchHashInfo from "@/components/mixins/fetchHashInfo";
import HashLink from "@/components/util/HashLink.vue";

export default defineComponent({
  name: 'ExplorerElements',
  components: {
    HashLink,
  },
  data() {
    return {
      searchValue: '',
      topPartyKey: '',
      rgdBtc: 600.012312,
      rgdBtcStr: '600.012312',
      usdRdg: 100.012312,
      usdRdgStr: '100.012',
      usdEth: 3000.3210,
      usdBtcStr: '60000.32',
      usdEthStr: '3000.32'
    };
  },
  mixins: [fetchHashInfo],
  created() {
    // Start fetching prices and swap info in parallel
    this.fetchPrices();
    this.fetchSwapInformation();
  },
  methods: {
    handleSubmit() {
      this.$router.push(`/hash/${this.searchValue}`);
    },
    async fetchPrices() {
      this.usdBtc = await this.btcUsdPrice();
      this.usdEth = await this.ethUsdPrice();
      // Commit the value to the store
      this.$store.commit('setBtcExchangeRate', this.usdBtc);
      this.$store.commit('setEthExchangeRate', this.usdEth);

      this.usdBtcStr = this.usdBtc.toFixed(2);
      this.usdEthStr = this.usdEth.toFixed(2);
    },
    fetchSwapInformation() {
      // Use promise chaining instead of await
      // console.log("Starting fetchSwapInformation");
      this.fetchSwapInfo()
        .then(swapInfo => {
          // console.log("Processing swap info in component");
          if (swapInfo != null) {
            this.topPartyKey = swapInfo['public_key'];
            if ('Bitcoin' in swapInfo.central_prices) {
              this.usdRdg = swapInfo.central_prices['Bitcoin'].min_ask_estimated;
              this.rgdBtc = swapInfo.central_prices['Bitcoin'].min_ask;
              this.rgdBtcStr = this.rgdBtc.toFixed(2);
              this.usdRdgStr = this.usdRdg.toFixed(2);
            }
          }
        })
        .catch(error => {
          console.error("Error in fetchSwapInformation:", error);
        });
    }
  }
});
</script>

<style scoped>
/* Explorer elements container */
.explorer-elements-container {
  display: flex;
  align-items: center;
  background-color: #000;
  width: 100%;
  padding: 0.3rem 1.5rem;
  box-sizing: border-box;
}

/* Search bar styling */
.search-bar-container {
  background-color: #000;
  flex: 0 1 auto;
  margin: 0;
  padding-left: 5rem;
  box-sizing: border-box;
}

.search-bar {
  background-color: #000;
  padding: 0.2rem;
  margin: 0;
  border-radius: 0.3rem;
  box-sizing: border-box;
  border: none;
  text-align: left;
}

.search-input,
.search-input:focus {
  box-sizing: border-box;
  width: 400px;
  background-color: #191a19;
  color: #fff;
  font-size: 1rem;
  padding: 0.5rem 0.8rem;
  margin: 0;
  border: 1px solid #333;
}

.search-input::placeholder {
  color: #ccc;
}

/* Top party container styling */
.top-party-container {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #000;
  padding: 0.3rem 0;
  margin-left: 3rem;
  height: 40px; /* Set a fixed height to ensure consistent alignment */
}

.top-party-label, .loading-text {
  display: flex;
  align-items: center;
  height: 100%;
  margin-bottom: 0;
}

.hash-link-wrapper {
  padding-top: 30px;
  /* display: inline-flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* height: 100%; */
  /* margin-bottom: 0; */
  /* vertical-align: middle; */
  /* position: relative; Added for positioning */
  /* top: -4px; Adjusted to move the element up more significantly */
  /* transform: translateY(2px); Additional transform to help with alignment */
}

.search-button {
  position: relative;
  color: #FFD700;
  background-color: #500000;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  width: auto;
}

.search-button:hover {
  background-color: #700000;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.search-button .button-text {
  font-size: 1.1rem;
  margin-left: 5px;
}

.search-button .button-icon {
  display: inline-block;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.mr-2 {
  margin-right: 0.5rem;
}
</style>
