<template>
  <div class="transaction-review">
    <div class="alert alert-info mb-4">
      Please review your transaction details before signing
    </div>
    
    <div class="transaction-details">
      <div class="detail-row">
        <div class="detail-label">Recipient:</div>
        <div class="detail-value">{{ truncateAddress(recipientAddress) }}</div>
      </div>
      <div class="detail-row">
        <div class="detail-label">Amount:</div>
        <div class="detail-value">{{ formatRdgBalance(parseFloat(amount)) }}</div>
      </div>
      <div class="detail-row">
        <div class="detail-label">Network Fee:</div>
        <div class="detail-value">{{ formatRdgBalance(fee) }}</div>
      </div>
      <div class="detail-row">
        <div class="detail-label">Total Amount:</div>
        <div class="detail-value">{{ formatRdgBalance(parseFloat(amount) + fee) }}</div>
      </div>
      <div class="detail-row">
        <div class="detail-label">Remaining Balance:</div>
        <div class="detail-value">{{ formatRdgBalance(currentBalance - parseFloat(amount) - fee) }}</div>
      </div>
    </div>
    
    <!-- Transaction Hash Preview -->
    <div class="transaction-hash mt-3 mb-3">
      <div class="form-label">Message to Sign:</div>
      <div class="hash-preview p-2 bg-dark text-light rounded">
        {{ messageToSign || 'Message not yet generated' }}
      </div>
    </div>

    <div class="transaction-hash mt-3 mb-3" v-if="signature">
      <div class="form-label">Signature (Hex):</div>
      <div class="hash-preview p-2 bg-dark text-light rounded">
        {{ signature }}
      </div>
    </div>
    
    <!-- Error Message -->
    <div class="alert alert-danger" v-if="error">
      {{ error }}
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'TransactionReview',
  props: {
    recipientAddress: {
      type: String,
      required: true
    },
    amount: {
      type: [String, Number],
      required: true
    },
    availableBalance: {
      type: Number,
      required: true
    },
    messageToSign: {
      type: String,
      default: ''
    },
    signature: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    truncateAddress: {
      type: Function,
      required: true
    },
    fee: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const currentBalance = ref(0);
    
    // Get wallet address from store
    const walletAddress = computed(() => store.getters.getWalletAddress);
    
    // Update the balance using the WASM function
    const updateBalance = () => {
      if (walletAddress.value && window.redgoldWasm && typeof window.redgoldWasm.get_data_state_balance_for_address === 'function') {
        try {
          // Use the WASM function to get the balance for the wallet address
          const balanceRdg = window.redgoldWasm.get_data_state_balance_for_address(walletAddress.value);
          console.log('TransactionReview Raw RDG balance:', balanceRdg);
          
          if (balanceRdg !== undefined && balanceRdg !== null) {
            // Convert RDG balance to the numeric value
            const numericBalance = parseFloat(balanceRdg);
            if (!isNaN(numericBalance)) {
              currentBalance.value = numericBalance;
            }
          }
        } catch (error) {
          console.error('Error getting address balance from WASM:', error);
          currentBalance.value = props.availableBalance / 100;
        }
      } else {
        currentBalance.value = props.availableBalance / 100;
      }
    };
    
    const formatRdgBalance = (balance) => {
      const symbol = 'Ⓡ';
      return `${symbol} ${parseFloat(balance).toFixed(8)}`;
    };
    
    // Update the balance when the component is mounted
    onMounted(() => {
      updateBalance();
    });
    
    // Watch for changes to the wallet address or the availableBalance prop
    watch([walletAddress, () => props.availableBalance], () => {
      updateBalance();
    });
    
    return {
      formatRdgBalance,
      currentBalance
    };
  }
});
</script>

<style scoped>

.alert {
  background-color: #2c0606;
  color: #92bdcd;
  border-color: #453d07;
}

.transaction-details {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  padding: 1rem;
}

.detail-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.detail-row:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.detail-label {
  color: #aaa;
}

.hash-preview {
  font-family: monospace;
  word-break: break-all;
}
</style>
