<template>
  <div class="wallet-balance-section d-flex flex-column me-4">
    <div class="wallet-balance-label">Wallet Balance</div>
    <div class="wallet-balance-values d-flex">
      <div class="wallet-balance-usd me-1">${{ formatBalance(rdgToUsd(currentBalance)) }}</div>
      <div>USD</div>
      <div class="wallet-balance-rdg">{{ formatRdgBalance(currentBalance) }}</div>
      <div>RDG</div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, ref, watch, onUnmounted } from 'vue';
import { formatCurrencyAbbreviated } from '../../../../utils/app-utils.js';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'WalletBalanceDisplay',
  props: {
    balance: {
      type: Number,
      required: true
    },
    rdgToUsd: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    // Convert the wallet balance (in USD) back to RDG for display
    const currentBalance = computed(() => {
      // If balance is in USD (store value), convert back to RDG
      return props.balance;
    });
    
    // Get the wallet address from the store
    const walletAddress = computed(() => store.getters.getWalletAddress);
    
    // // Watch for changes to the wallet address or the balance prop
    // watch([walletAddress, () => props.balance], () => {
    //   updateBalance();
    // });

    let balanceUpdateInterval = null;
    
    // Update the balance using the WASM function
    // const updateBalance = () => {
    //   if (walletAddress.value && window.redgoldWasm && typeof window.redgoldWasm.get_data_state_balance_for_address === 'function') {
    //     try {
    //       // Use the WASM function to get the balance for the wallet address
    //       const balanceRdg = window.redgoldWasm.get_data_state_balance_for_address(walletAddress.value);
    //       console.log('Modal Raw RDG balance:', balanceRdg);
          
    //       if (balanceRdg !== undefined && balanceRdg !== null) {
    //         // Convert RDG balance to the numeric value
    //         const numericBalance = parseFloat(balanceRdg);
    //         if (!isNaN(numericBalance)) {
    //           // Convert to USD for consistent store value (same as DashboardHeader)
    //           const balanceUsd = numericBalance * 100;
    //           // Update the store with the new balance in USD
    //           store.commit('setWalletBalance', balanceUsd);
    //         }
    //       }
    //     } catch (error) {
    //       console.error('Error getting address balance from WASM:', error);
    //       currentBalance.value = props.balance;
    //     }
    //   } else {
    //     currentBalance.value = props.balance;
    //   }
    // };
    
    // Format the balance for display
    const formatBalance = (balance) => {
      return parseFloat(balance).toFixed(2);
    };

    const formatRdgBalance = (balance) => {
      const symbol = 'Ⓡ';
      return `${symbol} ${parseFloat(balance).toFixed(8)}`;
    };
    
    // // Update the balance when the component is mounted
    // onMounted(() => {
    //   updateBalance();
      
    //   // Set up interval to update the balance every 30 seconds
    //   balanceUpdateInterval = setInterval(updateBalance, 30000);
    // });
    
    // Clean up the interval when the component is unmounted
    // onUnmounted(() => {
    //   if (balanceUpdateInterval) {
    //     clearInterval(balanceUpdateInterval);
    //   }
    // });

    return {
      formatBalance,
      formatCurrencyAbbreviated,
      formatRdgBalance,
      currentBalance
    };
  }
});
</script>

<style scoped>
.wallet-balance-section {
  text-align: left;
  padding-top: 45px;
  /* padding-bottom: -20px; */
}

.wallet-balance-label {
  font-size: 0.85rem;
  color: #999;
  margin-bottom: 2px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.wallet-balance-values {
  display: flex;
  align-items: center;
}

.wallet-balance-usd {
  font-size: 1rem;
  font-weight: 600;
  color: #00cc00;
}

.wallet-balance-rdg {
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  padding-left: 10px;
  padding-right: 10px;
}
</style>
