<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <div>
    <h4>Email Settings</h4>
    <div class="form-group mb-3">
      <label for="emailFrom">From Email</label>
      <input type="email" class="form-control bg-dark text-white" id="emailFrom" v-model="localEmail.from" placeholder="Enter sender email address">
    </div>
    <div class="form-group mb-3">
      <label for="emailTo">To Email</label>
      <input type="email" class="form-control bg-dark text-white" id="emailTo" v-model="localEmail.to" placeholder="Enter recipient email address">
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
  name: 'EmailTab',
  props: {
    email: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    // Create local copy of the email prop
    const localEmail = ref({
      from: props.email.from,
      to: props.email.to
    });

    // Watch for changes in the prop and update local data
    watch(() => props.email, (newValue) => {
      localEmail.value.from = newValue.from;
      localEmail.value.to = newValue.to;
    }, { deep: true });

    // Watch for changes in local data and emit update events
    watch(localEmail, (newValue) => {
      emit('update:email', {
        from: newValue.from,
        to: newValue.to
      });
    }, { deep: true });

    return {
      localEmail
    };
  }
});
</script>

<style scoped>
.form-control {
  border: 1px solid #343a40;
}

.form-control:focus {
  border-color: #ffc107;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.25);
}
</style>
