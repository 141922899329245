<template>
  <div class="modal" :class="{ 'd-block': show }" tabindex="-1" v-if="show">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content bg-dark text-white">
        <div class="modal-header">
          <h5 class="modal-title">Add Contact</h5>
          <button type="button" class="btn-close btn-close-white" @click="$emit('close')"></button>
        </div>
        <div class="modal-body">
          <div class="form-group mb-3">
            <label for="contactName">Name *</label>
            <input type="text" class="form-control bg-dark text-white" id="contactName" v-model="contact.name" required>
          </div>
          <div class="form-group mb-3">
            <label for="contactPeerId">Peer ID (Optional)</label>
            <input type="text" class="form-control bg-dark text-white" id="contactPeerId" v-model="contact.peer_id">
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="$emit('close')">Cancel</button>
          <button type="button" class="btn btn-primary" @click="addContact" :disabled="!contact.name">Add</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive } from 'vue';

export default defineComponent({
  name: 'AddContactModal',
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  emits: ['close', 'add'],
  setup(props, { emit }) {
    const contact = reactive({
      name: '',
      peer_id: ''
    });

    const addContact = () => {
      if (contact.name) {
        emit('add', { ...contact });
        
        // Reset form
        contact.name = '';
        contact.peer_id = '';
      }
    };

    return {
      contact,
      addContact
    };
  }
});
</script>

<style scoped>
.modal {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  border: 1px solid #343a40;
}

.form-control {
  border: 1px solid #343a40;
}

.form-control:focus {
  border-color: #ffc107;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.25);
}
</style>
