<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h4>Addresses</h4>
      <button class="btn btn-sm btn-success" @click="$emit('show-add-address-modal')">
        Add Address
      </button>
    </div>
    
    <div v-if="!savedAddresses.length" class="alert alert-secondary">
      No saved addresses. Add an address to get started.
    </div>
    
    <div v-else class="address-list">
      <div v-for="(address, index) in savedAddresses" :key="index" class="address-item p-3 mb-2 border border-secondary rounded">
        <div class="d-flex justify-content-between">
          <div>
            <h5>{{ address.name || 'Unnamed Address' }}</h5>
            <div class="address-value text-muted">{{ address.address }}</div>
            <div v-if="address.contact" class="text-info">Contact: {{ address.contact }}</div>
          </div>
          <div>
            <button class="btn btn-sm btn-danger" @click="$emit('remove-address', index)">
              Remove
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AddressesTab',
  props: {
    savedAddresses: {
      type: Array,
      required: true
    }
  },
  emits: ['show-add-address-modal', 'remove-address']
});
</script>

<style scoped>
.address-item {
  background-color: #1e1e1e;
  transition: background-color 0.2s;
}

.address-item:hover {
  background-color: #2c2c2c;
}

.address-value {
  word-break: break-all;
}
</style>
