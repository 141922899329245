<template>
  <div>
    <div class="d-flex gap-2 mb-3 transaction-buttons">
      <button class="btn flex-grow-1 py-1 px-3 send-button" @click="$emit('send')">
        <span class="button-text">Send</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-upload button-icon" viewBox="0 0 16 16">
          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
          <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"/>
        </svg>
      </button>
      <button class="btn flex-grow-1 py-1 px-3 receive-button" @click="$emit('receive')">
        <span class="button-text">Receive</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-download button-icon" viewBox="0 0 16 16">
          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
          <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
        </svg>
      </button>
    </div>
    
    <button class="btn btn-outline-light w-100 py-2 mt-auto trade-button" @click="$emit('trade')">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left-right me-2" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z"/>
      </svg>
      Trade
    </button>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TransactionButtons',
  emits: ['send', 'receive', 'trade']
});
</script>

<style scoped>
.transaction-buttons {
  margin-top: -10px;
}

.send-button, .receive-button {
  position: relative;
  width: 50%;
  transition: all 0.2s ease;
  margin-bottom: -5px;
}

.send-button {
  color: #FFD700;
  background-color: #500000;
}

.receive-button {
  color: #FFFFFF;
  background-color: #006400;
}

.send-button:hover {
  background-color: #700000;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.receive-button:hover {
  background-color: #008000;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.button-text {
  font-size: 1.1rem;
  position: relative;
  z-index: 1;
}

.button-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.send-button .button-icon {
  left: calc(50% - 45px);
}

.receive-button .button-icon {
  left: calc(50% - 55px);
}

.trade-button {
  margin-bottom: 5px;
}
</style>
