<template>
  <div class="dropdowns-container header-font-size">
    <div class="dropdown website-dropdown">
      <a href="https://redgold.io" class="btn btn-outline-light dropdown-toggle website-btn" 
                type="button" 
                id="websiteDropdown" 
                aria-expanded="false">
        Website
      </a>
      <ul class="dropdown-menu" aria-labelledby="websiteDropdown">
        <li><a class="dropdown-item" :href="getDocsUrl()" target="_blank">Docs</a></li>
        <li><a class="dropdown-item" href="https://discord.gg/86fzxJg8ce" target="_blank">Discord</a></li>
        <li><a class="dropdown-item" href="https://grafana.redgold.io/d/pj3zzDu4z/redgold?orgId=1&from=now-30m&to=now" target="_blank">Grafana</a></li>
        <li><a class="dropdown-item" href="https://blog.redgold.io" target="_blank">Blog</a></li>
        <li><a class="dropdown-item" href="/parties">Parties</a></li>
        <li v-if="!isMainnet"><a class="dropdown-item" href="/faucet">Faucet</a></li>
        <li><router-link class="dropdown-item" :to="isExplorerRoute ? '/app' : '/'">{{ isExplorerRoute ? 'App' : 'Explorer' }}</router-link></li>
      </ul>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'WebsiteDropdown',
  props: {
    isExplorerRoute: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    const isMainnet = computed(() => {
      const hostname = window.location.hostname;
      return hostname.includes("explorer.redgold.io");
    });

    const getDocsUrl = () => {
      let docsUrl = "https://docs.redgold.io";
      const hostname = window.location.hostname;

      if (hostname.includes('dev') || hostname.includes('localhost')) {
        docsUrl = "https://dev.docs.redgold.io";
      } else if (hostname.includes('staging')) {
        docsUrl = "https://staging.docs.redgold.io";
      } else if (hostname.includes('test')) {
        docsUrl = "https://test.docs.redgold.io";
      }

      return docsUrl;
    };

    return {
      isMainnet,
      getDocsUrl
    };
  }
});
</script>

<style scoped>


.dropdowns-container {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  top: 0;
  margin-top: 10px;
}



.website-dropdown {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
  padding: 0;
  height: 40px;
  position: relative;
  /* top: 0; */
  /* margin-top: 200px; */
  padding-top: 10px;
}

.website-btn {
  white-space: nowrap;
  padding: 0.5rem 0.7rem;
  height: 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.dropdown-menu {
  background-color: #1e1e1e;
  border: 1px solid #333;
  min-width: 6rem;
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  padding: 0;
  margin: 0;
  z-index: 20000;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-item {
  color: #fff;
  padding: 0.4rem 0.8rem;
  cursor: pointer;
  line-height: 1.4;
  display: flex;
  align-items: center;
}

.dropdown-item:hover, .dropdown-item:focus {
  background-color: #333;
  color: #fff;
}

.btn-outline-light {
  border-color: #444;
  color: #fff;
}

.btn-outline-light:hover {
  background-color: #333;
  color: #fff;
  border-color: #555;
}
</style>
