import { ethers } from 'ethers';

export function recoverPublicKeyFromSignature(message, signature) {
  try {
    // Remove "0x" prefix if present
    const cleanSignature = signature.startsWith('0x') ? signature : '0x' + signature;
    
    // Hash the message using Ethereum's personal_sign format
    const messageHash = ethers.utils.hashMessage(message);
    
    // Recover the public key
    const uncompressedPubKey = ethers.utils.recoverPublicKey(messageHash, cleanSignature);
    
    // Convert the uncompressed public key to bytes
    const pubKeyBytes = ethers.utils.arrayify(uncompressedPubKey);
    
    // The uncompressed public key format is: 0x04 + x-coordinate (32 bytes) + y-coordinate (32 bytes)
    // We need to compress it to: 0x02/0x03 + x-coordinate (32 bytes)
    // 0x02 if y is even, 0x03 if y is odd
    
    // Extract x and y coordinates (skip the 0x04 prefix)
    const x = pubKeyBytes.slice(1, 33);
    const y = pubKeyBytes.slice(33);
    
    // Determine if y is even or odd
    const prefix = y[y.length - 1] % 2 === 0 ? 0x02 : 0x03;
    
    // Create compressed public key: prefix + x-coordinate
    const compressedPubKey = new Uint8Array(33);
    compressedPubKey[0] = prefix;
    compressedPubKey.set(x, 1);
    
    return {
      publicKeyBytes: compressedPubKey,
      error: null
    };
  } catch (error) {
    return {
      publicKeyBytes: null,
      error: error.message
    };
  }
}
