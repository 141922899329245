<template>
  <div class="fee-input-container">
    <div class="form-group mb-3">
      <div class="fee-header">
        <label class="form-label field-label">Transaction Fee</label>
        <div class="fee-options">
        <button 
        v-for="(fee, key) in feePresets" 
        :key="key"
        class="btn btn-sm me-2"
        :class="selectedFeePreset === key ? 'btn-primary' : 'btn-outline-primary'"
        @click="selectFeePreset(key)"
      >
        {{ key.charAt(0).toUpperCase() + key.slice(1) }} ({{ fee * 100 }} USD)
      </button>
        </div>
      </div>
    
    <!-- <small class="form-text text-muted"> -->
      <!-- Estimated confirmation time: {{ getConfirmationTime() }} -->
    <!-- </small> -->
  </div>
</div>
</template>

<script>
import { defineComponent, ref, watch, computed } from 'vue';

export default defineComponent({
  name: 'FeeSelector',
  props: {
    feePresets: {
      type: Object,
      required: true
    },
    selectedFeePreset: {
      type: String,
      default: 'zero'
    },
    getConfirmationTime: {
      type: Function,
      required: true
    }
  },
  emits: ['update:selectedFeePreset', 'fee-changed'],
  setup(props, { emit }) {
    const selectFeePreset = (preset) => {
      emit('update:selectedFeePreset', preset);
      
      
      emit('fee-changed');
    };
    
    return {
      selectFeePreset
    };
  }
});
</script>

<style scoped>
/* Field Label Styling */
.field-label {
  font-size: 0.85rem;
  color: #e0e0e0;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 700;
  margin-top: -10px;
  margin-bottom: 18px;
}

/* Fee Header with Label and Options */
.fee-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.75rem;
}

.fee-header .field-label {
  margin-bottom: 0;
  margin-right: 1rem;
  white-space: nowrap;
}

/* Fee Options */
.fee-options {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0.5rem;
  overflow-x: auto;
  align-items: center;
}

.fee-options button {
  flex-shrink: 0;
  min-width: fit-content;
  white-space: nowrap;
}

/* Darker input fields */
.form-control {
  background-color: #121212;
  border-color: #333;
  color: #fff;
}

.form-control:focus {
  background-color: #1a1a1a;
  color: #fff;
  border-color: #b8860b;
  box-shadow: 0 0 0 0.25rem rgba(184, 134, 11, 0.25);
}

.input-group-text {
  background-color: #2a2a2a;
  border-color: #333;
  color: #fff;
}

.btn-outline-primary {
  color: #b8860b !important;
  border-color: #b8860b !important;
}

.btn-outline-primary:hover {
  background-color: rgba(184, 134, 11, 0.1) !important;
}

.btn-primary {
  background-color: #533f0d !important;
  border-color: #f2b721 !important;
}


.fee-input-container {
  /* background-color: #121212; */
  /* border: 1px solid #333; */
  /* border-radius: 0.5rem; */
  padding: 1rem;
  /* margin-top: -10px; */
}
</style>
