<template>
  <div class="dropdown environment-dropdown me-2 header-font-size">
    <button class="btn btn-outline-light dropdown-toggle" type="button" id="environmentDropdown" aria-expanded="false">
      {{ getCurrentEnvironmentLabel() }}
    </button>
    <ul class="dropdown-menu" aria-labelledby="environmentDropdown">
      <li v-for="env in environments" :key="env.label">
        <a 
          class="dropdown-item" 
          :href="env.label !== getCurrentEnvironmentLabel() ? getEnvironmentUrl(env.value) : undefined"
          :class="{ 'current-env': env.label === getCurrentEnvironmentLabel() }"
        >
          {{ env.label }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'EnvironmentSelector',
  props: {
    isExplorerRoute: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {

    const environments = [
      { label: 'Main', value: 'main' },
      { label: 'Test', value: 'test' },
      { label: 'Staging', value: 'staging' },
      { label: 'Dev', value: 'dev' }
    ];

    const getCurrentEnvironment = () => {
      const hostname = window.location.hostname;
      
      if (hostname.includes('test')) {
        return 'test';
      } else if (hostname.includes('staging')) {
        return 'staging';
      } else if (hostname.includes('dev') || hostname.includes('localhost')) {
        return 'dev';
      } else {
        return 'main';
      }
    };

    const getCurrentEnvironmentLabel = () => {
      const currentEnv = getCurrentEnvironment();
      const env = environments.find(e => e.value === currentEnv);
      return env ? env.label : 'Main';
    };

    const getEnvironmentUrl = (env) => {
      const baseUrl = window.location.protocol + '//';
      let url = '';
      
      if (env === 'main') {
        url = baseUrl + 'explorer.redgold.io';
      } else if (env === 'test') {
        url = baseUrl + 'test.explorer.redgold.io';
      } else if (env === 'staging') {
        url = baseUrl + 'staging.explorer.redgold.io';
      } else if (env === 'dev') {
        // Handle localhost specially
        if (window.location.hostname === 'localhost') {
          url = baseUrl + 'localhost:' + window.location.port;
        } else {
          url = baseUrl + 'dev.explorer.redgold.io';
        }
      } else {
        // Default fallback
        url = baseUrl + 'explorer.redgold.io';
      }
      
      // Add /app to the URL if we're in explorer mode
      return url + (props.isExplorerRoute ? '' : '/app');
    };

    return {
      environments,
      getCurrentEnvironment,
      getCurrentEnvironmentLabel,
      getEnvironmentUrl
    };
  }
});
</script>

<style scoped>
.environment-dropdown {
  display: flex;
  align-items: center;
  padding: 0;
  height: 40px;
  margin-top: 10px;
}

.dropdown-menu {
  background-color: #1e1e1e;
  border: 1px solid #333;
  min-width: 6rem;
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  padding: 0;
  margin: 0;
  z-index: 20000;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-item {
  color: #fff;
  padding: 0.4rem 0.8rem;
  cursor: pointer;
  line-height: 1.4;
  display: flex;
  align-items: center;
}

.dropdown-item:hover, .dropdown-item:focus {
  background-color: #333;
  color: #fff;
}

.current-env {
  color: #8b6914 !important;
  font-weight: bold;
  cursor: default;
  pointer-events: none;
}

.btn {
  padding: 0.5rem 0.7rem;
  line-height: 1.4;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  height: 40px;
  box-sizing: border-box;
  vertical-align: middle;
}

.btn-outline-light {
  border-color: #444;
  color: #fff;
}

.btn-outline-light:hover {
  background-color: #333;
  color: #fff;
  border-color: #555;
}

.me-2 {
  margin-right: 0.5rem;
}
</style>
