<template>
  <div>
    <DashboardHeader @refresh="refreshDashboard" />
    <SendModal 
      :show="showSendModal" 
      @close="showSendModal = false"
      @transaction-complete="handleTransactionComplete"
    />
    <!-- Main Dashboard Section -->
    <div class="card text-white mt-3" style="background-color: #121212;">
      <div class="card-body">
        <div>
          <!-- New Dashboard Layout -->
          <div class="dashboard-main mb-4">
            <div class="row">
              <!-- Left Section: Balance and Buttons -->
              <div class="col-md-6">
                <div class="balance-section p-3 border border-secondary rounded d-flex flex-column">
                  <div class="row" style="margin-bottom: -10px;">
                    <!-- Asset Allocation (Left) -->
                    <div class="col-md-6">
                      <AssetAllocation 
                        :assetAllocation="assetAllocation"
                        :currency="currency"
                      />
                    </div>
                    
                    <!-- Gainers/Losers (Right) -->
                    <div class="col-md-6">
                      <GainersLosers 
                        :gainers="gainers"
                        :losers="losers"
                      />
                    </div>
                  </div>
                  
                  <TransactionButtons 
                    @send="handleSend"
                    @receive="handleReceive"
                    @trade="handleTrade"
                  />
                </div>
              </div>
              
              <!-- Right Section: Chart -->
              <div class="col-md-6">
                <div class="chart-section p-3 border border-secondary rounded h-100 d-flex flex-column">
                  <PortfolioChart 
                    :key="'portfolio-chart-' + walletBalance"
                    :timeRanges="timeRanges"
                    :selectedTimeRange="selectedTimeRange"
                    :timeRangeData="timeRangeData"
                    :timeRangeLabels="timeRangeLabels"
                    :currency="currency"
                    :walletBalance="walletBalance"
                    @update:selectedTimeRange="setTimeRange"
                  />
                  <!-- Key Metrics Summary -->
                  <KeyMetrics 
                    :keyMetrics="keyMetrics"
                    class="mt-auto"
                  />
                </div>
              </div>
            </div>
          </div>
          
          <!-- Recent Transactions -->
          <RecentTransactions 
            :transactions="recentTransactions"
            :currency="currency"
          />
          
          <!-- Portfolio List -->
          <PortfolioList 
            :portfolios="portfolios"
            :currency="currency"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { formatCurrency, formatCurrencyAbbreviated, calculatePortfolioValue } from '../../utils/app-utils.js';

// Import components
import DashboardHeader from './components/DashboardHeader.vue';
import AssetAllocation from './components/AssetAllocation.vue';
import GainersLosers from './components/GainersLosers.vue';
import TransactionButtons from './components/TransactionButtons.vue';
import PortfolioChart from './components/PortfolioChart.vue';
import KeyMetrics from './components/KeyMetrics.vue';
import RecentTransactions from './components/RecentTransactions.vue';
import PortfolioList from './components/PortfolioList.vue';
import SendModal from './modals/SendModal.vue';

export default defineComponent({
  name: 'DashboardPanel',
  components: {
    DashboardHeader,
    AssetAllocation,
    GainersLosers,
    TransactionButtons,
    PortfolioChart,
    KeyMetrics,
    RecentTransactions,
    PortfolioList,
    SendModal
  },
  setup() {
    const store = useStore();
    
    // Modal states
    const showSendModal = ref(false);
    
    // Currency setting (default to USD)
    const currency = ref('USD');

    // Track when the wallet balance changes for reactivity
    const walletBalance = computed(() => store.getters.getWalletBalance || 0);
    
    // Get portfolios from store
    const portfolios = computed(() => store.getters.getPortfolios);
    const activePortfolio = computed(() => store.getters.getActivePortfolio || portfolios.value[0]);
    
    // Default transaction data
    const defaultTransactions = [
      {
        date: new Date(2024, 2, 28),
        type: 'Receive',
        asset: 'BTC',
        amount: 0.05,
        status: 'Completed'
      },
      {
        date: new Date(2024, 2, 27),
        type: 'Send',
        asset: 'ETH',
        amount: 1.2,
        status: 'Completed'
      },
      {
        date: new Date(2024, 2, 25),
        type: 'Trade',
        asset: 'BTC → ETH',
        amount: 0.03,
        status: 'Completed'
      },
      {
        date: new Date(2024, 2, 22),
        type: 'Receive',
        asset: 'USDC',
        amount: 500,
        status: 'Completed'
      },
      {
        date: new Date(2024, 2, 20),
        type: 'Send',
        asset: 'BTC',
        amount: 0.01,
        status: 'Pending'
      }
    ];
    
    // Use store's recentTransactions if available, otherwise use default data
    const recentTransactions = computed(() => {
      return store.state.recentTransactions || defaultTransactions;
    });
    
    // Asset allocation data
    const assetAllocation = computed(() => {
      return store.state.assetAllocation || [];
    });
    
    // Gainers/losers data
    const gainers = computed(() => {
      return store.state.gainers || [];
    });
    
    const losers = computed(() => {
      return store.state.losers || [];
    });
    
    // Key metrics
    const keyMetrics = computed(() => {
      return store.state.keyMetrics || {
        dailyChange: '0.0%',
        weeklyHighLow: '$0.0 / $0.0',
        monthlyChange: '0.0%',
        numAssets: '0',
        daysSinceMax: '0',
        portfolioAge: '0 days'
      };
    });
    
    // Time range options
    const timeRanges = ref([
      { label: 'Default', value: 'default' },
      { label: '1h', value: '1h' },
      { label: '1d', value: '1d' },
      { label: '7d', value: '7d' },
      { label: '1y', value: '1y' },
      { label: '5y', value: '5y' }
    ]);
    
    const selectedTimeRange = ref('default');
    
    // Generate chart data based on wallet connection status
    const generateChartData = () => {
      // If wallet is connected and has a balance, use wallet balance for chart data
      if (store.getters.isWalletInitialized && store.getters.getWalletAddress && store.getters.getWalletBalance > 0) {
        const walletBalance = store.getters.getWalletBalance;
        // Use wallet balance for the final value and generate a reasonable historical progression
        // This generates a chart that ends at the current wallet balance
        return {
          'default': [
            walletBalance * 0.5, 
            walletBalance * 0.55, 
            walletBalance * 0.58, 
            walletBalance * 0.62, 
            walletBalance * 0.67, 
            walletBalance * 0.72, 
            walletBalance * 0.77, 
            walletBalance * 0.82, 
            walletBalance * 0.87, 
            walletBalance * 0.92, 
            walletBalance * 0.96, 
            walletBalance
          ],
          '1h': [
            walletBalance * 0.97, 
            walletBalance * 0.975, 
            walletBalance * 0.98, 
            walletBalance * 0.978, 
            walletBalance * 0.982, 
            walletBalance * 0.985, 
            walletBalance * 0.987, 
            walletBalance * 0.989, 
            walletBalance * 0.992, 
            walletBalance * 0.996, 
            walletBalance * 0.998, 
            walletBalance
          ],
          '1d': [
            walletBalance * 0.93, 
            walletBalance * 0.94, 
            walletBalance * 0.945, 
            walletBalance * 0.942, 
            walletBalance * 0.948, 
            walletBalance * 0.955, 
            walletBalance * 0.952, 
            walletBalance * 0.96, 
            walletBalance * 0.97, 
            walletBalance * 0.98, 
            walletBalance * 0.99, 
            walletBalance
          ],
          '7d': [
            walletBalance * 0.85, 
            walletBalance * 0.87, 
            walletBalance * 0.86, 
            walletBalance * 0.88, 
            walletBalance * 0.9, 
            walletBalance * 0.89, 
            walletBalance * 0.92, 
            walletBalance * 0.94, 
            walletBalance * 0.96, 
            walletBalance * 0.98, 
            walletBalance * 0.99, 
            walletBalance
          ],
          '1y': [
            walletBalance * 0.65, 
            walletBalance * 0.68, 
            walletBalance * 0.66, 
            walletBalance * 0.7, 
            walletBalance * 0.75, 
            walletBalance * 0.78, 
            walletBalance * 0.76, 
            walletBalance * 0.8, 
            walletBalance * 0.85, 
            walletBalance * 0.9, 
            walletBalance * 0.95, 
            walletBalance
          ],
          '5y': [
            walletBalance * 0.15, 
            walletBalance * 0.25, 
            walletBalance * 0.35, 
            walletBalance * 0.45, 
            walletBalance * 0.5, 
            walletBalance * 0.6, 
            walletBalance * 0.65, 
            walletBalance * 0.75, 
            walletBalance * 0.85, 
            walletBalance * 0.9, 
            walletBalance * 0.95, 
            walletBalance
          ]
        };
      } else {
        // Demo data when wallet is not connected or has no balance
        return {
          'default': [30000, 32000, 35000, 34000, 36000, 40000, 42000, 45000, 47000, 48000, 52000, 60000],
          '1h': [59000, 59200, 59400, 59100, 59300, 59500, 59700, 59600, 59800, 59900, 60000, 60100],
          '1d': [58000, 58500, 59000, 58800, 59200, 59500, 59300, 59600, 59800, 59700, 59900, 60000],
          '7d': [55000, 56000, 55500, 56500, 57000, 56800, 57500, 58000, 58500, 59000, 59500, 60000],
          '1y': [40000, 42000, 41000, 43000, 45000, 47000, 46000, 48000, 50000, 52000, 55000, 60000],
          '5y': [10000, 15000, 20000, 25000, 30000, 35000, 40000, 45000, 50000, 55000, 58000, 60000]
        };
      }
    };
    
    // Use store's chartData if available, otherwise generate data based on wallet status
    // This now directly depends on walletBalance for reactivity
    const timeRangeData = computed(() => {
      // This will force the computed property to re-evaluate when wallet balance changes
      const currentBalance = walletBalance.value;
      console.log('Updating chart data with wallet balance:', currentBalance);
      
      // Only use store's chartData when in demo mode (no wallet or demo not disabled)
      if (!store.getters.isWalletInitialized || !store.getters.getWalletAddress || !store.getters.isDemoDisabled) {
        return store.state.chartData;
      }
      
      // Otherwise, generate dynamic data based on the current wallet balance
      return generateChartData();
    });
    
    // Time range labels
    const timeRangeLabels = {
      'default': ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      '1h': ['5m', '10m', '15m', '20m', '25m', '30m', '35m', '40m', '45m', '50m', '55m', '60m'],
      '1d': ['2h', '4h', '6h', '8h', '10h', '12h', '14h', '16h', '18h', '20h', '22h', '24h'],
      '7d': ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7', 'Day 8', 'Day 9', 'Day 10', 'Day 11', 'Day 12'],
      '1y': ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      '5y': ['2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030', '2031']
    };
    
    // Set time range
    const setTimeRange = (range) => {
      selectedTimeRange.value = range;
      // In a real app, this would fetch data for the selected time range
      console.log(`Setting time range to ${range}`);
    };
    
    // Create new portfolio
    const createNewPortfolio = () => {
      // In a real app, this would open a modal or navigate to a creation page
      const newId = (Math.max(...portfolios.value.map(p => parseInt(p.id))) + 1).toString();
      const newPortfolio = {
        id: newId,
        name: `New Portfolio ${newId}`,
        createdAt: new Date(),
        assets: []
      };
      store.commit('addPortfolio', newPortfolio);
      store.commit('setActivePortfolio', newPortfolio);
    };
    
    // Refresh dashboard data
    const refreshDashboard = () => {
      // In a real app, this would fetch updated data from the API
      console.log('Refreshing dashboard data...');
      // Example: re-fetch portfolios data
      // store.dispatch('fetchPortfolios');
      // You could also show a loading indicator or toast notification here
    };
    
    // Transaction button handlers
    const handleSend = () => {
      console.log('Send button clicked');
      showSendModal.value = true;
    };
    
    const handleReceive = () => {
      console.log('Receive button clicked');
      // In a real app, this would navigate to the receive page or open a modal
    };
    
    const handleTrade = () => {
      console.log('Trade button clicked');
      // In a real app, this would navigate to the trade page or open a modal
    };
    
    // Handle transaction complete event from SendModal
    const handleTransactionComplete = (transactionData) => {
      console.log('Transaction completed:', transactionData);
      
      // Add the transaction to recent transactions
      const newTransaction = {
        date: new Date(transactionData.timestamp),
        type: 'Send',
        asset: 'RDG',
        amount: transactionData.amount,
        status: 'Pending',
        hash: transactionData.hash
      };
      
      // In a real app, this would update the store with the new transaction
      // For now, we'll just log it
      console.log('New transaction added:', newTransaction);
    };
    
    onMounted(() => {
      // No longer automatically setting active portfolio to first portfolio
      // This allows the default "None" value to be used
    });

    return {
      refreshDashboard,
      
      // Modal states
      showSendModal,
      
      // Currency
      currency,
      
      // Portfolio data
      portfolios,
      activePortfolio,
      createNewPortfolio,
      
      // Transactions
      recentTransactions,
      
      // Asset allocation
      assetAllocation,
      
      // Gainers/losers
      gainers,
      losers,
      
      // Key metrics
      keyMetrics,
      
      // Chart
      timeRanges,
      selectedTimeRange,
      timeRangeData,
      timeRangeLabels,
      setTimeRange,
      
      // Transaction handlers
      handleSend,
      handleReceive,
      handleTrade,
      handleTransactionComplete,
      
      // Utility functions
      formatCurrency,
      formatCurrencyAbbreviated,
      calculatePortfolioValue
    };
  }
});
</script>

<style scoped>
/* Dashboard main section */
.dashboard-main {
  /* margin-bottom: 2rem; */
}

.balance-section, .chart-section {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}
</style>
