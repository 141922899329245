<template>
  <div class="network-status-container">
    <div class="network-status" @click="toggleNetworkStatus">
      <div class="status-button" ref="statusButton">
        <div class="status-indicator" :class="{ 'online': networkStatus.online }"></div>
        <span class="status-text">Network Status</span>
      </div>
      
      <!-- Network Status Popup -->
      <div class="network-popup" v-if="showNetworkStatus" :style="popupStyle">
        <div class="popup-content">
          <div class="health-item">
            <span class="health-label">Last event update:</span>
            <span class="health-value">{{ networkStatus.lastEventUpdate }} seconds ago</span>
          </div>
          <div class="health-item">
            <span class="health-label">Latency:</span>
            <span class="health-value">{{ networkStatus.latency }} ms</span>
          </div>
          <div class="health-item">
            <span class="health-label">Websocket peers:</span>
            <span class="health-value">{{ networkStatus.websocketPeers }} peers</span>
          </div>
          <div class="health-item">
            <span class="health-label">Transactions:</span>
            <span class="health-value">{{ networkStatus.transactions }}</span>
          </div>
          <div class="health-item">
            <span class="health-label">Total network peers:</span>
            <span class="health-value">{{ networkStatus.totalNetworkPeers }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, nextTick, onBeforeUnmount, watch } from 'vue';

export default defineComponent({
  name: 'NetworkStatus',
  emits: ['toggle-network-status'],
  props: {
    initialNetworkStatus: {
      type: Object,
      default: () => ({
        online: true,
        lastEventUpdate: 45,
        latency: 45,
        websocketPeers: 12,
        transactions: 1458,
        totalNetworkPeers: 24
      })
    }
  },
  setup(props, { emit }) {
    const showNetworkStatus = ref(false);
    const networkStatus = ref(props.initialNetworkStatus);
    const statusButton = ref(null);
    const popupPosition = ref({ top: 0, left: 0 });
    
    // Calculate popup position based on button position
    const calculatePopupPosition = () => {
      if (!statusButton.value) return;
      
      const buttonRect = statusButton.value.getBoundingClientRect();
      popupPosition.value = {
        top: buttonRect.bottom + 5, // 5px below the button
        left: buttonRect.left
      };
    };
    
    // Computed style for popup positioning
    const popupStyle = computed(() => {
      return {
        top: `${popupPosition.value.top}px`,
        left: `${popupPosition.value.left}px`
      };
    });
    
    // Handle clicks outside the popup
    const handleOutsideClick = (event) => {
      // Check if the click is outside the network status component
      const networkStatusEl = statusButton.value?.closest('.network-status');
      if (networkStatusEl && !networkStatusEl.contains(event.target)) {
        showNetworkStatus.value = false;
      }
    };
    
    // Add/remove document click listener when popup visibility changes
    watch(showNetworkStatus, (isVisible) => {
      if (isVisible) {
        // Add the click listener with a slight delay to avoid the current click event
        setTimeout(() => {
          document.addEventListener('click', handleOutsideClick);
        }, 0);
      } else {
        document.removeEventListener('click', handleOutsideClick);
      }
    });
    
    // Clean up event listeners when component is unmounted
    onBeforeUnmount(() => {
      document.removeEventListener('click', handleOutsideClick);
    });
    
    // Toggle network status popup
    const toggleNetworkStatus = () => {
      showNetworkStatus.value = !showNetworkStatus.value;
      
      if (showNetworkStatus.value) {
        // Calculate position when showing the popup
        nextTick(calculatePopupPosition);
      }
      
      emit('toggle-network-status', showNetworkStatus.value);
    };

    // Recalculate position on window resize
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', () => {
        if (showNetworkStatus.value) {
          calculatePopupPosition();
        }
      });
    }

    return {
      networkStatus,
      showNetworkStatus,
      toggleNetworkStatus,
      statusButton,
      popupStyle
    };
  }
});
</script>

<style scoped>
.network-status-container {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  top: 0;
  margin-top: 20px;
  margin-right: 10px;
}

/* Network Status Styles */
.network-status {
  position: relative;
}

.status-button {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background-color: rgba(30, 30, 40, 0.5);
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s, box-shadow 0.2s;
  height: 30px;
}

.status-button:hover {
  background-color: rgba(50, 50, 70, 0.7);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.status-indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #cc3030; /* Red for offline */
  margin-right: 6px; /* Reduced from 10px to 6px to decrease space */
  box-shadow: 0 0 5px rgba(204, 48, 48, 0.5);
  /* Fix vertical alignment */
  display: inline-block;
  position: relative;
  top: 4px; /* Adjusted for header alignment */
}

.status-indicator.online {
  background-color: #30cc30; /* Green for online */
  box-shadow: 0 0 5px rgba(48, 204, 48, 0.5);
}

.status-text {
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1;
}

/* Network Status Popup */
.network-popup {
  position: fixed; /* Changed from absolute to fixed to prevent clipping */
  width: 280px;
  background-color: #0f0f1a;
  border: 1px solid #333;
  border-radius: 6px;
  z-index: 10000; /* High z-index to ensure it's above other elements */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.popup-content {
  padding: 10px 15px 5px; /* Reduced bottom padding to eliminate extra space */
}

.health-item {
  display: flex;
  justify-content: space-between;
  padding: 6px 0; /* Increased by 50% from 4px to 6px for more spacing between rows */
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.health-item:last-child {
  border-bottom: none;
  margin-bottom: 0; /* Ensure no margin at the bottom */
  padding-bottom: 0; /* Remove padding at the bottom of the last item */
}

.health-label {
  color: #999;
  font-size: 0.9rem;
}

.health-value {
  font-weight: 500;
  font-size: 0.9rem;
}

.health-value.online {
  color: #30cc30; /* Green for online status */
}
</style>
