<template>
  <div>
    <div class="d-flex align-items-center dashboard-header">
      <h3 class="mb-0 ms-3">Settings</h3>
    </div>

    <!-- Main Settings Section -->
    <div class="card text-white mb-4" style="background-color: #121212;">
      <div class="card-body">
        <!-- Navigation Tabs (Horizontal) -->
        <div class="nav nav-tabs nav-pills mb-3" role="tablist">
          <button 
            class="nav-link" 
            :class="{ active: activeTab === 'general' }"
            @click="activeTab = 'general'"
          >
            General
          </button>
          <button 
            class="nav-link" 
            :class="{ active: activeTab === 'keys' }"
            @click="activeTab = 'keys'"
          >
            API Keys
          </button>
          <button 
            class="nav-link" 
            :class="{ active: activeTab === 'mnemonics' }"
            @click="activeTab = 'mnemonics'"
          >
            Mnemonics
          </button>
          <button 
            class="nav-link" 
            :class="{ active: activeTab === 'addresses' }"
            @click="activeTab = 'addresses'"
          >
            Addresses
          </button>
          <button 
            class="nav-link" 
            :class="{ active: activeTab === 'contacts' }"
            @click="activeTab = 'contacts'"
          >
            Contacts
          </button>
          <button 
            class="nav-link" 
            :class="{ active: activeTab === 'email' }"
            @click="activeTab = 'email'"
          >
            Email
          </button>
          <button 
            class="nav-link" 
            :class="{ active: activeTab === 'deployment' }"
            @click="activeTab = 'deployment'"
          >
            Deployment
          </button>
        </div>
        
        <div class="row">

          <!-- Tab Content -->
          <div class="col-md-12">
            <div class="tab-content">
              <!-- General Settings -->
              <div v-if="activeTab === 'general'" class="tab-pane fade show active">
                <GeneralTab 
                  :addresses-count="savedAddresses.length"
                  :mnemonics-count="mnemonics.length"
                  :contacts-count="contacts.length"
                  :servers-count="deploymentServers.length"
                  :config-data="configData"
                  @show-import-modal="showImportModal = true"
                  @show-export-modal="showExportModal = true"
                  @purge-configs="purgeConfigs"
                />
              </div>
              
              <!-- Keys Settings -->
              <div v-if="activeTab === 'keys'" class="tab-pane fade show active">
                <KeysTab 
                  :keys="keys"
                  :ai-keys="aiKeys"
                  @update:keys="updateKeys"
                  @update:aiKeys="updateAiKeys"
                />
              </div>
              
              <!-- Mnemonics Settings -->
              <div v-if="activeTab === 'mnemonics'" class="tab-pane fade show active">
                <MnemonicsTab 
                  :mnemonics="mnemonics"
                  @show-add-mnemonic-modal="showAddMnemonicModal = true"
                  @remove-mnemonic="removeMnemonic"
                />
              </div>
              
              <!-- Email Settings -->
              <div v-if="activeTab === 'email'" class="tab-pane fade show active">
                <EmailTab 
                  :email="email"
                  @update:email="updateEmail"
                />
              </div>

              <!-- Addresses Settings -->
              <div v-if="activeTab === 'addresses'" class="tab-pane fade show active">
                <AddressesTab 
                  :saved-addresses="savedAddresses"
                  @show-add-address-modal="showAddAddressModal = true"
                  @remove-address="removeAddress"
                />
              </div>

              <!-- Contacts Settings -->
              <div v-if="activeTab === 'contacts'" class="tab-pane fade show active">
                <ContactsTab 
                  :contacts="contacts"
                  @show-add-contact-modal="showAddContactModal = true"
                  @remove-contact="removeContact"
                />
              </div>

              <!-- Deployment Servers Settings -->
              <div v-if="activeTab === 'deployment'" class="tab-pane fade show active">
                <DeploymentTab 
                  :deployment-servers="deploymentServers"
                  @show-add-server-modal="showAddServerModal = true"
                  @remove-server="removeServer"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modals -->
    <AddAddressModal 
      :show="showAddAddressModal"
      @close="showAddAddressModal = false"
      @add="addAddress"
    />
    
    <AddMnemonicModal 
      :show="showAddMnemonicModal"
      @close="showAddMnemonicModal = false"
      @add="addMnemonic"
    />
    
    <AddContactModal 
      :show="showAddContactModal"
      @close="showAddContactModal = false"
      @add="addContact"
    />
    
    <AddServerModal 
      :show="showAddServerModal"
      @close="showAddServerModal = false"
      @add="addServer"
    />
    
    <ImportModal 
      :show="showImportModal"
      @close="showImportModal = false"
      @import-success="handleImportSuccess"
    />
    <ExportModal 
      :show="showExportModal"
      :config-data="configData"
      @close="showExportModal = false"
    />
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted, watch, nextTick } from 'vue';
import { useStore } from 'vuex';

// Import tab components
import GeneralTab from './tabs/GeneralTab.vue';
import KeysTab from './tabs/KeysTab.vue';
import MnemonicsTab from './tabs/MnemonicsTab.vue';
import AddressesTab from './tabs/AddressesTab.vue';
import ContactsTab from './tabs/ContactsTab.vue';
import EmailTab from './tabs/EmailTab.vue';
import DeploymentTab from './tabs/DeploymentTab.vue';

// Import modal components
import AddAddressModal from './modals/AddAddressModal.vue';
import AddMnemonicModal from './modals/AddMnemonicModal.vue';
import AddContactModal from './modals/AddContactModal.vue';
import AddServerModal from './modals/AddServerModal.vue';
import ImportModal from './modals/ImportModal.vue';
import ExportModal from './modals/ExportModal.vue';

export default defineComponent({
  name: 'SettingsTab',
  components: {
    GeneralTab,
    KeysTab,
    MnemonicsTab,
    AddressesTab,
    ContactsTab,
    EmailTab,
    DeploymentTab,
    AddAddressModal,
    AddMnemonicModal,
    AddContactModal,
    AddServerModal,
    ImportModal,
    ExportModal
  },
  setup() {
    const store = useStore();
    
    // Active tab state
    const activeTab = ref('general');
    
    // Config data from store
    const configData = computed(() => store.getters.getConfigData);
    
    // Keys state
    const keys = ref({
      etherscan: '',
      recaptcha: '',
      aws_access: '',
      aws_secret: ''
    });
    
    // AI Keys state
    const aiKeys = ref({
      anthropic: '',
      openai: '',
      gemini: '',
      elevenlabs: ''
    });
    
    // Email settings state
    const email = ref({
      from: '',
      to: ''
    });
    
    // Saved addresses state
    const savedAddresses = ref([]);
    const showAddAddressModal = ref(false);
    const newAddress = ref({
      name: '',
      address: '',
      contact: ''
    });
    
    // Mnemonics state
    const mnemonics = ref([]);
    const showAddMnemonicModal = ref(false);
    const newMnemonic = ref({
      name: '',
      mnemonic: '',
      passphrase: '',
      persist_disk: true
    });
    
    // Contacts state
    const contacts = ref([]);
    const showAddContactModal = ref(false);
    const newContact = ref({
      name: '',
      peer_id: ''
    });
    
    // Deployment servers state
    const deploymentServers = ref([]);
    const showAddServerModal = ref(false);
    const newServer = ref({
      ssh_host: '',
      ssh_user: '',
      external_ipv4: '',
      external_hostname: '',
      deploy_metrics_instance: false,
      is_localhost: false,
      instances: []
    });
    
    // Import/Export state
    const showImportModal = ref(false);
    const showExportModal = ref(false);
    const importConfigText = ref('');
    const exportConfigText = ref('');
    const exportFormat = ref('json');
    const includeSecrets = ref(false);
    const overwriteMode = ref(false);
    const importResult = ref(null);
    
    // Flag to prevent initialization during updates
    const isUpdating = ref(false);
    
    // Flag to track initialization
    const isInitialized = ref(false);
    
    // Manual save method to be called explicitly
    const saveToStore = () => {
      // Don't save if we're still initializing or updating
      if (!isInitialized.value || isUpdating.value) {
        console.log('Skipping save during initialization/update');
        return;
      }
      
      console.log('Saving to store');
      const newConfig = {
        keys: {
          etherscan: keys.value.etherscan,
          recaptcha: keys.value.recaptcha,
          aws_access: keys.value.aws_access,
          aws_secret: keys.value.aws_secret,
          ai: {
            anthropic: aiKeys.value.anthropic,
            openai: aiKeys.value.openai,
            gemini: aiKeys.value.gemini,
            elevenlabs: aiKeys.value.elevenlabs
          }
        },
        email: {
          from: email.value.from,
          to: email.value.to
        },
        local: {
          saved_addresses: [...savedAddresses.value],
          mnemonics: [...mnemonics.value],
          contacts: [...contacts.value],
          deploy: {
            servers: [...deploymentServers.value]
          }
        }
      };
      
      store.commit('setConfigData', newConfig);
    };
    
    // Initialize data from config
    const initializeFromConfig = () => {
      isUpdating.value = true;
      try {
        const config = store.getters.getConfigData;
        
        // Initialize keys
        if (config.keys) {
          keys.value = {
            etherscan: config.keys.etherscan || '',
            recaptcha: config.keys.recaptcha || '',
            aws_access: config.keys.aws_access || '',
            aws_secret: config.keys.aws_secret || ''
          };
          
          if (config.keys.ai) {
            aiKeys.value = {
              anthropic: config.keys.ai.anthropic || '',
              openai: config.keys.ai.openai || '',
              gemini: config.keys.ai.gemini || '',
              elevenlabs: config.keys.ai.elevenlabs || ''
            };
          }
        }
        
        // Initialize email settings
        if (config.email) {
          email.value = {
            from: config.email.from || '',
            to: config.email.to || ''
          };
        }
        
        // Initialize saved addresses
        if (config.local?.saved_addresses) {
          savedAddresses.value = [...config.local.saved_addresses];
        }
        
        // Initialize mnemonics
        if (config.local?.mnemonics) {
          mnemonics.value = [...config.local.mnemonics];
        }
        
        // Initialize contacts
        if (config.local?.contacts) {
          contacts.value = [...config.local.contacts];
        }
        
        // Initialize deployment servers
        if (config.local?.deploy?.servers) {
          deploymentServers.value = [...config.local.deploy.servers];
        }
      } finally {
        isUpdating.value = false;
      }
    };
    
    // Methods to update local state and save to store
    const updateKeys = (newKeys) => {
      keys.value = newKeys;
      saveToStore();
    };
    
    const updateAiKeys = (newAiKeys) => {
      aiKeys.value = newAiKeys;
      saveToStore();
    };
    
    const updateEmail = (newEmail) => {
      email.value = newEmail;
      saveToStore();
    };
    
    // Address methods
    const addAddress = () => {
      if (newAddress.value.address) {
        savedAddresses.value.push({
          name: newAddress.value.name,
          address: newAddress.value.address,
          contact: newAddress.value.contact
        });
        
        // Reset form
        newAddress.value = {
          name: '',
          address: '',
          contact: ''
        };
        
        // Close modal
        showAddAddressModal.value = false;
        
        // Save config
        saveToStore();
      }
    };
    
    const removeAddress = (index) => {
      savedAddresses.value.splice(index, 1);
      saveToStore();
    };
    
    // Mnemonic methods
    const addMnemonic = () => {
      if (newMnemonic.value.name && newMnemonic.value.mnemonic) {
        mnemonics.value.push({
          name: newMnemonic.value.name,
          mnemonic: newMnemonic.value.mnemonic,
          passphrase: newMnemonic.value.passphrase,
          persist_disk: newMnemonic.value.persist_disk
        });
        
        // Reset form
        newMnemonic.value = {
          name: '',
          mnemonic: '',
          passphrase: '',
          persist_disk: true
        };
        
        // Close modal
        showAddMnemonicModal.value = false;
        
        // Save config
        saveToStore();
      }
    };
    
    const removeMnemonic = (index) => {
      mnemonics.value.splice(index, 1);
      saveToStore();
    };
    
    // Contact methods
    const addContact = () => {
      if (newContact.value.name) {
        contacts.value.push({
          name: newContact.value.name,
          peer_id: newContact.value.peer_id
        });
        
        // Reset form
        newContact.value = {
          name: '',
          peer_id: ''
        };
        
        // Close modal
        showAddContactModal.value = false;
        
        // Save config
        saveToStore();
      }
    };
    
    const removeContact = (index) => {
      contacts.value.splice(index, 1);
      saveToStore();
    };
    
    // Server methods
    const addServer = () => {
      if (newServer.value.ssh_host && newServer.value.ssh_user && newServer.value.external_ipv4) {
        deploymentServers.value.push({
          ssh_host: newServer.value.ssh_host,
          ssh_user: newServer.value.ssh_user,
          external_ipv4: newServer.value.external_ipv4,
          external_hostname: newServer.value.external_hostname,
          deploy_metrics_instance: newServer.value.deploy_metrics_instance,
          is_localhost: newServer.value.is_localhost,
          instances: []
        });
        
        // Reset form
        newServer.value = {
          ssh_host: '',
          ssh_user: '',
          external_ipv4: '',
          external_hostname: '',
          deploy_metrics_instance: false,
          is_localhost: false,
          instances: []
        };
        
        // Close modal
        showAddServerModal.value = false;
        
        // Save config
        saveToStore();
      }
    };
    
    const removeServer = (index) => {
      deploymentServers.value.splice(index, 1);
      saveToStore();
    };
    
    // Import/Export methods
    const handleImportSuccess = () => {
      console.log('Import successful, waiting for store update');
      isUpdating.value = true;
      nextTick(() => {
        initializeFromConfig();
        isUpdating.value = false;
      });
    };

    const updateExportText = () => {
      try {
        const config = configData.value;
        
        // Check if config is an error object
        if (config && typeof config === 'object' && config.message !== undefined) {
          console.error('Config data is an error object:', config);
          exportConfigText.value = `Error: Invalid configuration data (${config.message || 'Unknown error'})`;
          return;
        }
        
        const configToExport = JSON.parse(JSON.stringify(config || {}));
        
        // Remove sensitive information if not including secrets
        if (!includeSecrets.value) {
          if (configToExport.keys) {
            if (configToExport.keys.ai) {
              configToExport.keys.ai = {
                anthropic: configToExport.keys.ai.anthropic ? '***' : '',
                openai: configToExport.keys.ai.openai ? '***' : '',
                gemini: configToExport.keys.ai.gemini ? '***' : '',
                elevenlabs: configToExport.keys.ai.elevenlabs ? '***' : ''
              };
            }
            
            configToExport.keys = {
              ...configToExport.keys,
              etherscan: configToExport.keys.etherscan ? '***' : '',
              recaptcha: configToExport.keys.recaptcha ? '***' : '',
              aws_access: configToExport.keys.aws_access ? '***' : '',
              aws_secret: configToExport.keys.aws_secret ? '***' : ''
            };
          }
          
          // Remove mnemonics
          if (configToExport.local?.mnemonics) {
            configToExport.local.mnemonics = configToExport.local.mnemonics.map(m => ({
              ...m,
              mnemonic: '***',
              passphrase: m.passphrase ? '***' : ''
            }));
          }
        }
        
        // Format based on selected format
        if (exportFormat.value === 'json') {
          exportConfigText.value = JSON.stringify(configToExport, null, 2);
        } else {
          // Use the WASM binding to convert to TOML
          const configJson = JSON.stringify(configToExport);
          const tomlResult = store.state.wasm.export_config_to_toml(configJson);
          
          // Check if the result is an error (ErrorInfo)
          if (tomlResult && typeof tomlResult === 'object' && tomlResult.code !== undefined) {
            throw new Error(`Failed to convert to TOML: ${tomlResult.description || tomlResult.message || 'Unknown error'}`);
          }
          
          exportConfigText.value = tomlResult;
        }
      } catch (error) {
        exportConfigText.value = `Error generating export: ${error.message}`;
      }
    };

    const copyToClipboard = async () => {
      try {
        await navigator.clipboard.writeText(exportConfigText.value);
        console.log('Configuration copied to clipboard');
      } catch (error) {
        console.error('Error copying to clipboard:', error);
      }
    };

    const downloadConfig = () => {
      try {
        const blob = new Blob([exportConfigText.value], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `config.${exportFormat.value}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error downloading configuration:', error);
      }
    };

    const toggleOverwriteMode = () => {
      overwriteMode.value = !overwriteMode.value;
    };

    // Purge all configuration
    const purgeConfigs = () => {
      if (confirm('Are you sure you want to purge all configuration data? This action cannot be undone.')) {
        // Reset all local state
        keys.value = {
          etherscan: '',
          recaptcha: '',
          aws_access: '',
          aws_secret: ''
        };
        
        aiKeys.value = {
          anthropic: '',
          openai: '',
          gemini: '',
          elevenlabs: ''
        };
        
        email.value = {
          from: '',
          to: ''
        };
        
        savedAddresses.value = [];
        mnemonics.value = [];
        contacts.value = [];
        deploymentServers.value = [];

        // Clear config in the store
        store.commit('setConfigData', {});
        
        console.log('Configuration purged successfully');
      }
    };

    // Initialize on mount
    onMounted(() => {
      isUpdating.value = true;
      nextTick(() => {
        initializeFromConfig();
        isInitialized.value = true;
        isUpdating.value = false;
      });
    });
    
    return {
      // State
      activeTab,
      keys,
      aiKeys,
      email,
      savedAddresses,
      mnemonics,
      contacts,
      deploymentServers,
      configData,
      
      // Modal state
      showAddAddressModal,
      showAddMnemonicModal,
      showAddContactModal,
      showAddServerModal,
      showImportModal,
      showExportModal,
      
      // Form state
      newAddress,
      newMnemonic,
      newContact,
      newServer,
      
      // Import/Export state
      importConfigText,
      exportConfigText,
      exportFormat,
      includeSecrets,
      overwriteMode,
      importResult,
      
      // Methods
      updateKeys,
      updateAiKeys,
      updateEmail,
      addAddress,
      removeAddress,
      addMnemonic,
      removeMnemonic,
      addContact,
      removeContact,
      addServer,
      removeServer,
      handleImportSuccess,
      updateExportText,
      copyToClipboard,
      downloadConfig,
      toggleOverwriteMode,
      purgeConfigs
    };
  }
});
</script>

<style scoped>
/* Basic styles */
.dashboard-header {
  margin-bottom: 1.5rem;
}

.nav-tabs {
  border-bottom: 1px solid #343a40;
}

.nav-tabs .nav-link {
  color: #adb5bd;
  border: none;
  border-bottom: 2px solid transparent;
  border-radius: 0;
  padding: 0.5rem 1rem;
  margin-right: 0.5rem;
}

.nav-tabs .nav-link:hover {
  color: #fff;
  border-bottom-color: #6c757d;
}

.nav-tabs .nav-link.active {
  background-color: transparent;
  border-bottom-color: #ffc107;
}

.tab-pane {
  padding: 1.5rem 0;
}
</style>
