<template>
  <div id="app">
    <div class="header-container" v-if="!isAppRoute">
      <AppHeader />
    </div>
    <router-view></router-view>
    <div id="modal-container" class="modal-container"></div>
  </div>
</template>

<script>
import AppHeader from './components/AppHeader.vue';
import rustWebSocketClient from './utils/rust-websocket-client';

export default {
  name: 'App',
  components: {
    AppHeader,
  },
  computed: {
    isAppRoute() {
      return this.$route.path.startsWith('/app');
    }
  },
  watch: {
    $route() {
      this.updateTitle();
    }
  },
  methods: {
    updateTitle() {
      if (this.$route.path.startsWith('/app')) {
        document.title = 'Redgold App';
      } else {
        document.title = 'Redgold Explorer';
      }
    },
    initializeWebSockets() {
      // If WASM is already loaded, initialize the Rust WebSocket client
      if (window.redgoldWasm) {
        this.onWasmLoaded();
      }
    },
    onWasmLoaded() {
      console.log('WASM loaded, initializing Rust WebSocket client');
      
      // Initialize the Rust WebSocket client
      if (rustWebSocketClient.initialize()) {
        // If initialization was successful, connect
        rustWebSocketClient.connect();
      } else {
        console.warn('Failed to initialize Rust WebSocket client');
      }
    }
  },
  mounted() {
    this.updateTitle();
    
    // Initialize WebSocket connections
    this.initializeWebSockets();
    
    // Add event listener for WASM loaded
    window.addEventListener('wasmLoaded', this.onWasmLoaded);
  },
  beforeUnmount() {
    // Clean up event listener
    window.removeEventListener('wasmLoaded', this.onWasmLoaded);
    
    // Disconnect WebSockets
    if (window.redgoldWasm && window.redgoldWasm.ws_disconnect) {
      rustWebSocketClient.disconnect();
    }
  }
}
</script>

<style lang="scss">
$body-bg: #000;
$body-color: #fff;
$medium-grey: #191a19;

html {
  background-color: $body-bg;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

body {
  color: $body-color;
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: $body-bg;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  flex-grow: 1;
  background-color: $body-bg;
  color: $body-color;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  isolation: isolate;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 9999;
  isolation: isolate;
}

.header-container {
  margin-top: 0; /* Remove top margin to make it start at the top */
}


a {
  color: #ADD8E6;
}

a:hover {
  background-color: $medium-grey;
}

/* Additional scrollbar styles specifically for app routes */
[class*="app-"] ::-webkit-scrollbar,
.main-content::-webkit-scrollbar,
.sidebar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

[class*="app-"] ::-webkit-scrollbar-track,
.main-content::-webkit-scrollbar-track,
.sidebar::-webkit-scrollbar-track {
  background: #121212;
  border-radius: 4px;
}

[class*="app-"] ::-webkit-scrollbar-thumb,
.main-content::-webkit-scrollbar-thumb,
.sidebar::-webkit-scrollbar-thumb {
  background: #333;
  border-radius: 4px;
}

[class*="app-"] ::-webkit-scrollbar-thumb:hover,
.main-content::-webkit-scrollbar-thumb:hover,
.sidebar::-webkit-scrollbar-thumb:hover {
  background: #444;
}

</style>
