<template>
  <div class="modal" :class="{ 'd-block': show }" tabindex="-1" v-if="show">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content bg-dark text-white">
        <div class="modal-header">
          <h5 class="modal-title">Add Address</h5>
          <button type="button" class="btn-close btn-close-white" @click="$emit('close')"></button>
        </div>
        <div class="modal-body">
          <div class="form-group mb-3">
            <label for="addressName">Name (Optional)</label>
            <input type="text" class="form-control bg-dark text-white" id="addressName" v-model="address.name">
          </div>
          <div class="form-group mb-3">
            <label for="addressValue">Address *</label>
            <input type="text" class="form-control bg-dark text-white" id="addressValue" v-model="address.address" required>
          </div>
          <div class="form-group mb-3">
            <label for="addressContact">Contact (Optional)</label>
            <input type="text" class="form-control bg-dark text-white" id="addressContact" v-model="address.contact">
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="$emit('close')">Cancel</button>
          <button type="button" class="btn btn-primary" @click="addAddress" :disabled="!address.address">Add</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive } from 'vue';

export default defineComponent({
  name: 'AddAddressModal',
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  emits: ['close', 'add'],
  setup(props, { emit }) {
    const address = reactive({
      name: '',
      address: '',
      contact: ''
    });

    const addAddress = () => {
      if (address.address) {
        emit('add', { ...address });
        
        // Reset form
        address.name = '';
        address.address = '';
        address.contact = '';
      }
    };

    return {
      address,
      addAddress
    };
  }
});
</script>

<style scoped>
.modal {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  border: 1px solid #343a40;
}

.form-control {
  border: 1px solid #343a40;
}

.form-control:focus {
  border-color: #ffc107;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.25);
}
</style>
