<template>
  <div class="modal" :class="{ 'd-block': show }" tabindex="-1" v-if="show">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content bg-dark text-white">
        <div class="modal-header">
          <h5 class="modal-title">Add Mnemonic</h5>
          <button type="button" class="btn-close btn-close-white" @click="$emit('close')"></button>
        </div>
        <div class="modal-body">
          <div class="form-group mb-3">
            <label for="mnemonicName">Name *</label>
            <input type="text" class="form-control bg-dark text-white" id="mnemonicName" v-model="mnemonic.name" required>
          </div>
          <div class="form-group mb-3">
            <label for="mnemonicValue">Mnemonic Phrase *</label>
            <textarea class="form-control bg-dark text-white" id="mnemonicValue" v-model="mnemonic.mnemonic" rows="3" required></textarea>
            <small class="form-text text-muted">Enter your 12 or 24 word mnemonic phrase</small>
          </div>
          <div class="form-group mb-3">
            <label for="mnemonicPassphrase">Passphrase (Optional)</label>
            <input type="password" class="form-control bg-dark text-white" id="mnemonicPassphrase" v-model="mnemonic.passphrase">
          </div>
          <div class="form-check mb-3">
            <input type="checkbox" class="form-check-input" id="mnemonicPersist" v-model="mnemonic.persist_disk">
            <label class="form-check-label" for="mnemonicPersist">Persist to disk</label>
            <small class="d-block form-text text-muted">If unchecked, the mnemonic will only be stored in memory</small>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="$emit('close')">Cancel</button>
          <button type="button" class="btn btn-primary" @click="addMnemonic" :disabled="!mnemonic.name || !mnemonic.mnemonic">Add</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive } from 'vue';

export default defineComponent({
  name: 'AddMnemonicModal',
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  emits: ['close', 'add'],
  setup(props, { emit }) {
    const mnemonic = reactive({
      name: '',
      mnemonic: '',
      passphrase: '',
      persist_disk: true
    });

    const addMnemonic = () => {
      if (mnemonic.name && mnemonic.mnemonic) {
        emit('add', { ...mnemonic });
        
        // Reset form
        mnemonic.name = '';
        mnemonic.mnemonic = '';
        mnemonic.passphrase = '';
        mnemonic.persist_disk = true;
      }
    };

    return {
      mnemonic,
      addMnemonic
    };
  }
});
</script>

<style scoped>
.modal {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  border: 1px solid #343a40;
}

.form-control {
  border: 1px solid #343a40;
}

.form-control:focus {
  border-color: #ffc107;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.25);
}
</style>
