<template>
  <div class="wallet-connection header-font-size">
    <!-- Show wallet address if connected -->
    <div v-if="isWalletConnected && walletAddress" class="wallet-info dropdown">
      <button class="btn btn-outline-light dropdown-toggle" type="button" id="walletDropdown" aria-expanded="false">
        <img v-if="walletType" :src="require(`@/assets/${walletTypeIcon}`)" :alt="walletType" class="wallet-icon-img">
        {{ formatWalletAddress }}
      </button>
      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="walletDropdown">
        <li><a class="dropdown-item" @click="copyAddress">Copy Address</a></li>
        <li><a class="dropdown-item" @click="disconnectWallet">Disconnect</a></li>
      </ul>
    </div>
    
    <!-- Show connect button if not connected -->
    <button v-else class="btn btn-gold" @click="connectWallet" :disabled="isConnecting">
      {{ isConnecting ? 'Connecting...' : 'Connect Wallet' }}
    </button>
  </div>
</template>

<script>
import { defineComponent, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { connectWallet, disconnectWallet, forceReconnectWallet } from '../../utils/wallet-connect';

export default defineComponent({
  name: 'WalletConnection',
  props: {
    isExplorerRoute: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const isConnecting = ref(false);
    
    const isWalletConnected = computed(() => store.getters.isWalletConnected);
    const walletAddress = computed(() => store.getters.getWalletAddress);
    const walletType = computed(() => store.getters.getWalletType);
    
    // Get the appropriate wallet icon based on wallet type
    const walletTypeIcon = computed(() => {
      switch(walletType.value) {
        case 'metamask':
          return 'metamask-icon.svg';
        case 'phantom':
          return 'phantom-icon.svg';
        case 'walletconnect':
          return 'walletconnect-icon.svg';
        default:
          return '';
      }
    });
    
    // Format wallet address to show only first 6 and last 4 characters
    const formatWalletAddress = computed(() => {
      if (!walletAddress.value) return '';
      return `${walletAddress.value.substring(0, 6)}...${walletAddress.value.substring(walletAddress.value.length - 4)}`;
    });
    
    const handleConnectWallet = async () => {
      // If we're already on the connect wallet screen, don't show the popup
      if (router.currentRoute.value.path === '/app/connect') {
        return;
      }
      
      isConnecting.value = true;
      
      try {
        const result = await connectWallet();
        
        // If the user cancelled, just return
        if (result && result.cancelled) {
          return;
        }
        
        // If we're on the connect wallet screen, redirect to app
        if (router.currentRoute.value.path === '/app/connect') {
          router.push('/app');
        }
      } catch (err) {
        console.error('Wallet connection error:', err);
      } finally {
        isConnecting.value = false;
      }
    };
    
    const handleDisconnectWallet = async () => {
      try {
        await disconnectWallet();
        // Redirect to the wallet connect page after disconnecting
        if (router.currentRoute.value.path.startsWith('/app')) {
          router.push('/app/connect');
        }
      } catch (err) {
        console.error('Wallet disconnection error:', err);
      }
    };
    
    const handleSwitchWallet = async () => {
      try {
        // Force a wallet reconnection to allow switching accounts
        await forceReconnectWallet();
      } catch (err) {
        console.error('Wallet switch error:', err);
      }
    };
    
    const handleRefreshAccount = async () => {
      try {
        console.log('Refreshing wallet account...');
        const walletType = store.getters.getWalletType;
        
        if (walletType === 'metamask' && window.ethereum) {
          // Force request the current accounts from MetaMask
          const accounts = await window.ethereum.request({ 
            method: 'eth_requestAccounts',
            params: []
          });
          
          if (accounts && accounts.length > 0) {
            console.log('Current MetaMask account:', accounts[0]);
            // Update the store with the current account
            store.commit('setWalletAddress', accounts[0]);
            
            // Re-establish event listeners to ensure they're working
            if (typeof window.setupMetaMaskListeners === 'function') {
              window.setupMetaMaskListeners();
            }
          } else {
            console.log('No accounts found in MetaMask');
          }
        } else if (walletType === 'phantom' && window.solana?.isPhantom) {
          try {
            // Get the current public key from Phantom
            const response = await window.solana.connect();
            const publicKey = response.publicKey.toString();
            
            if (publicKey) {
              console.log('Current Phantom account:', publicKey);
              // Update the store with the current public key
              store.commit('setWalletAddress', publicKey);
            } else {
              console.log('No public key found in Phantom');
            }
          } catch (error) {
            console.error('Error refreshing Phantom account:', error);
          }
        } else if (walletType === 'walletconnect' && window.walletProvider) {
          // Get the current accounts from WalletConnect
          const accounts = await window.walletProvider.request({ method: 'eth_accounts' });
          
          if (accounts && accounts.length > 0) {
            console.log('Current WalletConnect account:', accounts[0]);
            // Update the store with the current account
            store.commit('setWalletAddress', accounts[0]);
          } else {
            console.log('No accounts found in WalletConnect');
          }
        }
      } catch (err) {
        console.error('Account refresh error:', err);
      }
    };
    
    const copyAddress = () => {
      if (walletAddress.value) {
        navigator.clipboard.writeText(walletAddress.value)
          .then(() => {
            // Could add a toast notification here
            console.log('Address copied to clipboard');
          })
          .catch(err => {
            console.error('Failed to copy address:', err);
          });
      }
    };

    return {
      isWalletConnected,
      walletAddress,
      walletType,
      walletTypeIcon,
      formatWalletAddress,
      isConnecting,
      connectWallet: handleConnectWallet,
      disconnectWallet: handleDisconnectWallet,
      switchWallet: handleSwitchWallet,
      refreshAccount: handleRefreshAccount,
      copyAddress
    };
  }
});
</script>

<style scoped>
.wallet-connection {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 10px;
}


.wallet-info {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0;
  height: 40px;
}

.wallet-icon-img {
  width: 18px;
  height: 18px;
  margin-right: 5px;
  vertical-align: middle;
}

.btn {
  padding: 0.5rem 0.7rem;
  line-height: 1.4;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  height: 40px;
  box-sizing: border-box;
  vertical-align: middle;
}

.btn-outline-light {
  border-color: #444;
  color: #fff;
}

.btn-outline-light:hover {
  background-color: #333;
  color: #fff;
  border-color: #555;
}

.btn-gold {
  background-color: #8b6914;
  color: #fff;
  border: 1px solid #7a5c11;
}

.btn-gold:hover {
  background-color: #7a5c11;
  color: #fff;
  border-color: #69500e;
}

.btn-gold:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.dropdown-menu {
  background-color: #1e1e1e;
  border: 1px solid #333;
  min-width: 6rem;
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  padding: 0;
  margin: 0;
  z-index: 20000;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu-end {
  left: auto;
  right: 0;
}

.dropdown-item {
  color: #fff;
  padding: 0.4rem 0.8rem;
  cursor: pointer;
  line-height: 1.4;
  display: flex;
  align-items: center;
}

.dropdown-item:hover, .dropdown-item:focus {
  background-color: #333;
  color: #fff;
}
</style>
