<template>
  <div class="transaction-confirm">
    <div v-if="status === 'ready'" class="text-center">
      <div class="success-icon mb-3">
        <i class="bi bi-check-circle-fill text-success" style="font-size: 3rem;"></i>
      </div>
      <h5 class="mb-3">Transaction Signed Successfully!</h5>
      <p>Your transaction has been signed and is ready to broadcast.</p>
      <p>Click the "Broadcast Transaction" button below to send it to the network.</p>
    </div>

    <div v-else-if="status === 'pending'" class="text-center">
      <div class="spinner-border text-primary mb-3" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <p>Broadcasting transaction to the network...</p>
    </div>
    
    <div v-else-if="status === 'success'" class="text-center">
      <div class="success-icon mb-3">
        <i class="bi bi-check-circle-fill text-success" style="font-size: 3rem;"></i>
      </div>
      <h5 class="mb-3">Transaction Submitted Successfully!</h5>
      <p>Your transaction has been submitted to the network and is awaiting confirmation.</p>
      
      <div class="transaction-hash mt-3 mb-3">
        <div class="form-label">Transaction Hash:</div>
        <div class="hash-preview p-2 bg-dark text-light rounded d-flex justify-content-between">
          <span>{{ transactionHash }}</span>
          <button class="btn btn-sm btn-outline-light" @click="copyHash">
            <i class="bi bi-clipboard"></i>
          </button>
        </div>
      </div>
      
      <p class="text-muted">
        The transaction will be confirmed in approximately {{ confirmationTime }}.
      </p>
    </div>
    
    <div v-else-if="status === 'error'" class="text-center">
      <div class="error-icon mb-3">
        <i class="bi bi-x-circle-fill text-danger" style="font-size: 3rem;"></i>
      </div>
      <h5 class="mb-3">Transaction Failed</h5>
      <p>There was an error broadcasting your transaction:</p>
      <div class="alert alert-danger">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TransactionConfirm',
  props: {
    status: {
      type: String,
      default: 'pending',
      validator: (value) => ['ready', 'pending', 'success', 'error'].includes(value)
    },
    transactionHash: {
      type: String,
      default: ''
    },
    confirmationTime: {
      type: String,
      default: 'a few minutes'
    },
    error: {
      type: String,
      default: ''
    }
  },
  emits: ['copy-hash'],
  setup(props, { emit }) {
    const copyHash = () => {
      emit('copy-hash');
    };
    
    return {
      copyHash
    };
  }
});
</script>

<style scoped>
.hash-preview {
  font-family: monospace;
  word-break: break-all;
}

.text-primary {
  color: #b8860b !important;
}

.spinner-border.text-primary {
  border-color: #b8860b !important;
  border-right-color: transparent !important;
}
</style>
