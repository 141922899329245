/**
 * Rust-based WebSocket client for Redgold API
 * Uses the Rust WASM implementation for WebSocket functionality
 */

import { store } from '../store';

class RustWebSocketClient {
  constructor() {
    this.isConnected = false;
    this.url = null;
    this.reconnectAttempts = 0;
    this.maxReconnectAttempts = 5;
    this.reconnectDelay = 2000; // 2 seconds
    this.wsState = null;
    this.pendingSubscribe = false; // Flag to indicate a pending subscribe message
  }

  /**
   * Try to get wallet balance with retries
   * @param {string} walletAddress - The wallet address to get balance for
   * @param {string} context - Context string for logging
   * @returns {Promise<void>}
   */
  async tryGetBalanceWithRetry(walletAddress, context = '') {
    let retryCount = 0;
    const maxRetries = 50; // 5 seconds total (50 * 100ms)
    
    const tryGetBalance = () => {
      if (window.redgoldWasm && window.redgoldWasm.get_data_state_balance_for_address) {
        try {
          const balanceRdg = window.redgoldWasm.get_data_state_balance_for_address(walletAddress);
          if (balanceRdg !== undefined) {
            const balanceUsd = parseFloat(balanceRdg) * 100;
            store.commit('setWalletBalance', balanceUsd);
          }
        } catch (error) {
          console.error('Error getting wallet balance:', error);
        }
      } else {
        // If WASM function not available yet, retry after a short delay
        if (retryCount < maxRetries) {
          console.log(`WASM balance function not available yet${context ? ' in ' + context : ''}, retrying... (${retryCount + 1}/${maxRetries})`);
          retryCount++;
          setTimeout(tryGetBalance, 100);
        } else {
          console.error(`Max retries reached waiting for WASM balance function${context ? ' in ' + context : ''}`);
        }
      }
    };
    tryGetBalance();
  }

  /**
   * Initialize the WebSocket client
   * This should be called after the WASM module is loaded
   */
  initialize() {
    if (!window.redgoldWasm) {
      console.error('Cannot initialize WebSocket client: WASM module not loaded');
      return false;
    }

    // Check if the required WebSocket functions are available
    if (!window.redgoldWasm.ws_connect ||
        !window.redgoldWasm.ws_disconnect ||
        !window.redgoldWasm.ws_send ||
        !window.redgoldWasm.ws_is_connected) {
      console.error('WebSocket functions not available in WASM module');
      return false;
    }

    // Create a WebSocketState instance to track state
    if (window.redgoldWasm.WebSocketState) {
      this.wsState = new window.redgoldWasm.WebSocketState();
    }

    return true;
  }

  /**
   * Connect to the WebSocket server
   */
  connect() {
    if (!window.redgoldWasm) {
      console.error('Cannot connect: WASM module not loaded');
      return;
    }

    // Check if already connected
    if (window.redgoldWasm.ws_is_connected && window.redgoldWasm.ws_is_connected()) {
      console.log('WebSocket is already connected');
      return;
    }

    // Get the WebSocket URL
    let wsUrl;
    try {
      if (window.redgoldWasm.ws_get_url) {
        wsUrl = window.redgoldWasm.ws_get_url();
      } else {
        wsUrl = this.getWebSocketUrl();
      }
      
      // Ensure URL is a string
      if (typeof wsUrl !== 'string') {
        console.error('WebSocket URL is not a string:', wsUrl);
        wsUrl = this.getWebSocketUrl(); // Fallback to our own URL generation
      }
      
      this.url = wsUrl;
      console.log(`Connecting to WebSocket at ${this.url}`);
    } catch (error) {
      console.error('Error getting WebSocket URL:', error);
      this.url = this.getWebSocketUrl(); // Fallback to our own URL generation
      console.log(`Falling back to default WebSocket URL: ${this.url}`);
    }
    
    try {
      // Set up callbacks before connecting
      this.setupCallbacks();
      
      // Connect to the WebSocket server - ensure URL is a string
      if (!this.url || typeof this.url !== 'string') {
        throw new Error('Invalid WebSocket URL: ' + this.url);
      }
      
      const result = window.redgoldWasm.ws_connect(this.url);
      if (!result) {
        console.error('Failed to connect to WebSocket server');
      }
    } catch (error) {
      console.error('Error creating WebSocket connection:', error);
      
      // Store error in Vuex store if needed
      if (store && store.commit) {
        store.commit('setWebSocketError', error.message || 'Unknown WebSocket error');
      }
    }
  }

  /**
   * Set up WebSocket callbacks
   */
  setupCallbacks() {
    if (!window.redgoldWasm) {
      console.error('Cannot set up callbacks: WASM module not loaded');
      return;
    }

    try {
      // Set up onopen callback
      if (window.redgoldWasm.ws_set_on_open) {
        window.redgoldWasm.ws_set_on_open(this.handleOpen.bind(this));
      } else {
        console.error('Cannot set onopen callback: ws_set_on_open function not available');
      }
      
      // Set up onmessage callback
      if (window.redgoldWasm.ws_set_on_message) {
        window.redgoldWasm.ws_set_on_message(this.handleMessage.bind(this));
      } else {
        console.error('Cannot set onmessage callback: ws_set_on_message function not available');
      }
      
      // Set up onclose callback
      if (window.redgoldWasm.ws_set_on_close) {
        window.redgoldWasm.ws_set_on_close(this.handleClose.bind(this));
      } else {
        console.error('Cannot set onclose callback: ws_set_on_close function not available');
      }
      
      // Set up onerror callback
      if (window.redgoldWasm.ws_set_on_error) {
        window.redgoldWasm.ws_set_on_error(this.handleError.bind(this));
      } else {
        console.error('Cannot set onerror callback: ws_set_on_error function not available');
      }
    } catch (error) {
      console.error('Error setting up WebSocket callbacks:', error);
    }
  }

  /**
   * Get the appropriate WebSocket URL based on the current hostname
   * @returns {string} WebSocket URL
   */
  getWebSocketUrl() {
    let baseUrl = "wss://api.redgold.io";
    const hostname = window.location.hostname;

    if (hostname.includes('dev') || hostname.includes('localhost')) {
      baseUrl = "wss://dev.api.redgold.io";
    } else if (hostname.includes('staging')) {
      baseUrl = "wss://staging.api.redgold.io";
    } else if (hostname.includes('test')) {
      baseUrl = "wss://test.api.redgold.io";
    }

    return `${baseUrl}/v1/ws`;
  }

  /**
   * Handle WebSocket open event
   */
  handleOpen() {
    this.isConnected = true;
    this.reconnectAttempts = 0;
    
    // Update WebSocketState if available
    if (this.wsState) {
      this.wsState.set_connected(true);
    }
    
    // Store connection status in Vuex store
    if (store && store.commit) {
      store.commit('setWebSocketConnected', true);
    }
    
    // Add a delay to ensure the WebSocket is fully ready before sending any messages
    setTimeout(() => {
      // Send subscribe message if there's a pending one
      if (this.pendingSubscribe) {
        this.sendSubscribeMessage();
        this.pendingSubscribe = false;
      }
      
      // Check if wallet is initialized and connected
      if (store && store.getters.isWalletInitialized) {
        // If wallet is connected or config has saved addresses, send subscribe message
        const configData = store.getters.getConfigData;
        const walletAddress = store.getters.getWalletAddress;
        
        if (walletAddress || (configData && configData.local && configData.local.saved_addresses && configData.local.saved_addresses.length > 0)) {
          this.sendSubscribeMessage();
          
          // Update balance immediately after connecting
          this.tryGetBalanceWithRetry(walletAddress, 'handleOpen');
        }
      } else {
        // Set flag to check again when wallet is initialized
        this.pendingSubscribe = true;
      }
    }, 500); // 500ms delay to ensure WebSocket is fully established
  }
  
  /**
   * Send a subscribe message to the server
   */
  sendSubscribeMessage() {
    if (!window.redgoldWasm || !window.redgoldWasm.ws_send) {
      console.warn('Cannot send subscribe message: WASM module or ws_send function not available');
      this.pendingSubscribe = true; // Set flag to send message when WASM is available
      return;
    }
    
    // Check if WebSocket is connected before attempting to send
    if (!window.redgoldWasm.ws_is_connected || !window.redgoldWasm.ws_is_connected()) {
      this.pendingSubscribe = true; // Set flag to send message when connected
      
      // Retry after a delay to ensure connection is fully established
      setTimeout(() => {
        if (window.redgoldWasm.ws_is_connected && window.redgoldWasm.ws_is_connected()) {
          this.sendSubscribeMessage();
          this.pendingSubscribe = false;
        } else {
          console.warn('WebSocket still not connected after delay');
        }
      }, 1000); // 1 second delay
      
      return;
    }
    
    try {
      // Get config data from store
      const configData = store.getters.getConfigData;
      
      // Serialize configData to JSON
      const configDataStr = JSON.stringify(configData || {});
      
      // Get wallet address from store
      const walletAddress = store.getters.getWalletAddress;
      
      // Create subscribe message using WASM function
      if (window.redgoldWasm.create_and_send_subscribe_message) {
        // Pass only the raw serialized config and wallet address to Rust
        // Let Rust handle parsing the config and constructing the Subscribe message
        window.redgoldWasm.create_and_send_subscribe_message(
          configDataStr,
          walletAddress || ""
        );
        
        // console.log('Sending subscribe message to WebSocket server with config data and wallet address');
      } else {
        console.warn('create_subscribe_message_from_config function not available');
        
        // Fallback to sending a basic JSON message if the WASM function is not available
        const fallbackMessage = JSON.stringify({
          subscribe: {
            is_ui: true,
            addresses: walletAddress ? [{ address: walletAddress }] : []
          }
        });
        
        console.log('Sending fallback subscribe message to WebSocket server');
        window.redgoldWasm.ws_send(fallbackMessage);
      }
    } catch (error) {
      console.error('Error sending subscribe message:', error);
    }
  }
  
  /**
   * Update WebSocket with config data and wallet address
   * This should be called when the wallet/config is first loaded
   */
  updateWithConfigAndWalletAddress() {
    console.log('Updating WebSocket with config data and wallet address');
    
    if (!window.redgoldWasm) {
      console.error('Cannot update WebSocket: WASM module not loaded');
      this.pendingSubscribe = true; // Set flag to send message when WASM is available
      return false;
    }
    
    // Try to get initial balance regardless of websocket connection
    const walletAddress = store.getters.getWalletAddress;
    if (walletAddress) {
      this.tryGetBalanceWithRetry(walletAddress, 'updateWithConfigAndWalletAddress');
    }

    // Check if the WebSocket is connected
    const isConnected = this.isWebSocketConnected();
    
    if (isConnected) {
      // If connected, add a small delay before sending subscribe message
      // to ensure the connection is fully established
      console.log('WebSocket is connected, sending subscribe message with delay');
      setTimeout(() => {
        this.sendSubscribeMessage();
      }, 500);
    } else {
      // If not connected, set flag to send message when connected
      console.log('WebSocket is not connected, will send subscribe message when connected');
      this.pendingSubscribe = true;
      
      // Try to connect if not already connected
      if (!this.isConnected) {
        console.log('Attempting to connect WebSocket...');
        this.connect();
      }
    }
    
    return true;
  }

  /**
   * Handle WebSocket message event
   * @param {string} jsonData - The JSON data received from the WebSocket
   */
  handleMessage(jsonData) {
    try {
      // console.log('WEBSOCKET RAW MESSAGE:', jsonData);

      // Parse the JSON data
      const data = JSON.parse(jsonData);
      
      // Update WebSocketState if available
      if (this.wsState) {
        this.wsState.set_last_message_size(jsonData.length);
      }
      
      // Store the message size in Vuex store
      if (store && store.commit) {
        store.commit('setLastWebSocketMessageSize', jsonData.length);
      }
      
      // Process the message data
      this.processMessageData(data);
      
    } catch (error) {
      console.error('Error handling WebSocket message:', error);
    }
  }
  
  /**
   * Process the message data and update the store accordingly
   * @param {Object} data - The parsed message data
   */
  processMessageData(data) {
    if (!data || !store) {
      return;
    }

    try {
      // Check if the message contains address info
      if (data.addressInfo && Array.isArray(data.addressInfo)) {
        this.processAddressInfo(data.addressInfo);
      }

      // Handle other message types as needed
      
    } catch (error) {
      console.error('Error processing message data:', error);
    }
  }
  
  /**
   * Process address info from the message and update wallet balance
   * @param {Array} addressInfoArray - Array of address info objects
   */
  processAddressInfo(addressInfoArray) {
    if (!addressInfoArray || !Array.isArray(addressInfoArray) || !store) {
      return;
    }

    try {
      let totalBalance = 0;

      // Calculate total Redgold balance across all addresses
      for (const addressInfo of addressInfoArray) {
        if (addressInfo && typeof addressInfo.balance === 'number') {
          totalBalance += addressInfo.balance;
        }
      }

      console.log('Total Redgold wallet balance:', totalBalance);

      // Convert RDG to USD (multiply by 100 as specified)
      const balanceUsd = parseFloat(totalBalance) * 100;
      
      // Update the wallet balance in the store
      store.commit('setWalletBalance', balanceUsd);

    } catch (error) {
      console.error('Error processing address info:', error);
    }
  }

  /**
   * Handle WebSocket close event
   * @param {number} code - The close code
   * @param {string} reason - The close reason
   * @param {boolean} wasClean - Whether the connection was closed cleanly
   */
  handleClose(code, reason, wasClean) {
    console.log(`WebSocket connection closed: ${code} ${reason}`);
    this.isConnected = false;
    
    // Update WebSocketState if available
    if (this.wsState) {
      this.wsState.set_connected(false);
    }
    
    // Store connection status in Vuex store
    if (store && store.commit) {
      store.commit('setWebSocketConnected', false);
    }
    
    // Attempt to reconnect if not closed cleanly and we haven't exceeded max attempts
    if (!wasClean && this.reconnectAttempts < this.maxReconnectAttempts) {
      this.reconnectAttempts++;
      console.log(`Attempting to reconnect (${this.reconnectAttempts}/${this.maxReconnectAttempts})...`);
      setTimeout(() => this.connect(), this.reconnectDelay);
    }
  }

  /**
   * Handle WebSocket error event
   * @param {string} errorMessage - The error message
   */
  handleError(errorMessage) {
    console.error('WebSocket error:', errorMessage);
    
    // Update WebSocketState if available
    if (this.wsState) {
      this.wsState.set_error(errorMessage);
    }
    
    // Store error in Vuex store
    if (store && store.commit) {
      store.commit('setWebSocketError', errorMessage || 'Unknown WebSocket error');
    }
  }

  /**
   * Disconnect from the WebSocket server
   */
  disconnect() {
    if (!window.redgoldWasm || !window.redgoldWasm.ws_disconnect) {
      console.warn('Cannot disconnect: WASM module or ws_disconnect function not available');
      return;
    }
    
    console.log('Closing WebSocket connection');
    window.redgoldWasm.ws_disconnect();
    this.isConnected = false;
    
    // Update WebSocketState if available
    if (this.wsState) {
      this.wsState.set_connected(false);
    }
    
    // Store connection status in Vuex store
    if (store && store.commit) {
      store.commit('setWebSocketConnected', false);
    }
  }

  /**
   * Check if the WebSocket is connected
   * @returns {boolean} Whether the WebSocket is connected
   */
  isWebSocketConnected() {
    if (!window.redgoldWasm || !window.redgoldWasm.ws_is_connected) {
      return false;
    }
    
    return window.redgoldWasm.ws_is_connected();
  }
}

// Create a singleton instance
const rustWebSocketClient = new RustWebSocketClient();

export default rustWebSocketClient;
