// Basic utility functions for the app

/**
 * Format a number as currency
 * @param {number} value - The value to format
 * @param {string} currency - The currency code (default: 'USD')
 * @returns {string} Formatted currency string
 */
export function formatCurrency(value, currency = 'USD') {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency
  }).format(value);
}

/**
 * Format a number as currency with abbreviated suffixes (k, m)
 * @param {number} value - The value to format
 * @param {string} currency - The currency code (default: 'USD')
 * @returns {string} Formatted currency string with abbreviated suffix
 */
export function formatCurrencyAbbreviated(value, currency = 'USD') {
  if (!value && value !== 0) return getCurrencySymbol(currency) + '0';
  
  // Get the appropriate currency symbol
  const symbol = getCurrencySymbol(currency);
  
  // For values less than 1000, just show the whole number
  if (value < 1000) {
    return `${symbol}${Math.floor(value)}`;
  }
  
  // For values in thousands (1,000 - 999,999)
  if (value < 1000000) {
    return `${symbol}${(value / 1000).toFixed(2)}k`.replace(/\.00/, '');
  }
  
  // For values in millions (1,000,000+)
  return `${symbol}${(value / 1000000).toFixed(2)}m`.replace(/\.00/, '');
}

/**
 * Get currency symbol for a given currency code
 * @param {string} currency - The currency code
 * @returns {string} Currency symbol
 */
function getCurrencySymbol(currency = 'USD') {
  const symbols = {
    'USD': '$',
    'EUR': '€',
    'GBP': '£',
    'JPY': '¥',
    'CNY': '¥',
    'BTC': '₿',
    'ETH': 'Ξ',
    "RDG": 'Ⓡ'
  };
  
  return symbols[currency] || currency + ' ';
}

/**
 * Calculate the total value of a portfolio's assets
 * @param {Array} assets - Array of assets with currentValue property
 * @returns {number} Total portfolio value
 */
export function calculatePortfolioValue(assets) {
  if (!assets) return 0;
  return assets.reduce((total, asset) => total + (asset.currentValue || 0), 0);
}
