// wallet-connect.js
import EthereumProvider from '@walletconnect/ethereum-provider';
import { store } from '../store';
import { createApp, h } from 'vue';
import WalletSelectModal from '../components/WalletSelectModal.vue';

// Import WASM functions
import { redgoldWasm } from '../wasm/redgold_gui.js';

// Get the WalletConnect project ID from environment variables
// For development, you can set this in a .env file in the project root
// For production, set it in your deployment environment
const projectId = process.env.VUE_APP_WALLETCONNECT_PROJECT_ID || 
                 // Your project ID from WalletConnect Cloud
                 'your_project_id_here';

// Supported chains
const chains = [1, 11155111]; // Ethereum Mainnet and Sepolia

// Import the Rust WebSocket client
import rustWebSocketClient from './rust-websocket-client';

// Function to add wallet address to configData
const addWalletAddressToConfig = (address) => {
  try {
    // Get the existing config data directly from localStorage first
    let configData;
    try {
      const storedConfig = localStorage.getItem('configData');
      if (storedConfig) {
        configData = JSON.parse(storedConfig);
      }
    } catch (e) {
      console.error('Error parsing stored config:', e);
    }
    
    // If we couldn't get config from localStorage, fall back to store
    if (!configData) {
      configData = { ...store.getters.getConfigData };
    }
    
    // Initialize local if it doesn't exist
    if (!configData.local) {
      configData.local = {};
    }
    
    // Initialize saved_addresses if it doesn't exist
    if (!configData.local.saved_addresses) {
      configData.local.saved_addresses = [];
    }
    
    // Check if address already exists in saved_addresses
    const addressExists = configData.local.saved_addresses.some(
      savedAddr => savedAddr.address.toLowerCase() === address.toLowerCase()
    );
    
    // If address doesn't exist, add it
    if (!addressExists) {
      const newSavedAddress = {
        name: `Wallet ${configData.local.saved_addresses.length + 1}`,
        address: address,
        source: 'wallet_connect'
      };
      
      configData.local.saved_addresses.push(newSavedAddress);
      console.log('Added wallet address to config:', newSavedAddress);
    } else {
      // console.log('Wallet address already exists in config');
    }
    
    // Update config in store
    store.commit('setConfigData', configData);
    
    // Disable demo mode when wallet is connected
    store.commit('setIsDemoDisabled', true);
    
    // Update WebSocket with config data and wallet address
    if (window.redgoldWasm && jsWebSocketClient) {
      console.log('Updating WebSocket with config data and wallet address after wallet connection');
      jsWebSocketClient.updateWithConfigAndWalletAddress();
    }
    
    return true;
  } catch (error) {
    console.error('Error adding wallet address to config:', error);
    return false;
  }
};

// Function to save wallet connection state to localStorage
const saveWalletStateToLocalStorage = () => {
  const walletConnected = store.getters.isWalletConnected;
  const walletAddress = store.getters.getWalletAddress;
  const walletChainId = store.getters.getWalletChainId;
  const walletType = store.getters.getWalletType;
  
  if (walletConnected && walletAddress && walletType) {
    localStorage.setItem('walletConnected', 'true');
    localStorage.setItem('walletAddress', walletAddress);
    localStorage.setItem('walletChainId', walletChainId ? walletChainId.toString() : '');
    localStorage.setItem('walletType', walletType);
    console.log('Saved wallet state to localStorage:', { walletConnected, walletAddress, walletChainId, walletType });
    
    // Add wallet address to config and disable demo mode
    addWalletAddressToConfig(walletAddress);
  } else {
    // Clear localStorage if wallet is not connected
    localStorage.removeItem('walletConnected');
    localStorage.removeItem('walletAddress');
    localStorage.removeItem('walletChainId');
    localStorage.removeItem('walletType');
    console.log('Cleared wallet state from localStorage');
  }
};

// Function to restore wallet connection state from localStorage
const restoreWalletStateFromLocalStorage = async () => {
  const walletConnected = localStorage.getItem('walletConnected') === 'true';
  const walletSkipped = localStorage.getItem('walletSkipped') === 'true';
  const walletAddress = localStorage.getItem('walletAddress');
  const walletChainIdStr = localStorage.getItem('walletChainId');
  const walletType = localStorage.getItem('walletType');
  
  // Check if wallet is skipped
  if (walletSkipped) {
    // console.log('Restoring wallet skipped state from localStorage:', walletSkipped);
    store.commit('setWalletSkipped', true);
    store.commit('setWalletInitialized', true);
    return;
  } else {
    // console.log('Wallet skipped state not found in localStorage:', localStorage.getItem('walletSkipped'));
  }
  
  // Check if wallet is connected
  if (walletConnected && walletAddress && walletType) {
    // console.log('Restoring wallet connection state from localStorage:', { walletConnected, walletAddress, walletChainIdStr, walletType });
    
    // Convert chainId to number if it's not 'solana'
    let walletChainId = walletChainIdStr;
    if (walletChainIdStr && walletChainIdStr !== 'solana') {
      walletChainId = parseInt(walletChainIdStr, 10);
    }
    
    // Update the store
    store.commit('setWalletConnected', true);
    store.commit('setWalletAddress', walletAddress);
    store.commit('setWalletChainId', walletChainId);
    store.commit('setWalletType', walletType);
    
    // Add wallet address to config and disable demo mode
    addWalletAddressToConfig(walletAddress);
    
    // Attempt to reconnect to the wallet provider
    try {
      if (walletType === 'metamask' && isMetaMaskAvailable()) {
        // For MetaMask, set up event listeners
        window.walletProvider = window.ethereum;
        setupMetaMaskListeners();
        startMetaMaskPolling();
      } else if (walletType === 'phantom' && isPhantomAvailable()) {
        // For Phantom, set up event listeners
        window.walletProvider = window.solana;
        window.solana.on('disconnect', () => {
          disconnectWallet();
        });
        window.solana.on('accountChanged', async () => {
          try {
            const response = await window.solana.connect();
            const publicKey = response.publicKey.toString();
            if (publicKey) {
              store.commit('setWalletAddress', publicKey);
              console.log('Updated Phantom wallet address:', publicKey);
              saveWalletStateToLocalStorage();
            }
          } catch (error) {
            console.error('Error updating Phantom account:', error);
          }
        });
      } else if (walletType === 'walletconnect') {
        // For WalletConnect, we need to reinitialize the provider
        // This is more complex and might require user interaction
        console.log('WalletConnect reconnection requires user interaction, skipping automatic reconnect');
      }
    } catch (error) {
      console.error('Error reconnecting to wallet provider:', error);
      // If reconnection fails, clear the wallet state
      disconnectWallet();
    }
  } else {
    console.log('No wallet state found in localStorage or incomplete data');
  }
  
  // Mark wallet initialization as complete
  store.commit('setWalletInitialized', true);
  
  // Update WebSocket with config data and wallet address after initialization
  if (window.redgoldWasm && jsWebSocketClient && walletConnected && walletAddress) {
    console.log('Updating WebSocket with config data and wallet address after initialization');
    jsWebSocketClient.updateWithConfigAndWalletAddress();
  }
};

// Function to check if wallet initialization is complete
export const isWalletInitialized = () => {
  return store.getters.isWalletInitialized;
};

// Function to show wallet selection modal
const showWalletSelectionModal = () => {
  return new Promise((resolve) => {
    // Create a div to mount the modal
    const modalContainer = document.createElement('div');
    modalContainer.style.position = 'fixed';
    modalContainer.style.top = '0';
    modalContainer.style.left = '0';
    modalContainer.style.width = '100%';
    modalContainer.style.height = '100%';
    modalContainer.style.zIndex = '9999';
    document.body.appendChild(modalContainer);
    
    // Create the modal app
    const modalApp = createApp({
      render() {
        return h(WalletSelectModal, {
          isVisible: true,
          onClose: () => {
            // User closed the modal without selecting a wallet
            console.log('Modal closed without selection');
            modalApp.unmount();
            document.body.removeChild(modalContainer);
            resolve({ cancelled: true });
          },
          onSelect: (walletId) => {
            // User selected a wallet
            console.log('Wallet selected in modal:', walletId);
            
            // Check wallet availability before resolving
            if (walletId === 'metamask' && !isMetaMaskAvailable()) {
              console.error('MetaMask not available but was selected');
              return;
            }
            
            if (walletId === 'phantom' && !isPhantomAvailable()) {
              console.error('Phantom not available but was selected');
              return;
            }
            
            modalApp.unmount();
            document.body.removeChild(modalContainer);
            resolve({ walletId });
          }
        });
      }
    });
    
    // Mount the app to the container
    modalApp.mount(modalContainer);
    console.log('Wallet selection modal mounted');
  });
};

// Function to check if MetaMask is available
const isMetaMaskAvailable = () => {
  return typeof window.ethereum !== 'undefined';
};

// Function to check if Phantom is available
const isPhantomAvailable = () => {
  return typeof window.solana !== 'undefined' && window.solana.isPhantom;
};

// Function to set up MetaMask event listeners
const setupMetaMaskListeners = () => {
  if (window.ethereum) {
    // console.log('Setting up MetaMask event listeners...');
    
    // Remove any existing listeners first to avoid duplicates
    window.ethereum.removeAllListeners('accountsChanged');
    window.ethereum.removeAllListeners('chainChanged');
    window.ethereum.removeAllListeners('disconnect');
    
    // Add the accountsChanged listener with debug logging
    window.ethereum.on('accountsChanged', (newAccounts) => {
      console.log('MetaMask accountsChanged event triggered:', newAccounts);
      if (newAccounts.length === 0) {
        // User disconnected
        console.log('No accounts found, disconnecting wallet');
        disconnectWallet();
      } else {
        console.log('Updating wallet address to:', newAccounts[0]);
        store.commit('setWalletAddress', newAccounts[0]);
        saveWalletStateToLocalStorage();
      }
    });
    
    // Add the chainChanged listener with debug logging
    window.ethereum.on('chainChanged', (newChainId) => {
      console.log('MetaMask chainChanged event triggered:', newChainId);
      store.commit('setWalletChainId', parseInt(newChainId, 16));
      saveWalletStateToLocalStorage();
    });
    
    // Add the disconnect listener with debug logging
    window.ethereum.on('disconnect', (error) => {
      console.log('MetaMask disconnect event triggered', error);
      disconnectWallet();
    });
    
    // Test the event listeners by logging a message
    // console.log('MetaMask event listeners set up successfully');
    
    // Verify the listeners are attached
    const listeners = window.ethereum.listeners('accountsChanged');
    // console.log('Number of accountsChanged listeners:', listeners.length);
  } else {
    console.warn('Cannot set up MetaMask listeners: window.ethereum is not available');
  }
};

// Expose the setupMetaMaskListeners function globally so it can be accessed from other components
window.setupMetaMaskListeners = setupMetaMaskListeners;

// Function to check and update the current MetaMask account
const checkCurrentMetaMaskAccount = async () => {
  if (store.getters.getWalletType === 'metamask' && window.ethereum) {
    try {
      // console.log('Polling: Checking current MetaMask account...');
      
      // Force a fresh request to get the current accounts
      // This is different from eth_accounts which might use cached results
      const accounts = await window.ethereum.request({ 
        method: 'eth_requestAccounts',
        params: []
      });
      
      // console.log('Polling: Current accounts from eth_requestAccounts:', accounts);
      
      if (accounts && accounts.length > 0) {
        const currentAccount = accounts[0];
        const storeAccount = store.getters.getWalletAddress;
        
        // console.log('Polling: Comparing accounts - Current:', currentAccount, 'Stored:', storeAccount);
        
        if (currentAccount.toLowerCase() !== storeAccount.toLowerCase()) {
          console.log('Detected account change from polling:', storeAccount, 'to:', currentAccount);
          store.commit('setWalletAddress', currentAccount);
          saveWalletStateToLocalStorage();
          
          // Re-establish event listeners to ensure they're working
          setupMetaMaskListeners();
        }
      } else if (accounts.length === 0 && store.getters.isWalletConnected) {
        // User disconnected their wallet
        console.log('No accounts found, disconnecting wallet');
        disconnectWallet();
      }
    } catch (error) {
      console.error('Error checking current MetaMask account:', error);
    }
  }
};

// Expose the checkCurrentMetaMaskAccount function globally so it can be accessed from other components
window.checkCurrentMetaMaskAccount = checkCurrentMetaMaskAccount;

// Import the aggressive MetaMask reset functions
import { forceMetaMaskPermissionDialog } from './metamask-reset';

// Function to connect to MetaMask
export const connectMetamask = async () => {
  try {
    console.log('Connecting to MetaMask...');
    
    // First, disconnect any existing wallet connection to ensure a fresh start
    await disconnectWallet();
    
    // Clear any localStorage items related to wallet connection
    localStorage.removeItem('walletConnected');
    localStorage.removeItem('walletAddress');
    localStorage.removeItem('walletChainId');
    localStorage.removeItem('walletType');
    localStorage.removeItem('walletSkipped');
    localStorage.removeItem('walletSkipped');
    
    // Clear any sessionStorage items as well
    sessionStorage.removeItem('walletConnected');
    sessionStorage.removeItem('walletAddress');
    sessionStorage.removeItem('walletChainId');
    sessionStorage.removeItem('walletType');
    
    console.log('Cleared previous wallet connection data');
    
    // Check if MetaMask is available
    if (!isMetaMaskAvailable()) {
      return { 
        success: false, 
        error: 'MetaMask is not installed or available in your browser.' 
      };
    }
    
    // Use our aggressive approach to force MetaMask to show the permission dialog
    console.log('Using aggressive approach to force MetaMask permission dialog...');
    const accounts = await forceMetaMaskPermissionDialog();
    
    const chainId = await window.ethereum.request({ method: 'eth_chainId' });
    
    if (accounts && accounts.length > 0) {
      console.log('MetaMask connected successfully with account:', accounts[0]);
      
      // Update the store
      store.commit('setWalletConnected', true);
      store.commit('setWalletAddress', accounts[0]);
      store.commit('setWalletChainId', parseInt(chainId, 16));
      store.commit('setWalletType', 'metamask');
      
      // Save wallet state to localStorage
      saveWalletStateToLocalStorage();
      
      // Set up event listeners
      setupMetaMaskListeners();
      
      // Store the provider for later use
      window.walletProvider = window.ethereum;
      
      // Start polling for account changes
      startMetaMaskPolling();
      
      return {
        success: true,
        address: accounts[0],
        chainId: parseInt(chainId, 16),
        walletType: 'metamask'
      };
    }
    
    return { success: false };
  } catch (error) {
    console.error('Error connecting to MetaMask:', error);
    return { 
      success: false, 
      error: error.message 
    };
  }
};

// Variable to store the polling interval
let metaMaskPollingInterval = null;

// Function to start polling for MetaMask account changes
const startMetaMaskPolling = () => {
  // Clear any existing interval first
  if (metaMaskPollingInterval) {
    clearInterval(metaMaskPollingInterval);
  }
  
  // Set up a new interval with more frequent checks
  metaMaskPollingInterval = setInterval(async () => {
    await checkCurrentMetaMaskAccount();
  }, 1000); // Check every 1 second (more frequent than before)
  
  // console.log('Started MetaMask account polling');
};

// Function to stop polling for MetaMask account changes
const stopMetaMaskPolling = () => {
  if (metaMaskPollingInterval) {
    clearInterval(metaMaskPollingInterval);
    metaMaskPollingInterval = null;
    console.log('Stopped MetaMask account polling');
  }
};

// Function to connect to Phantom
const connectPhantom = async () => {
  try {
    console.log('Connecting to Phantom...');
    
    // Check if Phantom is available
    if (!isPhantomAvailable()) {
      return { 
        success: false, 
        error: 'Phantom is not installed or available in your browser.' 
      };
    }
    
    // Connect to Phantom
    const response = await window.solana.connect();
    const publicKey = response.publicKey.toString();
    
    if (publicKey) {
      // Update the store
      store.commit('setWalletConnected', true);
      store.commit('setWalletAddress', publicKey);
      store.commit('setWalletChainId', 'solana'); // Using string for Solana
      store.commit('setWalletType', 'phantom');
      
      // Save wallet state to localStorage
      saveWalletStateToLocalStorage();
      
      // Set up event listeners
      window.solana.on('disconnect', () => {
        disconnectWallet();
      });
      
      window.solana.on('accountChanged', async () => {
        // Mirror the active account change instead of disconnecting
        try {
          const response = await window.solana.connect();
          const publicKey = response.publicKey.toString();
          if (publicKey) {
            store.commit('setWalletAddress', publicKey);
            console.log('Updated Phantom wallet address:', publicKey);
            saveWalletStateToLocalStorage();
          }
        } catch (error) {
          console.error('Error updating Phantom account:', error);
        }
      });
      
      // Store the provider for later use
      window.walletProvider = window.solana;
      
      return {
        success: true,
        address: publicKey,
        chainId: 'solana',
        walletType: 'phantom'
      };
    }
    
    return { success: false };
  } catch (error) {
    console.error('Error connecting to Phantom:', error);
    return { 
      success: false, 
      error: error.message 
    };
  }
};

// Function to connect to WalletConnect
const connectWalletConnect = async () => {
  try {
    console.log('Connecting to WalletConnect...');
    
    // Initialize the Ethereum provider
    const provider = await EthereumProvider.init({
      projectId,
      showQrModal: true,
      chains,
      methods: ['eth_sendTransaction', 'personal_sign'],
      events: ['chainChanged', 'accountsChanged'],
      metadata: {
        name: 'Redgold Explorer',
        description: 'Redgold Explorer Application',
        url: window.location.origin,
        icons: [`${window.location.origin}/favicon.ico`]
      }
    });

    // Connect the provider
    await provider.connect();
    
    // Get the accounts
    const accounts = await provider.request({ method: 'eth_accounts' });
    const chainId = await provider.request({ method: 'eth_chainId' });
    
    if (accounts && accounts.length > 0) {
      // Update the store
      store.commit('setWalletConnected', true);
      store.commit('setWalletAddress', accounts[0]);
      store.commit('setWalletChainId', parseInt(chainId, 16));
      store.commit('setWalletType', 'walletconnect');
      
      // Save wallet state to localStorage
      saveWalletStateToLocalStorage();
      
      // Set up event listeners
      provider.on('accountsChanged', (newAccounts) => {
        if (newAccounts.length === 0) {
          // User disconnected
          disconnectWallet();
        } else {
          store.commit('setWalletAddress', newAccounts[0]);
          saveWalletStateToLocalStorage();
        }
      });
      
      provider.on('chainChanged', (newChainId) => {
        store.commit('setWalletChainId', parseInt(newChainId, 16));
        saveWalletStateToLocalStorage();
      });
      
      provider.on('disconnect', () => {
        disconnectWallet();
      });
      
      // Store the provider for later use
      window.walletProvider = provider;
      
      return {
        success: true,
        address: accounts[0],
        chainId: parseInt(chainId, 16),
        walletType: 'walletconnect'
      };
    }
    
    return { success: false };
  } catch (error) {
    console.error('Error connecting to WalletConnect:', error);
    return { 
      success: false, 
      error: error.message 
    };
  }
};

// Main connect wallet function
export const connectWallet = async () => {
  try {
    // First, disconnect any existing wallet connection to ensure a fresh start
    await disconnectWallet();
    
    // Clear any localStorage items related to wallet connection
    localStorage.removeItem('walletConnected');
    localStorage.removeItem('walletAddress');
    localStorage.removeItem('walletChainId');
    localStorage.removeItem('walletType');
    
    // Clear any sessionStorage items as well
    sessionStorage.removeItem('walletConnected');
    sessionStorage.removeItem('walletAddress');
    sessionStorage.removeItem('walletChainId');
    sessionStorage.removeItem('walletType');
    
    console.log('Cleared previous wallet connection data');
    
    // Show wallet selection modal
    const result = await showWalletSelectionModal();
    
    // If user cancelled, return
    if (result.cancelled) {
      return { success: false, cancelled: true };
    }
    
    console.log('Selected wallet:', result.walletId);
    
    // Connect to selected wallet
    switch (result.walletId) {
      case 'metamask':
        return await connectMetamask();
      case 'phantom':
        return await connectPhantom();
      case 'walletconnect':
        return await connectWalletConnect();
      default:
        return { success: false, error: 'Unknown wallet type' };
    }
  } catch (error) {
    console.error('Error connecting wallet:', error);
    return { 
      success: false, 
      error: error.message 
    };
  }
};

// Function to disconnect wallet
export const disconnectWallet = async () => {
  try {
    if (window.walletProvider) {
      const walletType = store.getters.getWalletType;
      
      if (walletType === 'phantom') {
        // Phantom disconnect
        await window.walletProvider.disconnect();
      } else if (walletType === 'walletconnect') {
        // WalletConnect disconnect
        await window.walletProvider.disconnect();
        
        // Remove event listeners
        window.walletProvider.removeAllListeners();
      } else if (walletType === 'metamask' && window.ethereum) {
        // For MetaMask, properly remove all event listeners
        console.log('Removing MetaMask event listeners');
        window.ethereum.removeAllListeners('accountsChanged');
        window.ethereum.removeAllListeners('chainChanged');
        window.ethereum.removeAllListeners('disconnect');
        
        // Stop the polling interval
        stopMetaMaskPolling();
        
        // Try to clear any cached permissions
        if (window.ethereum._metamask && typeof window.ethereum._metamask.clearPermissions === 'function') {
          console.log('Clearing MetaMask permissions');
          await window.ethereum._metamask.clearPermissions();
        }
      }
      
      // Clear the provider
      window.walletProvider = null;
    }
    
    // Update the store
    store.commit('setWalletConnected', false);
    store.commit('setWalletAddress', null);
    store.commit('setWalletChainId', null);
    store.commit('setWalletType', null);
    
    // Clear any localStorage items related to wallet connection
    localStorage.removeItem('walletConnected');
    localStorage.removeItem('walletAddress');
    localStorage.removeItem('walletChainId');
    localStorage.removeItem('walletType');
    
    // Clear any sessionStorage items as well
    sessionStorage.removeItem('walletConnected');
    sessionStorage.removeItem('walletAddress');
    sessionStorage.removeItem('walletChainId');
    sessionStorage.removeItem('walletType');
    
    return { success: true };
  } catch (error) {
    console.error('Error disconnecting wallet:', error);
    return { 
      success: false, 
      error: error.message 
    };
  }
};

// Function to force wallet reconnection (for switching accounts)
export const forceReconnectWallet = async () => {
  try {
    // First disconnect the current wallet
    await disconnectWallet();
    
    // Clear any localStorage items related to wallet connection
    localStorage.removeItem('walletConnected');
    localStorage.removeItem('walletAddress');
    localStorage.removeItem('walletChainId');
    localStorage.removeItem('walletType');
    
    // Clear any sessionStorage items as well
    sessionStorage.removeItem('walletConnected');
    sessionStorage.removeItem('walletAddress');
    sessionStorage.removeItem('walletChainId');
    sessionStorage.removeItem('walletType');
    
    console.log('Cleared previous wallet connection data');
    
    // Then initiate a new connection
    return await connectWallet();
  } catch (error) {
    console.error('Error reconnecting wallet:', error);
    return {
      success: false,
      error: error.message
    };
  }
};

// Function to check if wallet is connected
export const isWalletConnected = () => {
  return store.getters.isWalletConnected;
};

// Function to retrieve saved addresses using WASM bindings
export const getInternalSavedAddresses = () => {
  try {
    if (!window.redgoldWasm) {
      console.error('Redgold WASM not loaded');
      return [];
    }

    // Get config data from localStorage or store
    let configData;
    try {
      const storedConfig = localStorage.getItem('configData');
      if (storedConfig) {
        configData = JSON.parse(storedConfig);
      }
    } catch (e) {
      console.error('Error parsing stored config:', e);
    }
    
    // If we couldn't get config from localStorage, fall back to store
    if (!configData) {
      configData = { ...store.getters.getConfigData };
    }
    
    // Get the wallet address from store
    const walletAddress = store.getters.getWalletAddress || '';
    
    // Call the WASM function
    const savedAddressesJson = window.redgoldWasm.get_internal_saved_addresses(
      JSON.stringify(configData), 
      walletAddress
    );
    
    // Parse the result into an array of saved addresses
    const savedAddresses = JSON.parse(savedAddressesJson);
    
    // Transform the saved addresses into the format expected by the UI
    return savedAddresses.map((address, index) => ({
      id: index + 1,
      name: address.name || `Address ${index + 1}`,
      address: address.address,
      isSelfOwned: address.self_owned || false
    }));
  } catch (error) {
    console.error('Error retrieving saved addresses:', error);
    return [];
  }
};

// Initialize wallet connection state from localStorage when the file is loaded
// console.log('Initializing wallet connection utilities...');
// Use setTimeout to ensure the store is fully initialized before restoring state
setTimeout(() => {
  restoreWalletStateFromLocalStorage();
}, 0);
