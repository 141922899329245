<template>
  <div class="modal" :class="{ 'd-block': show }" tabindex="-1" v-if="show" @click.self="$emit('close')">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content bg-dark text-white">
        <div class="modal-header">
          <h5 class="modal-title">Export Configuration</h5>
          <button type="button" class="btn-close btn-close-white" @click="$emit('close')"></button>
        </div>
        <div class="modal-body">
          <div class="form-group mb-3">
            <label for="exportFormat">Format</label>
            <select id="exportFormat" class="form-control bg-dark text-white" v-model="exportFormat" @change="updateExportText">
              <option value="json">JSON</option>
              <option value="toml">TOML</option>
            </select>
          </div>
          <div class="form-group mb-3">
            <label for="exportOutput">Configuration</label>
            <textarea 
              id="exportOutput" 
              class="form-control bg-dark text-white" 
              rows="10"
              v-model="exportConfigText"
              readonly
            ></textarea>
          </div>
          <div class="form-group mb-3">
            <div class="form-check">
              <input type="checkbox" class="form-check-input" id="includeSensitive" v-model="includeSecrets">
              <label class="form-check-label" for="includeSensitive">Include sensitive information</label>
              <small class="d-block form-text text-muted">Warning: This will include API keys and other sensitive data in the export</small>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-center">
          <button 
            type="button" 
            class="btn btn-gold px-4 py-2"
            @click="copyToClipboard"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-clipboard me-2" viewBox="0 0 16 16">
              <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
              <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
            </svg>
            Copy to Clipboard
          </button>
          <button 
            type="button" 
            class="btn btn-primary px-4 py-2 ms-2"
            @click="downloadConfig"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-download me-2" viewBox="0 0 16 16">
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
            </svg>
            Download
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'ExportModal',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    configData: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  emits: ['close'],
  setup(props) {
    const store = useStore();
    const exportConfigText = ref('');
    const exportFormat = ref('json');
    const includeSecrets = ref(false);

    // Update export text when props change or format/include secrets changes
    const updateExportText = () => {
      try {
        const configToExport = { ...props.configData };
        
        // Remove sensitive information if not including secrets
        if (!includeSecrets.value) {
          if (configToExport.keys) {
            if (configToExport.keys.ai) {
              configToExport.keys.ai = {
                anthropic: configToExport.keys.ai.anthropic ? '***' : '',
                openai: configToExport.keys.ai.openai ? '***' : '',
                gemini: configToExport.keys.ai.gemini ? '***' : '',
                elevenlabs: configToExport.keys.ai.elevenlabs ? '***' : ''
              };
            }
            
            configToExport.keys = {
              ...configToExport.keys,
              etherscan: configToExport.keys.etherscan ? '***' : '',
              recaptcha: configToExport.keys.recaptcha ? '***' : '',
              aws_access: configToExport.keys.aws_access ? '***' : '',
              aws_secret: configToExport.keys.aws_secret ? '***' : ''
            };
          }
          
          // Remove mnemonics
          if (configToExport.local && configToExport.local.mnemonics) {
            configToExport.local.mnemonics = configToExport.local.mnemonics.map(m => ({
              ...m,
              mnemonic: '***',
              passphrase: m.passphrase ? '***' : ''
            }));
          }
        }
        
        // Format based on selected format
        if (exportFormat.value === 'json') {
          exportConfigText.value = JSON.stringify(configToExport, null, 2);
        } else {
          // Use the WASM binding to convert to TOML
          try {
            const configJson = JSON.stringify(configToExport);
            const tomlResult = store.state.wasm.export_config_to_toml(configJson);
            
            // Check if the result is an error or undefined
            if (!tomlResult || tomlResult === "undefined") {
              throw new Error("WASM returned undefined result");
            } else if (typeof tomlResult === 'object' && tomlResult.code !== undefined) {
              // This is an ErrorInfo object
              throw new Error(`Failed to convert to TOML: ${tomlResult.description || tomlResult.message || 'Unknown error'}`);
            }
            
            exportConfigText.value = tomlResult;
          } catch (error) {
            console.error("TOML conversion error:", error);
            // Fallback to JSON if TOML conversion fails
            exportConfigText.value = JSON.stringify(configToExport, null, 2) + 
              "\n\n// Note: TOML conversion failed, showing JSON instead. Error: " + error.message;
          }
        }
      } catch (error) {
        exportConfigText.value = `Error generating export: ${error.message}`;
      }
    };
    
    // Copy to clipboard
    const copyToClipboard = () => {
      try {
        navigator.clipboard.writeText(exportConfigText.value);
        alert('Configuration copied to clipboard');
      } catch (error) {
        alert(`Error copying to clipboard: ${error.message}`);
      }
    };
    
    // Download config
    const downloadConfig = () => {
      try {
        const blob = new Blob([exportConfigText.value], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `config.${exportFormat.value}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      } catch (error) {
        alert(`Error downloading configuration: ${error.message}`);
      }
    };

    // Watch for modal visibility only
    watch(() => props.show, (newVal) => {
      if (newVal) {
        // Use setTimeout to break reactivity chain
        setTimeout(() => {
          updateExportText();
        }, 0);
      }
    });

    return {
      exportConfigText,
      exportFormat,
      includeSecrets,
      updateExportText,
      copyToClipboard,
      downloadConfig
    };
  }
});
</script>

<style scoped>
.modal {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  border: 1px solid #343a40;
}

.form-control {
  border: 1px solid #343a40;
}

.form-control:focus {
  border-color: #ffc107;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.25);
}

.btn-gold {
  background-color: #ffc107;
  color: #212529;
  border: none;
}

.btn-gold:hover {
  background-color: #e0a800;
  color: #212529;
}
</style>
