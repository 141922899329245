<template>
  <div class="app-screen">
    <!-- Sidebar -->
    <div class="sidebar">
      <SidebarHeader />
      <div class="sidebar-menu">
        <div 
          v-for="tab in tabs" 
          :key="tab.id" 
          class="sidebar-item" 
          :class="{ 
            'active': activeTab === tab.id,
            'hover': hoveredTab === tab.id && activeTab !== tab.id
          }"
          @click="setActiveTab(tab.id)"
          @mouseenter="hoveredTab = tab.id"
          @mouseleave="hoveredTab = null"
        >
          <div class="sidebar-icon" v-html="tab.icon"></div>
          <div class="sidebar-text">{{ tab.name }}</div>
        </div>
      </div>
    </div>
    
    <!-- Main Content -->
    <div class="main-content">
      <div class="container-fluid p-4 h-100">
        <!-- Dashboard Tab -->
        <div v-if="activeTab === 'dashboard'">
          <Dashboard />
        </div>
        
        <!-- Portfolio Tab -->
        <div v-if="activeTab === 'portfolio'">
          <h1>Portfolio</h1>
          <p>Manage your crypto portfolios and asset allocations.</p>
          <!-- Portfolio content will go here -->
        </div>
        
        <!-- Assets Tab -->
        <div v-if="activeTab === 'assets'">
          <h1>Assets</h1>
          <p>View and manage your individual crypto assets.</p>
          <!-- Assets content will go here -->
        </div>
        
        <!-- Transactions Tab -->
        <div v-if="activeTab === 'transactions'">
          <h1>Transactions</h1>
          <p>Track your transaction history across all portfolios.</p>
          <!-- Transactions content will go here -->
        </div>
        
        <!-- Analytics Tab -->
        <div v-if="activeTab === 'analytics'">
          <h1>Analytics</h1>
          <p>View detailed analytics and performance metrics for your portfolios.</p>
          <!-- Analytics content will go here -->
        </div>
        
        <!-- Settings Tab -->
        <div v-if="activeTab === 'settings'">
          <Settings />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import SidebarHeader from './sidebar/SidebarHeader.vue';
import Dashboard from './dashboard/Dashboard.vue';
import Settings from './settings/SettingsTab.vue';

export default defineComponent({
  name: 'AppScreen',
  components: {
    SidebarHeader,
    Dashboard,
    Settings
  },
  setup() {
    const store = useStore();
    
    // Active tab state
    const activeTab = ref('dashboard');
    const hoveredTab = ref(null);
    
    // Sidebar tabs configuration
    const tabs = [
      {
        id: 'dashboard',
        name: 'Dashboard',
        icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="3" y="3" width="7" height="9"></rect><rect x="14" y="3" width="7" height="5"></rect><rect x="14" y="12" width="7" height="9"></rect><rect x="3" y="16" width="7" height="5"></rect></svg>'
      },
      {
        id: 'portfolio',
        name: 'Portfolio',
        icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M22 12h-4l-3 9L9 3l-3 9H2"></path></svg>'
      },
      {
        id: 'assets',
        name: 'Assets',
        icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><path d="M12 6v12"></path><path d="M8 12h8"></path></svg>'
      },
      {
        id: 'transactions',
        name: 'Transactions',
        icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M17 3H7c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path><path d="M9 7h6"></path><path d="M9 11h6"></path><path d="M9 15h4"></path></svg>'
      },
      {
        id: 'analytics',
        name: 'Analytics',
        icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M3 3v18h18"></path><path d="M18 9l-5 5-2-2-4 4"></path></svg>'
      },
      {
        id: 'settings',
        name: 'Settings',
        icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>'
      }
    ];
    
    const setActiveTab = (tabId) => {
      activeTab.value = tabId;
    };
    
    return {
      activeTab,
      hoveredTab,
      tabs,
      setActiveTab
    };
  }
});
</script>

<style scoped>
.app-screen {
  min-height: calc(100vh - 70px);
  height: 100vh; /* Add this to ensure it takes full viewport height */
  display: flex;
  overflow: hidden;
  margin-bottom: 0; /* Ensure no margin at bottom */
  padding-bottom: 0; /* Ensure no padding at bottom */
}

/* Sidebar Styles */
.sidebar {
  width: 280px;
  background-color: #0a0a14; /* Dark night color for sidebar */
  color: #fff;
  height: 100%;
  overflow-y: auto;
  border-right: 1px solid #333;
  flex-shrink: 0;
  position: relative; /* Add position relative for proper dropdown positioning */
  z-index: 100; /* Lower z-index to ensure modal appears above sidebar */
}


.sidebar-menu {
  padding: 10px 0;
}

.sidebar-item {
  display: flex;
  align-items: center;
  padding: 12px 20px;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  margin: 4px 8px;
  border-radius: 8px;
}

.sidebar-item.hover:not(.active) {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
}

.sidebar-item.active {
  background-color: rgba(139, 105, 20, 0.3);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.sidebar-item.active::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 70%;
  background-color: #8b6914;
  border-radius: 0 2px 2px 0;
}

.sidebar-icon {
  width: 24px;
  height: 24px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-icon svg {
  width: 20px;
  height: 20px;
  stroke: #ccc;
}

.sidebar-item:hover .sidebar-icon svg,
.sidebar-item.active .sidebar-icon svg {
  stroke: #fff;
}

.sidebar-text {
  font-size: 1rem;
  font-weight: 500;
}

/* Main Content Styles */
.main-content {
  flex-grow: 1;
  overflow-y: auto;
  background-color: #0f0f0f; /* Slightly lighter than black for content area */
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%; /* Ensure it takes up full height */
  position: relative;
  z-index: 1; /* Lower z-index than sidebar to ensure sidebar elements appear above */
  pointer-events: auto; /* Enable pointer events for scrolling */
}

.card {
  border: 1px solid #333;
}

.card-header {
  background-color: rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid #333;
}

.portfolio-item {
  background-color: rgba(0, 0, 0, 0.2);
  transition: background-color 0.2s;
}

.portfolio-item:hover {
  background-color: rgba(30, 30, 30, 0.5);
}

/* Dashboard header styles */
.dashboard-header {
  margin-bottom: 1rem;
}

.refresh-btn {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  line-height: 1;
}
</style>
