<template>
  <div class="address-input-container">
  <div class="form-group mb-3">
    <div class="d-flex align-items-center justify-content-between mb-2">
      <div class="d-flex align-items-center destination-header">
        <label class="form-label field-label me-3">Destination</label>
        <div class="address-mode-toggle">
          <button 
            class="toggle-btn" 
            :class="{ 'active': !useSavedAddress }"
            @click="toggleSavedAddress(false)"
          >
            Raw
          </button>
          <button 
            class="toggle-btn" 
            :class="{ 'active': useSavedAddress }"
            @click="toggleSavedAddress(true)"
          >
            Saved
          </button>
        </div>
      </div>
      <button 
        class="btn btn-outline-secondary copy-header-btn" 
        @click="copyCurrentAddressToClipboard"
        title="Copy current address to clipboard"
      >
        <i class="bi bi-clipboard"></i> Copy
      </button>
    </div>
    
    <!-- Raw Address Input -->
    <div v-if="!useSavedAddress">
      <div class="input-group mb-2">
        <input 
          type="text" 
          class="form-control" 
          v-model="localRecipientAddress" 
          placeholder="Enter address here"
          :class="{ 'is-invalid': error }"
        />
      </div>
      <div class="error-container d-flex align-items-center justify-content-between">
        <small 
          :class="{
            'form-text': true,
            'text-danger': error,
            'text-success': !error && localRecipientAddress,
            'invisible': !error && !localRecipientAddress
          }"
        >
          {{ error || (localRecipientAddress ? 'Valid' : 'Valid address') }}
        </small>
        <!-- Save Address Button -->
        <button 
          class="btn btn-outline-secondary btn-sm"
          @click="toggleSaveAddress"
          v-if="!isAddressFromSaved && localRecipientAddress && !error"
        >
          Save to new address
        </button>
      </div>
      
      <!-- Address Name Input (if saving) -->
      <div class="mb-2" v-if="saveAddress">
        <input 
          type="text" 
          class="form-control" 
          v-model="localNewAddressName" 
          placeholder="Enter a name for this address"
        />
        <small class="form-text text-danger" v-if="newAddressNameError">
          {{ newAddressNameError }}
        </small>
      </div>
    </div>
    
    <!-- Saved Addresses Dropdown -->
    <div v-else>
      <div class="d-flex align-items-center">
        <div class="input-group flex-grow-1">
          <select 
            class="form-select" 
            v-model="localSelectedSavedAddressId"
            @change="onSavedAddressChange"
          >
            <option value="" disabled selected>Select a saved address</option>
            <option 
              v-for="address in savedAddresses" 
              :key="address.id" 
              :value="address.id"
            >
              {{ address.name }} - {{ truncateAddress(address.address) }}
            </option>
          </select>
        </div>
        
        <!-- Details dropdown button -->
        <div class="saved-address-details ms-2" v-if="localSelectedSavedAddressId">
          <button 
            class="btn btn-dropdown" 
            @click="toggleDetailsDropdown"
            title="View address details"
          >
            <i class="bi bi-info-circle me-1"></i>
            Details
            <i class="bi" :class="{'bi-chevron-up': showDetailsDropdown, 'bi-chevron-down': !showDetailsDropdown}"></i>
          </button>
          
          <div class="details-dropdown-menu" v-if="showDetailsDropdown">
            <div class="details-header">Address Details</div>
            <div class="details-content">
              <template v-if="selectedSavedAddress">
                <div class="detail-item">
                  <span class="detail-label">Name:</span>
                  <span class="detail-value">{{ selectedSavedAddress.name }}</span>
                </div>
                <div class="detail-item">
                  <span class="detail-label">Address:</span>
                  <span class="detail-value address-wrap">{{ selectedSavedAddress.address }}</span>
                </div>
                <div class="detail-item" v-if="selectedSavedAddress.isSelfOwned">
                  <span class="detail-label">Type:</span>
                  <span class="detail-value self-owned">Self Owned</span>
                </div>
              </template>
              <div class="detail-item" v-else>
                <span>No address selected</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { defineComponent, ref, watch, computed, onMounted, onUnmounted } from 'vue';

export default defineComponent({
  name: 'AddressInput',
  props: {
    recipientAddress: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    savedAddresses: {
      type: Array,
      required: true
    },
    useSavedAddress: {
      type: Boolean,
      default: false
    },
    saveAddress: {
      type: Boolean,
      default: false
    },
    newAddressName: {
      type: String,
      default: ''
    },
    newAddressNameError: {
      type: String,
      default: ''
    },
    isAddressFromSaved: {
      type: Boolean,
      default: false
    },
    selectedSavedAddressId: {
      type: [String, Number],
      default: ''
    },
    truncateAddress: {
      type: Function,
      required: true
    }
  },
  emits: [
    'update:recipientAddress', 
    'update:useSavedAddress', 
    'update:saveAddress', 
    'update:newAddressName',
    'update:selectedSavedAddressId',
    'saved-address-selected'
  ],
  setup(props, { emit }) {
    const localRecipientAddress = ref(props.recipientAddress);
    const localNewAddressName = ref(props.newAddressName);
    const localSelectedSavedAddressId = ref(props.selectedSavedAddressId);
    const showDetailsDropdown = ref(false);
    
    // Computed property to get the selected saved address
    const selectedSavedAddress = computed(() => {
      if (!localSelectedSavedAddressId.value) return null;
      return props.savedAddresses.find(addr => addr.id === localSelectedSavedAddressId.value);
    });
    
    // Toggle the details dropdown
    const toggleDetailsDropdown = () => {
      showDetailsDropdown.value = !showDetailsDropdown.value;
    };
    
    // Close details dropdown when clicking outside
    const handleClickOutside = (event) => {
      const detailsDropdown = document.querySelector('.saved-address-details');
      if (showDetailsDropdown.value && detailsDropdown && !detailsDropdown.contains(event.target)) {
        showDetailsDropdown.value = false;
      }
    };
    
    // Add event listener when component is mounted
    onMounted(() => {
      document.addEventListener('click', handleClickOutside);
    });
    
    // Remove event listener when component is unmounted
    onUnmounted(() => {
      document.removeEventListener('click', handleClickOutside);
    });
    
    // Watch for changes in local values and emit updates
    watch(localRecipientAddress, (newValue) => {
      emit('update:recipientAddress', newValue);
    });
    
    watch(localNewAddressName, (newValue) => {
      emit('update:newAddressName', newValue);
    });
    
    watch(localSelectedSavedAddressId, (newValue) => {
      emit('update:selectedSavedAddressId', newValue);
    });
    
    // Watch for changes in props and update local values
    watch(() => props.recipientAddress, (newValue) => {
      localRecipientAddress.value = newValue;
    });
    
    watch(() => props.newAddressName, (newValue) => {
      localNewAddressName.value = newValue;
    });
    
    watch(() => props.selectedSavedAddressId, (newValue) => {
      localSelectedSavedAddressId.value = newValue;
    });
    
    const toggleSavedAddress = (value) => {
      emit('update:useSavedAddress', value);
    };
    
    const toggleSaveAddress = () => {
      emit('update:saveAddress', !props.saveAddress);
    };
    
    const onSavedAddressChange = () => {
      if (localSelectedSavedAddressId.value) {
        const selectedAddress = props.savedAddresses.find(addr => addr.id === localSelectedSavedAddressId.value);
        if (selectedAddress) {
          emit('saved-address-selected', selectedAddress);
        }
      }
    };
    
    // Copy current address to clipboard (header button)
    const copyCurrentAddressToClipboard = () => {
      let addressToCopy = "";
      
      if (!props.useSavedAddress) {
        // Copy raw address
        if (localRecipientAddress.value) {
          addressToCopy = localRecipientAddress.value;
        }
      } else {
        // Copy selected saved address
        if (localSelectedSavedAddressId.value) {
          const selectedAddress = props.savedAddresses.find(addr => addr.id === localSelectedSavedAddressId.value);
          if (selectedAddress) {
            addressToCopy = selectedAddress.address;
          }
        }
      }
      
      if (addressToCopy) {
        navigator.clipboard.writeText(addressToCopy)
          .then(() => {
            console.log('Address copied to clipboard');
          })
          .catch(err => {
            console.error('Failed to copy address: ', err);
          });
      } else {
        console.log('No address to copy');
        // Could add a toast notification here to inform the user
      }
    };
    
    return {
      localRecipientAddress,
      localNewAddressName,
      localSelectedSavedAddressId,
      toggleSavedAddress,
      toggleSaveAddress,
      onSavedAddressChange,
      copyCurrentAddressToClipboard,
      // New properties for the details dropdown
      showDetailsDropdown,
      selectedSavedAddress,
      toggleDetailsDropdown
    };
  }
});
</script>

<style scoped>
/* Address Mode Toggle */
.address-mode-toggle {
  display: flex;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #333;
}

.toggle-btn {
  padding: 4px 12px;
  background-color: #2a2a2a;
  border: none;
  color: #999;
  cursor: pointer;
  transition: all 0.2s ease;
}

.toggle-btn.active {
  background-color: #463306;
  color: #fff;
}

/* Field Label Styling */
.field-label {
  font-size: 0.85rem;
  color: #e0e0e0;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 700;
  margin-bottom: 8px;
}

/* Darker input fields */
.form-control, .form-select {
  background-color: #121212;
  border-color: #333;
  color: #fff;
}

.form-control:focus, .form-select:focus {
  background-color: #1a1a1a;
  color: #fff;
  border-color: #b8860b;
  box-shadow: 0 0 0 0.0625rem rgba(184, 134, 11, 0.25);
}

/* Placeholder text styling */
.form-control::placeholder {
  color: #999999;
  opacity: 1;
}

.text-success {
  color: #28a745 !important;
}

/* Header copy button styling */
.copy-header-btn {
  background-color: #2a2a2a;
  border-color: #444;
  color: #b8860b;
  padding: 4px 10px;
  font-size: 0.8rem;
}

.copy-header-btn:hover {
  background-color: #3a3a3a;
  color: #d4a721;
}

.address-input-container {
  padding: 1rem;
  margin-top: -20px;
  margin-bottom: -20px;
}

.error-container {
  min-height: 20px;
  margin-top: 4px;
  margin-bottom: -24px;
}

.invisible {
  visibility: hidden;
}

/* Saved address details dropdown */
.saved-address-details {
  position: relative;
}

.btn-dropdown {
  background-color: #463306;
  border: 1px solid #5e4408;
  color: #fff;
  padding: 0.375rem 0.75rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
  height: 38px;
  display: flex;
  align-items: center;
}

.btn-dropdown:hover {
  background-color: #5e4408;
}

.details-dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 0.5rem;
  background-color: #2a2a2a;
  border: 1px solid #444;
  border-radius: 4px;
  min-width: 300px;
  max-width: 400px;
  z-index: 1000;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.details-header {
  background-color: #333;
  color: #e0e0e0;
  font-weight: bold;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #444;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.details-content {
  padding: 1rem;
}

.detail-item {
  margin-bottom: 0.75rem;
  display: flex;
  flex-direction: column;
}

.detail-item:last-child {
  margin-bottom: 0;
}

.detail-label {
  font-size: 0.75rem;
  color: #888;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.detail-value {
  color: #e0e0e0;
  word-break: break-all;
}

.address-wrap {
  word-wrap: break-word;
  font-family: monospace;
  font-size: 0.85rem;
  background-color: #1a1a1a;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #333;
}

.self-owned {
  color: #b8860b;
  font-weight: bold;
}

.destination-header {
  margin-bottom: -10px;
}
</style>
