<template>
  <Teleport to="body">
    <div class="send-modal" v-if="show" @mousedown.self="close">
    <div class="modal-content" @mousedown.stop>
      <div class="header-container">
      <div class="modal-header py-2">
        <div class="d-flex align-items-center w-100">
          <h4 class="modal-title mb-0 me-4">Send</h4>
          <WalletBalanceDisplay 
            :balance="availableBalance"
            :rdgToUsd="rdgToUsd"
          />
          <TransactionStages 
            :stages="stages"
            :currentStage="currentStage"
          />
        </div>
        <button type="button" class="btn-close" @click="close" aria-label="Close"></button>
      </div>
    </div>
      
      <div class="modal-body">
        <div v-if="currentStage === 0" class="transaction-form">
          <div class="d-flex align-items-start">
            <div class="flex-grow-1">
              <AddressInput
                :recipientAddress="recipientAddress"
                :useSavedAddress="useSavedAddress"
                :saveAddress="saveAddress"
                :newAddressName="newAddressName"
                :selectedSavedAddressId="selectedSavedAddressId"
                :error="errors.recipientAddress"
                :newAddressNameError="errors.newAddressName"
                :savedAddresses="savedAddresses"
                :isAddressFromSaved="isAddressFromSaved"
                :truncateAddress="truncateAddress"
                @update:recipientAddress="recipientAddress = $event"
                @update:useSavedAddress="useSavedAddress = $event"
                @update:saveAddress="saveAddress = $event"
                @update:newAddressName="newAddressName = $event"
                @update:selectedSavedAddressId="selectedSavedAddressId = $event"
                @saved-address-selected="selectSavedAddress"
              />
            </div>
          </div>
          
          <AmountInput
            :amount="amount"
            :currentDenomination="currentDenomination"
            :error="errors.amount"
            :usdToRdg="usdToRdg"
            :rdgToUsd="rdgToUsd"
            :availableBalance="availableBalance"
            @update:amount="amount = $event"
            @update:currentDenomination="currentDenomination = $event"
          />
          
          <FeeSelector
            :selectedFeePreset="selectedFeePreset"
            :feePresets="feePresets"
            :getConfirmationTime="getConfirmationTime"
            @update:selectedFeePreset="selectedFeePreset = $event"
            @fee-changed="validateAmount"
          />
          
          <BalanceInfo
            :availableBalance="availableBalance"
            :amount="amount"
            :fee="getCurrentFee()"
          />
          
          <div class="alert alert-danger" v-if="generalError">
            {{ generalError }}
          </div>
        </div>
        
        <div v-if="currentStage === 1">
          <TransactionReview
            :recipientAddress="recipientAddress"
            :amount="amount"
            :fee="getCurrentFee()"
            :availableBalance="availableBalance"
            :messageToSign="messageToSign"
            :signature="signature"
            :error="generalError"
            :truncateAddress="truncateAddress"
          />
        </div>
        
        <div v-if="currentStage === 2">
          <TransactionConfirm
            :status="transactionStatus"
            :transactionHash="transactionHash"
            :confirmationTime="getConfirmationTime()"
            :error="generalError"
            @copy-hash="copyHash"
          />
        </div>
      </div>
      
      <div class="modal-footer">
        <div v-if="currentStage === 0">
          <button type="button" class="btn btn-secondary me-2" @click="close">Cancel</button>
          <button 
            type="button" 
            class="btn continue-button" 
            @click="prepareTransaction"
            :disabled="!isFormValid"
          >
            Continue
          </button>
        </div>
        
        <div v-if="currentStage === 1">
          <button type="button" class="btn btn-secondary me-2" @click="currentStage = 0">Back</button>
          <button type="button" class="btn continue-button" @click="signTransaction">Sign Transaction</button>
        </div>
        
        <div v-if="currentStage === 2">
          <button 
            type="button" 
            class="btn btn-secondary me-2" 
            @click="currentStage = 1"
            v-if="transactionStatus === 'ready'"
          >
            Back
          </button>
          <button 
            type="button" 
            class="btn continue-button" 
            @click="broadcastTx"
            v-if="transactionStatus === 'ready'"
          >
            {{ transactionStatus === 'pending' || transactionStatus === 'broadcasting' ? 'Broadcasting...' : 'Broadcast Transaction' }}
          </button>
          <button 
            type="button" 
            class="btn btn-secondary me-2" 
            @click="close"
            v-if="transactionStatus !== 'pending' && transactionStatus !== 'ready' && transactionStatus !== 'broadcasting'"
          >
            Close
          </button>
          <button 
            type="button" 
            class="btn continue-button" 
            @click="viewTransaction"
            v-if="transactionStatus === 'success'"
          >
            View Transaction
          </button>
        </div>
      </div>
    </div>
    </div>
  </teleport>
</template>

<script>
import { defineComponent, ref, computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { 
  validateAddress, 
  prepareTransaction, 
  signTransaction, 
  broadcastTransaction,
  getTransactionFeePresets,
  estimateConfirmationTime,
  formatAddress,
  getSignMessage
} from '../../../utils/transaction-utils.js';
import { getInternalSavedAddresses } from '../../../utils/wallet-connect.js';
import { formatCurrencyAbbreviated } from '../../../utils/app-utils.js';

import TransactionStages from './send-modal-components/TransactionStages.vue';
import WalletBalanceDisplay from './send-modal-components/WalletBalanceDisplay.vue';
import AddressInput from './send-modal-components/AddressInput.vue';
import AmountInput from './send-modal-components/AmountInput.vue';
import FeeSelector from './send-modal-components/FeeSelector.vue';
import BalanceInfo from './send-modal-components/BalanceInfo.vue';
import TransactionReview from './send-modal-components/TransactionReview.vue';
import TransactionConfirm from './send-modal-components/TransactionConfirm.vue';

export default defineComponent({
  name: 'SendModal',
  components: {
    TransactionStages,
    WalletBalanceDisplay,
    AddressInput,
    AmountInput,
    FeeSelector,
    BalanceInfo,
    TransactionReview,
    TransactionConfirm
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close', 'transaction-complete'],
  setup(props, { emit }) {
    const store = useStore();
    const stages = [
      { id: 'prepare', label: 'Prepare' },
      { id: 'sign', label: 'Sign' },
      { id: 'broadcast', label: 'Broadcast' }
    ];
    const currentStage = ref(0);
    const currentDenomination = ref('RDG');
    const amount = ref('');
    const recipientAddress = ref('');
    const saveAddress = ref(false);
    const newAddressName = ref('');
    const isAddressFromSaved = ref(false);
    const useSavedAddress = ref(false);
    const selectedSavedAddressId = ref('');
    const feePresets = ref({
      zero: 0,
      low: 0.0001,
      medium: 0.001
    });
    const selectedFeePreset = ref('zero');
    const transactionHash = ref('');
    const transactionStatus = ref('');
    const generalError = ref('');
    const errors = ref({
      amount: '',
      recipientAddress: '',
      newAddressName: ''
    });
    const availableBalance = computed(() => store.getters.getWalletBalance || 0);
    const rdgToUsdRate = computed(() => store.state.exchangeRate || 1.25);
    const savedAddresses = ref([]);
    
    const loadSavedAddresses = () => {
      try {
        const addresses = getInternalSavedAddresses();
        if (addresses && addresses.length > 0) {
          savedAddresses.value = addresses;
        } else {
          savedAddresses.value = [
            { id: 1, name: 'My Wallet', address: 'rdg1qyqszqgpqyqszqgpqyqszqgpqyqszqgpqyqszqgpqyqszqgpqyqs0gep3w' },
            { id: 2, name: 'Exchange', address: 'rdg1qyqszqgpqyqszqgpqyqszqgpqyqszqgpqyqszqgpqyqszqgpqyqs0gep3w' }
          ];
        }

        const currentWalletAddress = store.getters.getWalletAddress?.toLowerCase();
        const defaultAddress = savedAddresses.value.find(addr => addr.address.toLowerCase() !== currentWalletAddress);

        if (defaultAddress) {
          selectedSavedAddressId.value = defaultAddress.id;
          useSavedAddress.value = true;
          selectSavedAddress(defaultAddress);
        } else if (savedAddresses.value.length > 0) {
          selectedSavedAddressId.value = savedAddresses.value[0].id;
          selectSavedAddress(savedAddresses.value[0]);
          useSavedAddress.value = false;
        }
      } catch (error) {
        console.error('Error loading saved addresses:', error);
        savedAddresses.value = [
          { id: 1, name: 'My Wallet', address: 'rdg1qyqszqgpqyqszqgpqyqszqgpqyqszqgpqyqszqgpqyqszqgpqyqs0gep3w' },
          { id: 2, name: 'Exchange', address: 'rdg1qyqszqgpqyqszqgpqyqszqgpqyqszqgpqyqszqgpqyqszqgpqyqs0gep3w' }
        ];
      }
    };
    
    const isFormValid = computed(() => {
      return amount.value > 0 && 
             recipientAddress.value && 
             !errors.value.amount && 
             !errors.value.recipientAddress &&
             !errors.value.newAddressName &&
             parseFloat(amount.value) + getCurrentFee() <= availableBalance.value;
    });
    
    const rdgToUsd = (rdgAmount) => {
      if (!rdgAmount) return 0;
      return (parseFloat(rdgAmount) * 100).toFixed(2);
    };
    
    const usdToRdg = (usdAmount) => {
      if (!usdAmount) return 0;
      return (parseFloat(usdAmount) / 100).toFixed(6);
    };
    
    const close = () => {
      emit('close');
      resetForm();
    };
    
    const resetForm = () => {
      currentStage.value = 0;
      currentDenomination.value = 'RDG';
      amount.value = '';
      recipientAddress.value = '';
      saveAddress.value = false;
      newAddressName.value = '';
      isAddressFromSaved.value = false;
      selectedFeePreset.value = 'zero';
      transactionHash.value = '';
      transactionStatus.value = '';
      generalError.value = '';
      errors.value = {
        amount: '',
        recipientAddress: '',
        newAddressName: ''
      };
      messageToSign.value = '';
      signature.value = '';
      preparedTransaction.value = null;
      signedTransaction.value = null;
      store.commit('setPreparedTransaction', null);
      store.commit('setSignedTransaction', null);
      store.commit('setTransactionSignature', null);
    };
    
    const truncateAddress = formatAddress;
    
    const selectSavedAddress = (address) => {
      recipientAddress.value = address.address;
      isAddressFromSaved.value = true;
      saveAddress.value = false;
      validateRecipientAddress();
    };
    
    const onSavedAddressChange = () => {
      if (selectedSavedAddressId.value) {
        const selectedAddress = savedAddresses.value.find(addr => addr.id === selectedSavedAddressId.value);
        if (selectedAddress) {
          selectSavedAddress(selectedAddress);
        }
      }
    };
    
    const getCurrentFee = () => {
      return feePresets.value[selectedFeePreset.value];
    };
    
    const selectFeePreset = (preset) => {
      selectedFeePreset.value = preset;
      emit('fee-changed');
    };
    
    const getConfirmationTime = () => {
      return estimateConfirmationTime(getCurrentFee(), feePresets.value);
    };
    
    const formatBalance = (balance) => {
      return parseFloat(balance).toFixed(6);
    };
    
    const validateAmount = () => {
      errors.value.amount = '';
      
      if (!amount.value) {
        errors.value.amount = 'Amount is required';
        return false;
      }
      
      const numAmount = parseFloat(amount.value);
      if (isNaN(numAmount) || numAmount <= 0) {
        errors.value.amount = 'Amount must be greater than 0';
        return false;
      }
      
      const currentWalletBalance = store.getters.getWalletBalance;
      
      if (numAmount + getCurrentFee() > currentWalletBalance) {
        errors.value.amount = 'Insufficient balance';
        return false;
      }
      
      return true;
    };
    
    const validateRecipientAddress = () => {
      errors.value.recipientAddress = '';
      
      if (!recipientAddress.value) {
        errors.value.recipientAddress = 'Recipient address is required';
        return false;
      }
    
      let result = validateAddress(recipientAddress.value);
      console.log('validateAddress result:', result);

      if (result !=  '') {
        errors.value.recipientAddress = 'Invalid Redgold address';
        return false;
      }
      
      return true;
    };
    
    const validateNewAddressName = () => {
      errors.value.newAddressName = '';
      
      if (saveAddress.value && !newAddressName.value) {
        errors.value.newAddressName = 'Address name is required';
        return false;
      }
      
      return true;
    };
    
    const prepareTx = async () => {
      const isAmountValid = validateAmount();
      const isAddressValid = validateRecipientAddress();
      const isNameValid = validateNewAddressName();
      
      if (!isAmountValid || !isAddressValid || !isNameValid) {
        return;
      }
      
      try {
        generalError.value = '';
        
        const txAmount = currentDenomination.value === 'USD' 
          ? usdToRdg(amount.value, rdgToUsdRate.value) 
          : parseFloat(amount.value);
        
        const result = prepareTransaction(
          recipientAddress.value,
          txAmount,
          getCurrentFee(),
          store.getters.getWalletAddress
        );
        
        if (result) {
          generalError.value = result.message || 'Failed to prepare transaction';
          return;
        }
        
        store.commit('setPreparedTransaction', result);
        preparedTransaction.value = result;
        
        if (saveAddress.value && newAddressName.value) {
          const newAddress = {
            id: Date.now(),
            name: newAddressName.value,
            address: recipientAddress.value
          };
          
          if (store.state.savedAddresses) {
            store.commit('addSavedAddress', newAddress);
          } else {
            console.log('Saved new address:', newAddress);
          }
        }
        
        currentStage.value = 1;
        
        try {
          // Get message to sign immediately when entering sign stage
          const msgResult = await getSignMessage(store.getters.getWalletAddress);
          if (!msgResult.success) {
            generalError.value = msgResult.error || 'Failed to get message to sign';
            return;
          }
          messageToSign.value = msgResult.messageToSign;
        } catch (error) {
          generalError.value = error.message || 'Failed to get message to sign';
          console.error('Error getting sign message:', error);
          return;
        }
      } catch (error) {
        generalError.value = error.message || 'Failed to prepare transaction';
        console.error('Error preparing transaction:', error);
      }
    };
    
    const signature = ref('');
    const messageToSign = ref('');
    
    const signTx = async () => {
      try {
        generalError.value = '';
        transactionStatus.value = '';
        
        // Get message to sign
        const msgResult = await getSignMessage(store.getters.getWalletAddress);
        if (!msgResult.success) {
          generalError.value = msgResult.error || 'Failed to get message to sign';
          return;
        }
        
        messageToSign.value = msgResult.messageToSign;
        
        // Sign with Metamask
        const result = await signTransaction({
          messageToSign: messageToSign.value,
          walletAddress: store.getters.getWalletAddress
        });
        
        if (!result.success) {
          generalError.value = result.error || 'Failed to sign transaction';
          return;
        }
        
        signature.value = result.signature;
        store.commit('setTransactionSignature', signature.value);
        
        // Create signed transaction
        signedTransaction.value = {
          ...preparedTransaction.value,
          signature: signature.value
        };
        store.commit('setSignedTransaction', signedTransaction.value);
        
        transactionStatus.value = 'ready';
        currentStage.value = 2;
      } catch (error) {
        generalError.value = error.message || 'Failed to sign transaction';
        console.error('Error signing transaction:', error);
      }
    };
    
    const getApiUrl = () => {
      const hostname = window.location.hostname || 'localhost';
      
      let baseUrl = "https://api.redgold.io";
      if (hostname.includes('dev') || hostname.includes('localhost')) {
        baseUrl = "https://dev.api.redgold.io";
      } else if (hostname.includes('staging')) {
        baseUrl = "https://staging.api.redgold.io";
      } else if (hostname.includes('test')) {
        baseUrl = "https://test.api.redgold.io";
      }

      return `${baseUrl}/v1/request`;
    };

    const broadcastTx = async () => {
      try {
        transactionStatus.value = 'pending';
        generalError.value = '';
        
        // Get transaction request from WASM
        const rawPayload = window.redgoldWasm.get_transaction_request();

        console.log('Raw tx request payload:', rawPayload);
        const parsedPayload = JSON.parse(rawPayload);

        if (!parsedPayload && parsedPayload.code) {
          transactionStatus.value = 'error';
          generalError.value = 'Failed to prepare transaction request';
          return;
        }

        // Parse the raw payload
        console.log('Broadcasting transaction request:', parsedPayload);

        // Update UI to show broadcasting state
        transactionStatus.value = 'broadcasting';
        
        // Make request to API endpoint
        const apiUrl = getApiUrl();
        console.log('Using API URL:', apiUrl);
        
        const response = await fetch(apiUrl, {
          method: 'POST',
          // mode: 'cors',
          // credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            // 'Accept': 'application/json'
          },
          body: JSON.stringify(parsedPayload)
        });

        if (!response.ok) {
          const errorText = await response.text();
          console.error('Transaction broadcast failed:', errorText);
          transactionStatus.value = 'error';
          generalError.value = 'Failed to submit transaction';
          return;
        }

        const responseData = await response.json();
        console.log('Transaction broadcast response:', responseData);
        
        if (responseData.response_metadata.error_info) {
          transactionStatus.value = 'error';
          generalError.value = responseData.response_metadata.error_info.message;
          return;
        }
        
        transactionStatus.value = 'success';
        transactionHash.value = responseData.hash;
        
        emit('transaction-complete', {
          hash: transactionHash.value,
          amount: parseFloat(currentDenomination.value === 'USD' 
            ? usdToRdg(amount.value, rdgToUsdRate.value) 
            : amount.value),
          fee: getCurrentFee(),
          recipient: recipientAddress.value,
          timestamp: responseData.timestamp || Date.now()
        });
        
        if (store.state.walletBalance !== undefined) {
          const deductAmount = parseFloat(currentDenomination.value === 'USD' 
            ? usdToRdg(amount.value, rdgToUsdRate.value) 
            : amount.value) + getCurrentFee();
          store.commit('updateWalletBalance', availableBalance.value - deductAmount);
        }
      } catch (error) {
        transactionStatus.value = 'error';
        generalError.value = error.message || 'Failed to broadcast transaction';
        console.error('Error broadcasting transaction:', error);
      }
    };
    
    const copyAddressToClipboard = () => {
      if (!recipientAddress.value) return;
      
      navigator.clipboard.writeText(recipientAddress.value)
        .then(() => {
          console.log('Address copied to clipboard');
        })
        .catch(err => {
          console.error('Failed to copy address:', err);
        });
    };
    
    const copyHash = () => {
      navigator.clipboard.writeText(transactionHash.value)
        .then(() => {
          console.log('Hash copied to clipboard');
        })
        .catch(err => {
          console.error('Failed to copy hash:', err);
        });
    };
    
    const viewTransaction = () => {
      console.log('Viewing transaction:', transactionHash.value);
    };
    
    watch(amount, validateAmount);
    watch(recipientAddress, validateRecipientAddress);
    watch(newAddressName, validateNewAddressName);
    
    watch(currentDenomination, (newDenom) => {
      if (newDenom === 'USD' && amount.value) {
        amount.value = rdgToUsd(amount.value);
      } else if (newDenom === 'RDG' && amount.value) {
        amount.value = usdToRdg(amount.value);
      }
    });
    
    const preparedTransaction = ref(null);
    const signedTransaction = ref(null);
    
    onMounted(async () => {
      try {
        const presets = getTransactionFeePresets();
        if (presets && Object.keys(presets).length > 0) {
          feePresets.value = presets;
        }
      } catch (error) {
        console.error('Error loading fee presets:', error);
      }
      
      loadSavedAddresses();
    });
    
    return {
      stages,
      currentStage,
      currentDenomination,
      amount,
      recipientAddress,
      saveAddress,
      newAddressName,
      isAddressFromSaved,
      useSavedAddress,
      selectedSavedAddressId,
      feePresets,
      selectedFeePreset,
      transactionHash,
      transactionStatus,
      generalError,
      errors,
      availableBalance,
      savedAddresses,
      isFormValid,
      close,
      truncateAddress,
      selectSavedAddress,
      onSavedAddressChange,
      getCurrentFee,
      selectFeePreset,
      getConfirmationTime,
      formatBalance,
      prepareTransaction: prepareTx,
      signTransaction: signTx,
      broadcastTx,
      copyAddressToClipboard,
      copyHash,
      viewTransaction,
      validateAmount,
      rdgToUsd,
      formatCurrencyAbbreviated,
      messageToSign,
      signature,
      signedTransaction
    };
  }
});
</script>

<style scoped>
.send-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  position: relative;
  width: 100%;
  max-width: 750px;
  background-color: #1e1e1e;
  color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
  margin: 2rem;
  pointer-events: all;
}

.form-control {
  background-color: #121212;
  border-color: #333;
  color: #fff;
}

.form-control:focus {
  background-color: #1a1a1a;
  color: #fff;
  border-color: #b8860b;
  box-shadow: 0 0 0 0.25rem rgba(184, 134, 11, 0.25);
}

.form-control::placeholder {
  color: #999999;
  opacity: 1;
}

.input-group-text {
  background-color: #2a2a2a;
  border-color: #333;
  color: #241c1c;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  height: 70px;
  border-bottom: 1px solid #333;
}

.modal-body {
  padding: 1rem;
  max-height: 70vh;
  overflow-y: auto;
}

.modal-footer {
  padding: 1rem;
  border-top: 1px solid #333;
  display: flex;
  justify-content: flex-end;
}

.field-label {
  font-size: 0.85rem;
  color: #e0e0e0;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 700;
  margin-bottom: 8px;
}

.detail-label {
  color: #aaa;
}

.hash-preview {
  font-family: monospace;
  word-break: break-all;
}

.btn-outline-primary {
  color: #b8860b !important;
  border-color: #b8860b !important;
}

.btn-outline-primary:hover {
  background-color: rgba(184, 134, 11, 0.1) !important;
}

.text-primary {
  color: #b8860b !important;
}

.spinner-border.text-primary {
  border-color: #b8860b !important;
  border-right-color: transparent !important;
}

.btn-close {
  filter: invert(1) grayscale(100%) brightness(200%);
  opacity: 0.8;
}

.btn-close:hover {
  opacity: 1;
}

.continue-button {
  color: #FFD700;
  background-color: #500000;
  transition: all 0.2s ease;
}

.continue-button:hover:not(:disabled) {
  background-color: #700000;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.continue-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.header-container {
  padding: 1rem;
  margin-top: -15px;
  margin-bottom: -15px;
}
</style>
