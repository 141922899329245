<template>
  <div class="amount-input-container">
    <!-- Amount Input -->
    <div class="form-group mb-3">
      <div class="d-flex flex-column mb-2">
        <div class="d-flex align-items-center mb-2">
          <label class="form-label field-label mb-0 me-3">Amount</label>
          <div class="quick-amount-buttons d-flex flex-wrap gap-1">
            <!-- Fixed USD amounts -->
            <button 
              type="button" 
              class="btn btn-xs btn-outline-primary" 
              @click="setFixedUsdAmount(1)"
              :class="{ 'active': activeButton === 'usd-1' }"
            >
              $1
            </button>
            <button 
              type="button" 
              class="btn btn-xs btn-outline-primary" 
              @click="setFixedUsdAmount(10)"
              :class="{ 'active': activeButton === 'usd-10' }"
            >
              $10
            </button>
            <button 
              type="button" 
              class="btn btn-xs btn-outline-primary" 
              @click="setFixedUsdAmount(100)"
              :class="{ 'active': activeButton === 'usd-100' }"
            >
              $100
            </button>
            
            <!-- Percentage of available balance -->
            <button 
              type="button" 
              class="btn btn-xs btn-outline-primary" 
              @click="setPercentOfBalance(0.01)"
              :disabled="!availableBalance"
              :class="{ 'active': activeButton === 'pct-1' }"
            >
              1%
            </button>
            <button 
              type="button" 
              class="btn btn-xs btn-outline-primary" 
              @click="setPercentOfBalance(0.05)"
              :disabled="!availableBalance"
              :class="{ 'active': activeButton === 'pct-5' }"
            >
              5%
            </button>
            <button 
              type="button" 
              class="btn btn-xs btn-outline-primary" 
              @click="setPercentOfBalance(0.25)"
              :disabled="!availableBalance"
              :class="{ 'active': activeButton === 'pct-25' }"
            >
              25%
            </button>
            <button 
              type="button" 
              class="btn btn-xs btn-outline-primary" 
              @click="setPercentOfBalance(0.5)"
              :disabled="!availableBalance"
              :class="{ 'active': activeButton === 'pct-50' }"
            >
              50%
            </button>
            <button 
              type="button" 
              class="btn btn-xs btn-outline-primary" 
              @click="setPercentOfBalance(1)"
              :disabled="!availableBalance"
              :class="{ 'active': activeButton === 'pct-100' }"
            >
              100%
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col pe-1">
          <div class="input-group">
            <input 
              type="text" 
              class="form-control" 
              :value="usdDisplayValue"
              placeholder="0.00"
              min="0"
              :class="{ 'is-invalid': error }"
              @input="onUsdInput($event)"
              @blur="onUsdBlur"
              @focus="onUsdFocus"
            />
            <span class="input-group-text">USD</span>
          </div>
        </div>
        <div class="col ps-1">
          <div class="input-group">
            <input 
              type="text" 
              class="form-control" 
              :value="rdgDisplayValue"
              placeholder="0.00"
              min="0"
              :class="{ 'is-invalid': error }"
              @input="onRdgInput($event)"
              @blur="onRdgBlur"
              @focus="onRdgFocus"
            />
            <span class="input-group-text">RDG</span>
          </div>
        </div>
      </div>
      <div class="error-container d-flex align-items-center">
        <small 
          :class="{
            'form-text': true,
            'text-danger': error,
            'text-success': !error && (usdRawValue || rdgRawValue),
            'invisible': !error && !(usdRawValue || rdgRawValue)
          }"
        >
          {{ error || ((usdRawValue || rdgRawValue) ? 'Valid' : 'Valid amount') }}
        </small>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
  name: 'AmountInput',
  props: {
    amount: {
      type: [String, Number],
      default: ''
    },
    currentDenomination: {
      type: String,
      default: 'RDG'
    },
    error: {
      type: String,
      default: ''
    },
    usdToRdg: {
      type: Function,
      required: true
    },
    rdgToUsd: {
      type: Function,
      required: true
    },
    availableBalance: {
      type: Number,
      default: 0
    }
  },
  emits: ['update:amount', 'update:currentDenomination'],
  setup(props, { emit }) {
    // Internal raw values (unformatted)
    const usdRawValue = ref('');
    const rdgRawValue = ref(props.amount || '');
    
    // Display values (what shows in the input)
    const usdDisplayValue = ref('');
    const rdgDisplayValue = ref(props.amount || '');
    
    const activeButton = ref('');
    let updatingFromWatch = false;
    let manualInput = false;
    let usdFocused = false;
    let rdgFocused = false;
    
    // Custom conversion functions with fixed ratio of 100 USD/RDG
    const convertUsdToRdg = (usdValue) => {
      if (!usdValue || isNaN(parseFloat(usdValue))) return '';
      return (parseFloat(usdValue) / 100).toFixed(6);
    };
    
    const convertRdgToUsd = (rdgValue) => {
      if (!rdgValue || isNaN(parseFloat(rdgValue))) return '';
      return (parseFloat(rdgValue) * 100).toFixed(2);
    };
    
    // Format functions
    const formatUsdValue = (value) => {
      if (!value || isNaN(parseFloat(value))) return '';
      return parseFloat(value).toFixed(2);
    };
    
    const formatRdgValue = (value) => {
      if (!value || isNaN(parseFloat(value))) return '';
      return parseFloat(value).toFixed(6);
    };
    
    // Focus handlers
    const onUsdFocus = () => {
      usdFocused = true;
      // Use raw value when focused
      usdDisplayValue.value = usdRawValue.value;
    };
    
    const onRdgFocus = () => {
      rdgFocused = true;
      // Use raw value when focused
      rdgDisplayValue.value = rdgRawValue.value;
    };
    
    // Blur handlers
    const onUsdBlur = () => {
      usdFocused = false;
      
      // Only now update and format both fields
      if (usdRawValue.value && !isNaN(parseFloat(usdRawValue.value))) {
        // Format USD value
        usdDisplayValue.value = formatUsdValue(usdRawValue.value);
        
        // Convert to RDG and format
        const rawRdgValue = parseFloat(usdRawValue.value) / 100;
        rdgRawValue.value = rawRdgValue.toString();
        rdgDisplayValue.value = formatRdgValue(rawRdgValue);
        
        // Emit the RDG value
        emit('update:amount', rdgRawValue.value);
        emit('update:currentDenomination', 'RDG');
      }
    };
    
    const onRdgBlur = () => {
      rdgFocused = false;
      
      // Only now update and format both fields
      if (rdgRawValue.value && !isNaN(parseFloat(rdgRawValue.value))) {
        // Format RDG value
        rdgDisplayValue.value = formatRdgValue(rdgRawValue.value);
        
        // Convert to USD and format
        const rawUsdValue = parseFloat(rdgRawValue.value) * 100;
        usdRawValue.value = rawUsdValue.toString();
        usdDisplayValue.value = formatUsdValue(rawUsdValue);
        
        // Emit the RDG value
        emit('update:amount', rdgRawValue.value);
        emit('update:currentDenomination', 'RDG');
      }
    };
    
    // Input handlers
    const onUsdInput = (event) => {
      // CRITICAL: If we're updating from a watch, don't process this input
      if (updatingFromWatch) return;
      
      // CRITICAL: When typing in USD field, we ONLY update the USD value exactly as typed
      // and convert to RDG. No formatting or interference with the USD input.
      const inputValue = event.target.value;
      
      // Store raw value and display exactly what user types - no formatting
      usdRawValue.value = inputValue;
      usdDisplayValue.value = inputValue; // Show exactly what user types
      
      // Check if user manually typed a different amount
      if (!manualInput) {
        manualInput = true;
        activeButton.value = ''; // Clear active button on manual input
      }
      
      // Only update the RDG field based on USD input
      if (inputValue && !isNaN(parseFloat(inputValue))) {
        const rawRdgValue = parseFloat(inputValue) / 100;
        rdgRawValue.value = rawRdgValue.toString();
        
        // Only update RDG display if not focused
        if (!rdgFocused) {
          rdgDisplayValue.value = formatRdgValue(rawRdgValue);
        }
        
        // Emit the RDG value
        emit('update:amount', rdgRawValue.value);
        emit('update:currentDenomination', 'RDG');
      } else {
        rdgRawValue.value = '';
        if (!rdgFocused) {
          rdgDisplayValue.value = '';
        }
        emit('update:amount', '');
      }
    };
    
    const onRdgInput = (event) => {
      if (updatingFromWatch) return;
      
      // Store raw value AND update display value of the field being typed in
      // WITHOUT formatting it - this is crucial to prevent interruption
      rdgRawValue.value = event.target.value;
      rdgDisplayValue.value = event.target.value; // Show exactly what user types
      
      // Check if user manually typed a different amount
      if (!manualInput) {
        manualInput = true;
        activeButton.value = ''; // Clear active button on manual input
      }
      
      // Update USD field in real-time as user types in RDG field
      if (rdgRawValue.value && !isNaN(parseFloat(rdgRawValue.value))) {
        const rawUsdValue = parseFloat(rdgRawValue.value) * 100;
        usdRawValue.value = rawUsdValue.toString();
        
        // Only update the OTHER field's display value (USD)
        if (!usdFocused) {
          usdDisplayValue.value = formatUsdValue(rawUsdValue);
        }
        
        // Emit the RDG value
        emit('update:amount', rdgRawValue.value);
        emit('update:currentDenomination', 'RDG');
      } else {
        usdRawValue.value = '';
        if (!usdFocused) {
          usdDisplayValue.value = '';
        }
        emit('update:amount', '');
      }
    };
    
    // Initialize USD value based on initial RDG amount
    if (props.amount) {
      rdgRawValue.value = props.amount;
      rdgDisplayValue.value = props.amount;
      usdRawValue.value = convertRdgToUsd(props.amount);
      usdDisplayValue.value = usdRawValue.value;
    }
    
    // Set fixed USD amount
    const setFixedUsdAmount = (amount) => {
      manualInput = false;
      activeButton.value = `usd-${amount}`;
      
      // Set raw and display values
      usdRawValue.value = amount.toString();
      usdDisplayValue.value = formatUsdValue(amount);
      
      // Convert to RDG
      const rdgValue = parseFloat(amount) / 100;
      rdgRawValue.value = rdgValue.toString();
      rdgDisplayValue.value = formatRdgValue(rdgValue);
      
      emit('update:amount', rdgRawValue.value);
      emit('update:currentDenomination', 'RDG');
    };
    
    // Set percentage of available balance
    const setPercentOfBalance = (percentage) => {
      if (!props.availableBalance) return;
      
      manualInput = false;
      activeButton.value = `pct-${percentage * 100}`;
      
      // Calculate amount as percentage of available balance in RDG
      // Note: availableBalance is already in RDG units - no need to divide by 100
      const rdgValue = props.availableBalance * percentage;
      
      // Set RDG values
      rdgRawValue.value = rdgValue.toString();
      rdgDisplayValue.value = formatRdgValue(rdgValue);
      
      // Set USD values
      const usdValue = rdgValue * 100;
      usdRawValue.value = usdValue.toString();
      usdDisplayValue.value = formatUsdValue(usdValue);
      
      emit('update:amount', rdgRawValue.value);
      emit('update:currentDenomination', 'RDG');
    };
    
    // Watch for external changes in props
    watch(() => props.amount, (newValue) => {
      if (updatingFromWatch) return;
      updatingFromWatch = true;
      
      rdgRawValue.value = newValue;
      rdgDisplayValue.value = newValue;
      
      if (newValue && !isNaN(parseFloat(newValue))) {
        const usdValue = parseFloat(newValue) * 100;
        usdRawValue.value = usdValue.toString();
        
        // CRITICAL: Don't update USD display value if USD field is focused
        // This prevents interference when typing in USD field
        if (!usdFocused) {
          usdDisplayValue.value = formatUsdValue(usdValue);
        }
      } else {
        usdRawValue.value = '';
        // Only update display if not focused
        if (!usdFocused) {
          usdDisplayValue.value = '';
        }
      }
      
      updatingFromWatch = false;
    });
    
    return {
      usdDisplayValue,
      rdgDisplayValue,
      usdRawValue,
      rdgRawValue,
      onUsdInput,
      onRdgInput,
      onUsdBlur,
      onRdgBlur,
      onUsdFocus,
      onRdgFocus,
      setFixedUsdAmount,
      setPercentOfBalance,
      activeButton
    };
  }
});
</script>

<style scoped>
/* Field Label Styling */
.field-label {
  font-size: 0.85rem;
  color: #e0e0e0;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 700;
  margin-bottom: 8px;
}

/* Darker input fields */
.form-control {
  background-color: #121212;
  border-color: #333;
  color: #fff;
}

.form-control:focus {
  background-color: #1a1a1a;
  color: #fff;
  border-color: #b8860b;
  box-shadow: 0 0 0 0.25rem rgba(184, 134, 11, 0.25);
}

/* Placeholder text styling */
.form-control::placeholder {
  color: #999999;
  opacity: 1;
}

.input-group-text {
  background-color: #2a2a2a;
  border-color: #333;
  color: #fff;
}

.btn-outline-primary {
  color: #b8860b !important;
  border-color: #b8860b !important;
}

.btn-outline-primary:hover {
  background-color: rgba(184, 134, 11, 0.1) !important;
}

.btn-primary {
  background-color: #b8860b !important;
  border-color: #533f0f !important;
}

.quick-amount-buttons .btn {
  font-size: 0.75rem;
  padding: 0.15rem 0.35rem;
  margin-left: 2px;
  margin-bottom: -10px;
}

.btn-xs {
  font-size: 0.7rem;
  padding: 0.15rem 0.3rem;
  line-height: 1.2;
}

.gap-1 {
  gap: 0.25rem !important;
}

.btn-outline-primary.active {
  background-color: #d4a721 !important;
  color: white !important;
  box-shadow: 0 0 4px rgba(212, 167, 33, 0.6);
  transform: scale(1.05);
  transition: none;
}

/* Add stronger styling for active buttons */
.btn.active, button.active {
  background-color: #1b1503 !important;
  color: white !important;
  font-weight: bold;
  border-color: #d4a721 !important;
}

/* Ensure active state persists on hover */
button.active:hover {
  background-color: #56471b !important;
  color: white !important;
}

.amount-input-container {
  padding: 1rem;
  margin-top: -5px;
  margin-bottom: -35px;
  max-height: 150px;
}

.error-container {
  min-height: 20px;
  margin-top: -20px;
  /* padding-left: 1rem; */
  /* padding-bottom: 15px; */
}

.invisible {
  visibility: hidden;
}
</style>
