<template>
  <div class="modal" :class="{ 'd-block': show }" tabindex="-1" v-if="show" @click.self="$emit('close')">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content bg-dark text-white">
        <div class="modal-header py-2">
          <h5 class="modal-title">Import Configuration</h5>
          <button type="button" class="btn-close btn-close-white" @click="$emit('close')"></button>
        </div>
        <div class="overwrite-controls p-2 border-bottom border-secondary">
          <div class="d-flex align-items-center">
            <span class="me-2 fw-bold">Overwrite Mode:</span>
            <span :class="['status-indicator', overwriteMode ? 'status-error' : 'status-neutral']">
              {{ overwriteMode ? 'Enabled' : 'Disabled' }}
            </span>
            <button 
              type="button" 
              class="btn btn-sm ms-3" 
              :class="overwriteMode ? 'btn-danger' : 'btn-outline-secondary'"
              @click="confirmToggleOverwrite"
            >
              {{ overwriteMode ? 'Disable Overwrite' : 'Enable Overwrite' }}
            </button>
          </div>
          <div class="mt-1 small text-secondary">
            <span v-if="overwriteMode">Warning: Overwrite mode will completely replace your existing configuration.</span>
            <span v-else>Default mode will merge new settings with your existing configuration.</span>
          </div>
        </div>
        <div class="modal-body py-2">
          <div class="form-group mb-2">
            <label for="configInput" class="mb-1">Paste configuration here (JSON or TOML)</label>
            <textarea 
              id="configInput" 
              class="form-control bg-dark text-white" 
              rows="6"
              v-model="importConfigText"
              placeholder="Paste your configuration here..."
              @input="debounceValidate"
            ></textarea>
          </div>
          <div v-if="importResult" class="mt-2">
            <div :class="['validation-status', importResult.success ? 'status-success' : 'status-error']">
              <div class="d-flex align-items-center">
                <span v-if="importResult.success" class="me-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                  </svg>
                </span>
                <span v-else class="me-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                  </svg>
                </span>
                {{ importResult.message }}
              </div>
            </div>
            <div v-if="importResult.data" class="config-preview-container mt-2">
              <div class="config-preview-label mb-1">Configuration Preview:</div>
              <div class="config-preview">
                <pre>{{ JSON.stringify(importResult.data, null, 2) }}</pre>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer py-2 justify-content-center">
          <button 
            type="button" 
            class="btn import-btn px-3 py-1"
            @click="importConfig" 
            :disabled="!importResult || !importResult.success"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download me-2" viewBox="0 0 16 16">
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
            </svg>
            Import
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch, nextTick } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'ImportModal',
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  emits: ['close', 'import-success'],
  setup(props, { emit }) {
    const importConfigText = ref('');
    const overwriteMode = ref(false);
    const importResult = ref(null);
    const showConfirmDialog = ref(false);
    const validateTimeout = ref(null);
  // Add this inside the setup function

      // Add this inside the setup function
    const debounceValidate = () => {
      if (validateTimeout.value) {
        clearTimeout(validateTimeout.value);
      }
      validateTimeout.value = setTimeout(() => {
        validateImportConfig();
      }, 300);
    };


    // Reset state when modal is opened/closed
    watch(() => props.show, (newVal) => {
      if (newVal) {
        importConfigText.value = '';
        importResult.value = null;
        overwriteMode.value = false;
      }
    });

    // Get store
    const store = useStore();
    
    const validateImportConfig = () => {
      try {
        if (!importConfigText.value.trim()) {
          importResult.value = null;
          return;
        }
        
        const trimmedConfig = importConfigText.value.trim();
        
        // Check if WASM is loaded
        if (!store.state.wasm) {
          importResult.value = {
            success: false,
            message: 'WASM module not loaded. Please try again later.',
            error: 'WASM module not available'
          };
          return;
        }
        
        // Try to parse as JSON first
        try {
          const parsedConfig = JSON.parse(trimmedConfig);
          importResult.value = {
            success: true,
            message: 'Valid JSON configuration',
            data: parsedConfig
          };
          return;
        } catch (jsonError) {
          // If JSON parsing fails, use the WASM binding to parse TOML
          if (trimmedConfig.includes('=') && !trimmedConfig.startsWith('{')) {
            try {
              // Use the WASM binding to parse TOML
              const result = store.state.wasm.parse_config_data(trimmedConfig);
              
              // Check if the result is an error (ErrorInfo)
              if (result && typeof result === 'object' && result.code !== undefined) {
                // This is an ErrorInfo object
                importResult.value = {
                  success: false,
                  message: `Invalid configuration: ${result.description || result.message || 'Unknown error'}`,
                  error: result.description_extended || result.message || 'Validation failed'
                };
              } else if (result === "undefined" || result === undefined) {
                // Handle undefined result
                importResult.value = {
                  success: false,
                  message: 'Invalid configuration: WASM parser returned undefined',
                  error: 'WASM parser returned undefined'
                };
              } else {
                // Valid config - parse the JSON string returned by the WASM function
                try {
                  // Make sure we have a string to parse
                  const resultStr = typeof result === 'string' ? result : JSON.stringify(result);
                  const parsedConfig = JSON.parse(resultStr);
                  importResult.value = {
                    success: true,
                    message: 'Valid TOML configuration',
                    data: parsedConfig
                  };
                } catch (parseError) {
                  console.error('Error parsing WASM result:', parseError, 'Result was:', result);
                  importResult.value = {
                    success: false,
                    message: `Error parsing configuration: ${parseError.message}`,
                    error: parseError.message
                  };
                }
              }
            } catch (tomlError) {
              console.error('TOML parsing error:', tomlError);
              importResult.value = {
                success: false,
                message: `Error validating TOML configuration: ${tomlError.message}`,
                error: tomlError.message
              };
            }
          } else {
            importResult.value = {
              success: false,
              message: 'Invalid configuration format. Please provide valid JSON or TOML.',
              error: jsonError.message
            };
          }
        }
      } catch (error) {
        importResult.value = {
          success: false,
          message: `Error validating configuration: ${error.message}`,
          error: error.message
        };
      }
    };
    
    // Wrap the import function in a try-catch to handle any errors
    const importConfig = () => {
      try {
        // Create a non-reactive copy of the data to break reactivity chains
        const importedConfig = JSON.parse(JSON.stringify(importResult.value.data));
        
        // Get current config from store - also as a non-reactive copy
        const currentConfig = JSON.parse(JSON.stringify(store.getters.getConfigData || {}));
        
        // Determine final config based on overwrite mode
        let finalConfig;
        if (overwriteMode.value) {
          finalConfig = importedConfig;
        } else {
          // Use WASM to merge configs
          const mergedConfigStr = store.state.wasm.merge_config_data(
            JSON.stringify(importedConfig),
            JSON.stringify(currentConfig),
          );
          finalConfig = JSON.parse(mergedConfigStr);
        }

        // Close modal first to prevent any reactivity issues
        emit('close');
        
        // Use setTimeout to break the reactivity chain
        setTimeout(() => {
          store.commit('setConfigData', finalConfig);
          
          // After updating the store, dispatch the refreshMetrics action
          // Use another setTimeout to further break the reactivity chain
          setTimeout(() => {
            store.dispatch('refreshMetrics');
            // Emit an event to notify parent components that import is complete
            emit('import-success');
          }, 0);
        }, 0);
      } catch (error) {
        console.error('Error importing configuration:', error);
      }
    };
    
    const confirmToggleOverwrite = () => {
      if (!overwriteMode.value) {
        // Only show confirmation when enabling overwrite mode
        if (confirm('Are you sure you want to enable overwrite mode? This will completely replace your existing configuration when importing.')) {
          overwriteMode.value = true;
        }
      } else {
        // Directly disable overwrite mode without confirmation
        overwriteMode.value = false;
      }
    };

    return {
      importConfigText,
      overwriteMode,
      importResult,
      validateImportConfig,
      importConfig,
      confirmToggleOverwrite,
      debounceValidate  // Add this line to expose the function
    };
  }
});
</script>

<style scoped>
.modal {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  border: 1px solid #343a40;
}

.form-control {
  border: 1px solid #343a40;
}

.form-control:focus {
  border-color: #ffc107;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.25);
}

.import-btn {
  background-color: #b8860b; /* Darker gold */
  color: white;
  border: none;
  font-weight: 500;
  transition: all 0.2s ease;
}

.import-btn:hover:not(:disabled) {
  background-color: #a67c00;
  color: white;
  transform: translateY(-2px);
}

.import-btn:disabled {
  background-color: #5a4200;
  color: #aaa;
  cursor: not-allowed;
}

.status-indicator {
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-weight: 500;
}

.status-success {
  color: #28a745;
}

.status-error {
  color: #dc3545;
}

.status-neutral {
  color: #6c757d;
}

.validation-status {
  padding: 0.5rem;
  border-radius: 0.25rem;
}

.config-preview-container {
  margin-top: 0.5rem;
}

.config-preview-label {
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.config-preview {
  max-height: 100px; /* Reduced height */
  overflow-y: auto;
  background-color: #1a1a1a;
  border-radius: 0.25rem;
  padding: 0.5rem;
  border: 1px solid #343a40;
  font-size: 0.9rem; /* Slightly smaller font */
}

.config-preview pre {
  margin: 0;
  white-space: pre-wrap;
  word-break: break-word;
}

.overwrite-controls {
  background-color: rgba(184, 134, 11, 0.1); /* Subtle gold background */
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
}
</style>
