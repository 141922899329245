<template>
  <div id="app-layout">
    <div class="header-container">
      <AppHeader />
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import AppHeader from '../components/AppHeader.vue';

export default defineComponent({
  name: 'AppLayout',
  components: {
    AppHeader,
  },
});
</script>

<style scoped>
#app-layout {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  flex-grow: 1;
  background-color: #000;
  color: #fff;
  /* height: 100vh; */
  overflow: hidden; /* Prevent scrollbars */
}


/* In your AppLayout */
.header-container {
  margin: 0;
  padding: 0;
  line-height: 0; /* This can help eliminate extra space */
}
</style>
