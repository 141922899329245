<template>
  <div class="transaction-stages ms-auto">
    <div class="stage-container">
      <div 
        v-for="(stage, index) in stages" 
        :key="stage.id"
        class="stage"
        :class="{ 
          'active': currentStage >= index, 
          'completed': currentStage > index 
        }"
      >
        <div class="stage-number">{{ index + 1 }}</div>
        <div class="stage-label">{{ stage.label }}</div>
        <div class="stage-connector" v-if="index < stages.length - 1"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TransactionStages',
  props: {
    stages: {
      type: Array,
      required: true
    },
    currentStage: {
      type: Number,
      required: true
    }
  }
});
</script>

<style scoped>
.transaction-stages {
  display: flex;
  justify-content: flex-end;
  padding-top: 45px;
  padding-right: 0px;
}

.stage-container {
  display: flex;
  align-items: center;
}

.stage {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 0 1.5rem;
}

.stage-number {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: #333;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.stage.active .stage-number {
  background-color: #503b04;
}

.stage.completed .stage-number {
  background-color: #28a745;
}

.stage-label {
  font-size: 0.7rem;
  color: #aaa;
  transition: color 0.3s;
  font-weight: 500;
}

.stage-connector {
  position: absolute;
  top: 1rem;
  left: 2rem;
  width: 3rem;
  height: 2px;
  background-color: #333;
}

.stage.active .stage-connector,
.stage.completed .stage-connector {
  background-color: #b8860b;
}
</style>
