<template>
  <div class="gainers-losers">
    <div class="gainers-losers-label">Gainers</div>
    <div class="gainers-section">
      <div v-if="gainers.length === 0" class="text-center py-2">
        <p class="text-muted">No gainers data</p>
      </div>
      <div v-for="gainer in gainers" :key="gainer.name" class="market-item">
        <span class="market-name">{{ gainer.name }}</span>
        <span class="market-value positive">
          <span class="triangle">▲</span> ${{ gainer.value.toFixed(2) }}
        </span>
      </div>
    </div>
    <div class="gainers-losers-label losers-label mt-2">Losers</div>
    <div class="losers-section">
      <div v-if="losers.length === 0" class="text-center py-2">
        <p class="text-muted">No losers data</p>
      </div>
      <div v-for="loser in losers" :key="loser.name" class="market-item">
        <span class="market-name">{{ loser.name }}</span>
        <span class="market-value negative">
          <span class="triangle">▼</span> ${{ loser.value.toFixed(2) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'GainersLosers',
  props: {
    gainers: {
      type: Array,
      required: true
    },
    losers: {
      type: Array,
      required: true
    }
  }
});
</script>

<style scoped>
.gainers-losers-label {
  font-size: 0.85rem;
  color: #999;
  margin-bottom: 8px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  text-align: center;
  padding-bottom: 10px;
}

.losers-label {
  margin-bottom: -5px;
}

.gainers-section, .losers-section {
  margin-bottom: 2px;
}

.market-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2px;
  font-size: .9rem;
}

.market-name {
  font-weight: 500;
}

.market-value {
  font-weight: 600;
  display: flex;
  align-items: center;
}

.market-value.positive {
  color: #00cc00;
}

.market-value.negative {
  color: #F44336;
}

.triangle {
  margin-right: 4px;
  font-size: 0.8rem;
}
</style>
