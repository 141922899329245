<template>
  <div class="app-header">
  <div class="top-header">
    <div class="left-section">
      <router-link :to="router.currentRoute.value.path.startsWith('/app') ? '/app' : '/'" class="logo">
        <img :src="require('@/assets/logo.png')" alt="Logo" class="logo-img">
        <h3>Redgold</h3>
      </router-link>
      
      <!-- Website Dropdown -->
      <WebsiteDropdown :isExplorerRoute="isExplorerRoute" />
      
      <!-- Identity and Account Dropdowns - Hidden on root route -->
      <!-- <div class="identity-account-container" v-if="!isRootRoute"> -->
        <!-- <IdentityAccountDropdowns /> -->
      <!-- </div> -->
    </div>
    
    <div class="header-buttons">
      <!-- Network Status -->
      <NetworkStatus />
      
      <!-- Wallet Status -->
      <WalletStatus :isExplorerRoute="isExplorerRoute" />
      
      <!-- Environment Selector -->
      <EnvironmentSelector :isExplorerRoute="isExplorerRoute" />
      
      <router-link :to="isExplorerRoute ? '/app' : '/'" class="btn btn-outline-light me-2 explorer-btn">
        {{ isExplorerRoute ? 'App' : 'Explorer' }}
      </router-link>
      
      <!-- Wallet Connection -->
      <WalletConnection :isExplorerRoute="isExplorerRoute" />
    </div>
  </div>
    
    <!-- Explorer-specific elements (Only in Explorer mode) -->
    <ExplorerElements v-if="isExplorerRoute" />
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { useRouter } from 'vue-router';
import EnvironmentSelector from './header/EnvironmentSelector.vue';
import WebsiteDropdown from './header/WebsiteDropdown.vue';
import WalletConnection from './header/WalletConnection.vue';
import WalletStatus from './header/WalletStatus.vue';
import NetworkStatus from './header/NetworkStatus.vue';
// import IdentityAccountDropdowns from './header/IdentityAccountDropdowns.vue';
import ExplorerElements from './header/ExplorerElements.vue';

export default defineComponent({
  name: 'AppHeader',
  components: {
    EnvironmentSelector,
    WebsiteDropdown,
    WalletConnection,
    WalletStatus,
    NetworkStatus,
    // IdentityAccountDropdowns,
    ExplorerElements
  },
  setup() {
    const router = useRouter();
    const isExplorerRoute = computed(() => !router.currentRoute.value.path.startsWith('/app'));
    const isRootRoute = computed(() => router.currentRoute.value.path === '/');

    return {
      router,
      isExplorerRoute,
      isRootRoute
    };
  }
});
</script>

<style scoped>


/* Removed deep selector since we're now using a global CSS file */

.app-header {
  background-color: #121212;
  border-bottom: 1px solid #333;
  display: flex;
  flex-direction: column;
  position: relative;
  /* Set a specific height when not in explorer mode to prevent extra space *v-bind('isExplorerRoute ? "auto" : "60px"');*/
  height: auto;
  /* height: v-bind('isExplorerRoute ? "auto" : "60px"'); */
  /* Remove overflow hidden to allow dropdowns to be visible */
  overflow: visible;
  /* Ensure no margin at the bottom */
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.top-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;
  height: 60px;
  position: relative;
  min-height: 60px; /* Ensure consistent height */
  /* Remove max-height to allow dropdowns to be visible */
  box-sizing: border-box;
  margin: 0;
  border-bottom: none;
}

.left-section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  padding: 0;
  box-sizing: border-box;
}

.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
  height: 100%;
  margin-right: 1rem;
  padding: 0;
  margin-top: 10px;
}

.logo:hover {
  color: #ADD8E6;
  background-color: transparent;
}

.logo-img {
  height: 45px;
  width: 45px;
  margin-right: 10px;
}

.logo h3 {
  margin: 0;
  font-size: 1.8rem;
  line-height: 1;
  font-weight: 600;
}

.header-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding: 0;
  box-sizing: border-box;
  margin-top: 10px;
}


/* Adjust button sizes and ensure vertical centering */
.btn {
  padding: 0.5rem 0.7rem;
  font-size: 1.1rem;
  line-height: 1.4;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  height: 40px;
  box-sizing: border-box;
  vertical-align: middle;
}

/* Add specific margin between buttons */
.me-2 {
  margin-right: 0.5rem;
}

/* Custom gold button styling */
.btn-gold {
  background-color: #8b6914;
  color: #fff;
  border: 1px solid #7a5c11;
}

.btn-gold:hover {
  background-color: #7a5c11;
  color: #fff;
  border-color: #69500e;
}

.btn-gold:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Custom styling for outline-light buttons */
.btn-outline-light {
  border-color: #444;
  color: #fff;
}

.btn-outline-light:hover {
  background-color: #333;
  color: #fff;
  border-color: #555;
}


.current-env {
  color: #8b6914 !important;
  font-weight: bold;
  cursor: default;
  pointer-events: none;
}

/* Hash container styling */
.hash-container {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0.3rem 1.5rem;
  background-color: #000;
  width: 100%;
  font-size: 1rem;
  margin: 0; /* Remove any margin */
  box-sizing: border-box;
  border-top: none;
  border-bottom: none;
}

/* Search bar styling */
.search-bar-container {
  background-color: #000;
  /* padding: 0.4rem 1.5rem; */
  width: 100%;
  margin: 0; /* Remove any margin */
  box-sizing: border-box;
  border-top: none;
  border-bottom: none;
}


/* Ensure all child containers don't add extra space */
.hash-container, .search-bar-container {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  /* Force box-sizing */
  box-sizing: border-box;
}

.search-bar {
  background-color: #000;
  padding: 0.2rem;
  margin-bottom: 0;
  margin-top: 0;
  border-radius: 0.3rem;
  max-width: 800px;
  margin: 0 auto;
  box-sizing: border-box;
  border: none;
}

.search-bar h6 {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}

.search-input,
.search-input:focus {
  box-sizing: border-box;
  min-width: 600px;
  max-width: 600px;
  background-color: #191a19;
  color: #fff;
  font-size: 1rem;
  padding: 0.5rem 0.8rem;
  margin: 0;
  border: 1px solid #333;
}

.search-input::placeholder {
  color: #ccc;
}

/* Identity and Account container styling THIS ONE SEEMS TO BE CRITICAL for vertical alignment */
.identity-account-container {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0;
  margin: 0;
}


</style>
