<template>
  <div class="dropdown me-2" :class="dropdownClass" ref="dropdownContainer">
    <button class="btn btn-outline-light dropdown-toggle" type="button" :id="id" :aria-expanded="isOpen" ref="dropdownButton" @click="toggleDropdown">
      <span class="icon-container">
        <slot name="icon"></slot>
      </span>
      <span class="label" :style="{ color: labelColor }">{{ label }}</span>: {{ selectedItem ? truncateText(getDisplayName(selectedItem), maxDisplayLength) : 'All' }}
    </button>
    <div class="dropdown-menu" :class="[menuClass, { 'show': isOpen }]" :aria-labelledby="id" ref="dropdownMenu">
      <div class="dropdown-search-container">
        <div class="search-and-clear">
          <input 
            type="text" 
            v-model="searchQuery" 
            class="dropdown-search-input" 
            :placeholder="searchPlaceholder"
          >
          <button 
            class="clear-selected-btn" 
            @click="clearSelection"
            :title="clearButtonTitle"
          >
            Clear
          </button>
        </div>
      </div>
      <div class="dropdown-items-container">
        <li v-for="item in filteredItems" :key="getItemKey(item)">
          <a class="dropdown-item" @click="selectItem(item)" 
             :class="{ 'current-env': isItemActive(item) }">
            {{ getDisplayName(item) }}
          </a>
        </li>
        <li v-if="filteredItems.length === 0">
          <span class="dropdown-item no-results">No results found</span>
        </li>
      </div>
      <div class="create-new-fixed-container">
        <button class="create-new-btn" @click="createNew">
          <div class="plus-icon-container">
            <svg class="plus-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="12" cy="12" r="12" class="plus-icon-circle" />
              <path d="M12 6V18M6 12H18" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <span>Create New {{ label }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, watch, onMounted, onBeforeUnmount, nextTick } from 'vue';

// Create a custom event for dropdown communication
const DROPDOWN_OPENED_EVENT = 'dropdown-opened';

export default defineComponent({
  name: 'FilterableDropdown',
  props: {
    // Required props
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    // Optional props with defaults
    labelColor: {
      type: String,
      default: '#D4AF37' // Gold color
    },
    dropdownClass: {
      type: String,
      default: ''
    },
    menuClass: {
      type: String,
      default: ''
    },
    searchPlaceholder: {
      type: String,
      default: 'Filter items...'
    },
    clearButtonTitle: {
      type: String,
      default: 'Clear selection'
    },
    maxDisplayLength: {
      type: Number,
      default: 15
    },
    // Function to get a unique key for each item
    itemKey: {
      type: Function,
      default: item => item.id
    },
    // Function to get the display name for each item
    displayName: {
      type: Function,
      default: item => item.name || item.displayName || String(item)
    },
    // Function to determine if an item is active
    isActive: {
      type: Function,
      default: (item, selectedItem) => selectedItem && item.id === selectedItem.id
    },
    // Optional modelValue for v-model support
    modelValue: {
      type: Object,
      default: null
    }
  },
  emits: ['update:modelValue', 'select', 'clear', 'create-new'],
  setup(props, { emit }) {
    const searchQuery = ref('');
    const selectedItem = ref(props.modelValue);
    const isOpen = ref(false);
    const dropdownButton = ref(null);
    const dropdownMenu = ref(null);
    const dropdownContainer = ref(null);
    
    // Watch for external changes to modelValue
    watch(() => props.modelValue, (newValue) => {
      selectedItem.value = newValue;
    });
    
    const filteredItems = computed(() => {
      if (!searchQuery.value) {
        return props.items;
      }
      const query = searchQuery.value.toLowerCase();
      return props.items.filter(item => {
        const displayName = props.displayName(item).toLowerCase();
        return displayName.includes(query);
      });
    });
    
    // Function to position the dropdown menu
    const positionDropdownMenu = () => {
      if (!dropdownButton.value || !dropdownMenu.value || !isOpen.value) return;
      
      const buttonRect = dropdownButton.value.getBoundingClientRect();
      const menuRect = dropdownMenu.value.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const viewportWidth = window.innerWidth;
      
      // Calculate the ideal position
      let top = buttonRect.bottom + 2; // Add a small offset to prevent overlap
      let left = buttonRect.left;
      
      // Check if the menu would extend beyond the bottom of the viewport
      if (top + menuRect.height > viewportHeight) {
        // Position above the button if there's enough space
        if (buttonRect.top > menuRect.height) {
          top = buttonRect.top - menuRect.height - 2;
        }
      }
      
      // Check if the menu would extend beyond the right edge of the viewport
      if (left + menuRect.width > viewportWidth) {
        // Align with the right edge of the button
        left = buttonRect.right - menuRect.width;
      }
      
      // Apply the position
      dropdownMenu.value.style.top = `${top}px`;
      dropdownMenu.value.style.left = `${left}px`;
      dropdownMenu.value.style.width = '280px';
    };
    
    // Handle clicks outside the dropdown
    const handleOutsideClick = (event) => {
      // Check if the click is inside the dropdown container or the search input
      const container = dropdownContainer.value;
      const isClickInsideDropdown = container && container.contains(event.target);
      
      // Only close if the click is outside the dropdown
      if (!isClickInsideDropdown) {
        isOpen.value = false;
      }
    };
    
    // Listen for other dropdowns being opened
    const handleOtherDropdownOpened = (openedDropdownId) => {
      // Close this dropdown if it's not the one that was just opened
      if (props.id !== openedDropdownId && isOpen.value) {
        isOpen.value = false;
        document.removeEventListener('click', handleOutsideClick);
      }
    };

    // Toggle dropdown visibility
    const toggleDropdown = (event) => {
      event.stopPropagation();
      
      // Toggle the dropdown state
      isOpen.value = !isOpen.value;
      
      // Use nextTick to ensure the dropdown is visible before positioning
      if (isOpen.value) {
        // Dispatch custom event to notify other dropdowns
        document.dispatchEvent(new CustomEvent(DROPDOWN_OPENED_EVENT, { 
          detail: { dropdownId: props.id } 
        }));
        
        nextTick(() => {
          positionDropdownMenu();
          
          // Add the click listener with a slight delay to avoid the current click event
          setTimeout(() => {
            document.addEventListener('click', handleOutsideClick);
          }, 0);
        });
      } else {
        document.removeEventListener('click', handleOutsideClick);
      }
    };
    
    // Handle window resize and scroll events
    const handleWindowResize = () => {
      positionDropdownMenu();
    };
    
    const handleWindowScroll = () => {
      positionDropdownMenu();
    };
    
    // Add event listeners
    onMounted(() => {
      window.addEventListener('resize', handleWindowResize);
      window.addEventListener('scroll', handleWindowScroll, true); // Use capture phase to catch all scroll events
      document.addEventListener(DROPDOWN_OPENED_EVENT, (event) => {
        handleOtherDropdownOpened(event.detail.dropdownId);
      });
    });
    
    // Remove event listeners
    onBeforeUnmount(() => {
      window.removeEventListener('resize', handleWindowResize);
      window.removeEventListener('scroll', handleWindowScroll, true);
      document.removeEventListener('click', handleOutsideClick);
      document.removeEventListener(DROPDOWN_OPENED_EVENT, handleOtherDropdownOpened);
    });
    
    const selectItem = (item) => {
      selectedItem.value = item;
      emit('update:modelValue', item);
      emit('select', item);
      isOpen.value = false;
      document.removeEventListener('click', handleOutsideClick);
    };
    
    const clearSelection = (event) => {
      event.stopPropagation(); // Prevent the click from bubbling up
      selectedItem.value = null;
      emit('update:modelValue', null);
      emit('clear');
    };
    
    const createNew = (event) => {
      event.stopPropagation(); // Prevent the click from bubbling up
      emit('create-new', props.label);
      isOpen.value = false;
      document.removeEventListener('click', handleOutsideClick);
    };
    
    const getItemKey = (item) => {
      return props.itemKey(item);
    };
    
    const getDisplayName = (item) => {
      return props.displayName(item);
    };
    
    const isItemActive = (item) => {
      return props.isActive(item, selectedItem.value);
    };
    
    // Function to truncate text with ellipsis
    const truncateText = (text, maxLength) => {
      if (!text) return '';
      if (text.length <= maxLength) return text;
      return text.substring(0, maxLength) + '...';
    };
    
    return {
      searchQuery,
      selectedItem,
      filteredItems,
      selectItem,
      clearSelection,
      createNew,
      getItemKey,
      getDisplayName,
      isItemActive,
      isOpen,
      toggleDropdown,
      dropdownButton,
      dropdownMenu,
      dropdownContainer,
      truncateText
    };
  }
});
</script>

<style scoped>
.dropdown {
  display: flex;
  align-items: center;
  padding: 0;
  height: 40px;
  margin-top: 10px;
}

.dropdown-menu {
  width: 280px;
  padding: 0.3rem 0 0 0;
  background-color: #1e1e1e;
  border: 1px solid #333;
  min-width: 6rem;
  position: fixed; /* Fixed positioning relative to the viewport */
  margin: 0;
  z-index: 99999; /* High z-index to ensure it appears above other elements */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Add shadow for better visibility */
  display: none; /* Hide by default */
}

/* Show dropdown menu when isOpen is true */
.dropdown-menu.show {
  display: block;
}

.dropdown-search-container {
  padding: 0.3rem 0.5rem;
  border-bottom: 1px solid #333;
  margin-bottom: 0.1rem;
}

.search-and-clear {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.dropdown-search-input {
  width: 75%;
  padding: 0.5rem;
  background-color: #2a2a2a;
  border: 1px solid #444;
  color: #fff;
  border-radius: 4px;
}

.dropdown-search-input:focus {
  outline: none;
  border-color: #555;
}

.clear-selected-btn {
  background-color: #8b6914;
  border: 1px solid #6b4900;
  color: #fff;
  border-radius: 4px;
  padding: 0.5rem 0.6rem;
  height: 32px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.2s;
  flex-grow: 1;
}

.clear-selected-btn:hover {
  background-color: #6b4900;
  border-color: #4b2900;
}

.create-new-fixed-container {
  padding: 0.5rem;
  border-top: 1px solid #333;
  position: sticky;
  bottom: 0;
  background-color: #1e1e1e;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.create-new-btn {
  background-color: #8b6914;
  border: 1px solid #6b4900;
  color: #fff;
  border-radius: 4px;
  padding: 0.5rem 0.8rem;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.2s;
  text-align: left;
  height: 40px;
  display: inline-flex;
  align-items: center;
  width: auto;
}

.create-new-btn:hover {
  background-color: #6b4900;
  border-color: #4b2900;
}

.dropdown-items-container {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 0.5rem;
}

.no-results {
  color: #888;
  font-style: italic;
}

.btn {
  padding: 0.5rem 0.7rem;
  line-height: 1.4;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  height: 40px;
  box-sizing: border-box;
  vertical-align: middle;
  white-space: nowrap;
}

.btn-outline-light {
  border-color: #444;
  color: #fff;
}

.btn-outline-light:hover {
  background-color: #333;
  color: #fff;
  border-color: #555;
}

.me-2 {
  margin-right: 0.5rem;
}

.dropdown-item {
  color: #fff;
  padding: 0.4rem 0.8rem;
  cursor: pointer;
  line-height: 1.4;
  display: flex;
  align-items: center;
}

.dropdown-item:hover, .dropdown-item:focus {
  background-color: #333;
  color: #fff;
}

.current-env {
  color: #8b6914 !important;
  font-weight: bold;
  cursor: default;
  pointer-events: none;
}

.label {
  font-weight: bold;
}

.icon-container {
  display: inline-flex;
  align-items: center;
  margin-right: 6px;
}

.plus-icon-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 7px;
  margin-top: 10px;
  height: 100%;
}

.plus-icon {
  font-size: 1.2rem;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  position: relative;
}

.plus-icon-circle {
  fill: #a37a18; /* Brighter gold color than the button background */
  stroke: none;
}

.create-new-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 12px;
  height: 40px;
  line-height: 24px;
}

.create-new-btn span {
  display: inline-block;
  line-height: 24px;
}
</style>
